@if(canDownloadManuals) {

<!--

Button

-->
<button 
  (click)="onDownloadManualClick()"
  class="my-button" 
  [class.my-button--secondary]="!calculatedStatus.showBlueButton"
  [class.my-button--has-status-icon]="calculatedStatus?.statusToShow"
  [disabled]="calculatedStatus.buttonIsDisabled"
  [class.my-button--block]="displayBlock">

  <span 
    *ngIf="calculatedStatus.buttonIconName" 
    translate="no" 
    [class.material-icon--spin]="calculatedStatus.buttonToShow == 'inprogress'"
    class="material-icon">{{calculatedStatus.buttonIconName}}</span>

  <mibp-resource-string *ngIf="calculatedStatus.buttonResourceString" [key]="calculatedStatus.buttonResourceString"></mibp-resource-string>

  @if (calculatedStatus.buttonToShow == 'download') {
    {{calculatedStatus.fileTypeAsString}} ({{fileSize | formatBytes}})
  }
</button>

<!--

Status icons

-->
@if (calculatedStatus?.statusToShow == 'error') {
  <span class="status-icon" (click)="openStatus()">
    <span class="material-icon material-icon--red" translate="no">warning</span>
  </span>
} @else if (calculatedStatus?.statusToShow == 'updating') {
  <span class="status-icon" (click)="openStatus()">
    <span class="material-icon  material-icon--blue" translate="no">sync</span>
  </span> 
}

<mibp-support-menu [items]="supportMenuItems" (action)="supportAction($event)"></mibp-support-menu>

}

<!--

Popup when clicking on status icon

-->
<ng-template #statusPopupTemplate>
  <div class="manual-button-status-overlay" [style.opacity]="showStatus ? 1 : 0"></div>
  <div class="manual-button-status-popover"
    [class.manual-button-status-popover--error]="calculatedStatus?.statusToShow == 'error'"
    [class.manual-button-status-popover--fullwidth]="statusPosition?.fullWidth"
    [style.opacity]="showStatus ? 1 : 0"
    [style.top]="statusPosition?.y ? statusPosition?.y  + 'px' : null"
    [style.left]="statusPosition?.x + 'px'">
      @if (calculatedStatus?.statusToShow == 'error') {
        <ng-template *ngTemplateOutlet="errorPopupTemplate; context: {item: ds}"></ng-template>
      } @else {
        <mibp-resource-string isHtml="true" key="DownloadedManuals_ManualUpdateInProgress"></mibp-resource-string>
      }
  </div>
</ng-template>


<ng-template #errorPopupTemplate>

  <header>  
    <h3 class="my-header"><mibp-resource-string key="DownloadedManualErrorCode_Title"></mibp-resource-string></h3>
    <span class="close">
      <span class="material-icon" translate="no">close</span>
    </span>
  </header>

  <p><mibp-resource-string key="Manual_Name"></mibp-resource-string>: {{mediaName}}</p>
  <p><mibp-resource-string key="DownloadedManualErrorCode_GeneralMessage"></mibp-resource-string></p>

  @if (isSupportTeamMember) {
    
    <div class="support-team-member-details">
      <strong>Support team member error info:</strong>
      @if (loadingSyncStatus) {
        <span class="material-icon material-icon--spin">progress_activity</span>
      }
      <ul>
        <li *ngIf="mediaSyncStatus?.media?.markedForDeletion"  class="my-text my-text--danger">Media is marked for deletion</li>
        @if (mediaSyncStatus?.fetchDocumotoMediaContent?.lastRun?.status == jobStatus.Failed || mediaSyncStatus?.fetchDocumotoMediaContent?.lastRun?.status == jobStatus.Terminated) {
          <li class="my-text my-text--danger">Error fetching files from Documoto (Job <a [ecommerceRouterLink]="['/:lang/settings/manuals/jobs/documoto-fetch-media-content/' + mediaSyncStatus?.fetchDocumotoMediaContent.lastRun.id]"> {{mediaSyncStatus?.fetchDocumotoMediaContent.lastRun.id}}</a> {{mediaSyncStatus?.fetchDocumotoMediaContent.lastRun.endDate | formatDateTime}})
            <ul>
              <li *ngFor="let file of mediaSyncStatus?.fetchDocumotoMediaContent.lastRun.files">
                File - {{file.errorMessage}}
              </li>
            </ul>
          </li>
        } @else if (mediaSyncStatus?.downloadManual?.lastRun?.status == jobStatus.Failed) {
          <li class="my-text my-text--blue" *ngIf="mediaSyncStatus?.fetchDocumotoMediaContent?.lastRun?.status == jobStatus.Completed"><span class="material-icon">check</span> File fetched from Documoto (Job <a [ecommerceRouterLink]="['/:lang/settings/manuals/jobs/documoto-fetch-media-content/' + mediaSyncStatus?.fetchDocumotoMediaContent.lastRun.id]"> {{mediaSyncStatus?.fetchDocumotoMediaContent.lastRun.id}}</a> {{mediaSyncStatus?.fetchDocumotoMediaContent.lastRun.endDate | formatDateTime}})</li>
          <li class="my-text my-text--danger">Download manual job failed
            <a [ecommerceRouterLink]="['/:lang/settings/manuals/jobs/download-manual/' + mediaSyncStatus?.downloadManual.lastRun.id]"> {{mediaSyncStatus?.downloadManual.lastRun.id}}</a> {{mediaSyncStatus?.downloadManual.lastRun.endDate | formatDateTime}})
          </li>
        }
    </ul>
</div>
}

</ng-template>