/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CatalogComponentIndexSearchResponse } from '../models/catalog-component-index-search-response';
import { getCatalogComponentSearchFilters } from '../fn/component-finder/get-catalog-component-search-filters';
import { GetCatalogComponentSearchFilters$Params } from '../fn/component-finder/get-catalog-component-search-filters';
import { getCatalogComponentSearchFilters$Plain } from '../fn/component-finder/get-catalog-component-search-filters-plain';
import { GetCatalogComponentSearchFilters$Plain$Params } from '../fn/component-finder/get-catalog-component-search-filters-plain';
import { importCatalogComponent } from '../fn/component-finder/import-catalog-component';
import { ImportCatalogComponent$Params } from '../fn/component-finder/import-catalog-component';
import { importCatalogComponent$Plain } from '../fn/component-finder/import-catalog-component-plain';
import { ImportCatalogComponent$Plain$Params } from '../fn/component-finder/import-catalog-component-plain';
import { NewProductSearchItemSearchResponse } from '../models/new-product-search-item-search-response';
import { searchCatalogComponents } from '../fn/component-finder/search-catalog-components';
import { SearchCatalogComponents$Params } from '../fn/component-finder/search-catalog-components';
import { searchCatalogComponents$Plain } from '../fn/component-finder/search-catalog-components-plain';
import { SearchCatalogComponents$Plain$Params } from '../fn/component-finder/search-catalog-components-plain';

@Injectable({ providedIn: 'root' })
export class ComponentFinderApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `componentFinderControllerImportCatalogComponentrequest()` */
  static readonly ComponentFinderControllerImportCatalogComponentrequestPath = '/componentfinder/import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importCatalogComponent$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importCatalogComponent$Plain$Response(params?: ImportCatalogComponent$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return importCatalogComponent$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importCatalogComponent$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importCatalogComponent$Plain(params?: ImportCatalogComponent$Plain$Params, context?: HttpContext): Observable<number> {
    return this.importCatalogComponent$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importCatalogComponent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importCatalogComponent$Response(params?: ImportCatalogComponent$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return importCatalogComponent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importCatalogComponent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importCatalogComponent(params?: ImportCatalogComponent$Params, context?: HttpContext): Observable<number> {
    return this.importCatalogComponent$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `componentFinderControllerSearchCatalogComponentsrefinementOptions()` */
  static readonly ComponentFinderControllerSearchCatalogComponentsrefinementOptionsPath = '/componentfinder/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCatalogComponents$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCatalogComponents$Plain$Response(params?: SearchCatalogComponents$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<NewProductSearchItemSearchResponse>> {
    return searchCatalogComponents$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCatalogComponents$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCatalogComponents$Plain(params?: SearchCatalogComponents$Plain$Params, context?: HttpContext): Observable<NewProductSearchItemSearchResponse> {
    return this.searchCatalogComponents$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewProductSearchItemSearchResponse>): NewProductSearchItemSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCatalogComponents()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCatalogComponents$Response(params?: SearchCatalogComponents$Params, context?: HttpContext): Observable<StrictHttpResponse<NewProductSearchItemSearchResponse>> {
    return searchCatalogComponents(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCatalogComponents$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCatalogComponents(params?: SearchCatalogComponents$Params, context?: HttpContext): Observable<NewProductSearchItemSearchResponse> {
    return this.searchCatalogComponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<NewProductSearchItemSearchResponse>): NewProductSearchItemSearchResponse => r.body)
    );
  }

  /** Path part for operation `componentFinderControllerGetCatalogComponentSearchFiltersrefinementOptions()` */
  static readonly ComponentFinderControllerGetCatalogComponentSearchFiltersrefinementOptionsPath = '/componentfinder/filters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCatalogComponentSearchFilters$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCatalogComponentSearchFilters$Plain$Response(params?: GetCatalogComponentSearchFilters$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogComponentIndexSearchResponse>> {
    return getCatalogComponentSearchFilters$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCatalogComponentSearchFilters$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCatalogComponentSearchFilters$Plain(params?: GetCatalogComponentSearchFilters$Plain$Params, context?: HttpContext): Observable<CatalogComponentIndexSearchResponse> {
    return this.getCatalogComponentSearchFilters$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogComponentIndexSearchResponse>): CatalogComponentIndexSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCatalogComponentSearchFilters()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCatalogComponentSearchFilters$Response(params?: GetCatalogComponentSearchFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogComponentIndexSearchResponse>> {
    return getCatalogComponentSearchFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCatalogComponentSearchFilters$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCatalogComponentSearchFilters(params?: GetCatalogComponentSearchFilters$Params, context?: HttpContext): Observable<CatalogComponentIndexSearchResponse> {
    return this.getCatalogComponentSearchFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogComponentIndexSearchResponse>): CatalogComponentIndexSearchResponse => r.body)
    );
  }

}
