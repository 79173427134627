@import '../../../scss/variables.scss';

.system-message {
  position: relative;
  min-height: 112px;
  margin: 0 0 0px;
  z-index: 0;
  padding: 15px 25px 0;
  border: 1px solid $my-color-eb;
  text-align: left;
  background-color: #fff;
  color: #000 !important;
  &:not(.system-message--nomargin) {
    margin-bottom: 40px;
  }
}

.system-message-subtle {
  min-height: 0;
  padding: 15px 20px 0;
}

.system-message {
  border-left-width: 120px;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.system-message:before {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.system-message ul {
  list-style-type: none;
  margin: 0;
  padding-left: 25px;
  margin-bottom: 25px;
}

.system-message p {
  font-size: 1em;
}

.system-message.exception-manager-message {
  margin-top: 12px;
  margin-bottom: 12px;
}

.system-message ul li:before {
  position: absolute;
  margin-right: 10px;
  margin-left: -25px;
  content: "- ";
}

.system-message ul li {
  margin-bottom: 13px;
}

.system-message-subtle,
.confirmation-message-subtle,
.warning-message-subtle,
.error-message-subtle {
  border-left-width: 1px;
}

.system-message-subtle p {
  margin-bottom: 15px;
}

.system-message:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -120px;
  width: 120px;
  background-image:url('#{$assets-images}system-message/system-message-neutral-73x66.svg');
  background-image: linear-gradient(transparent, transparent), url(#{$assets-images}system-message/system-message-neutral-30x69.svg);
  content: "";
}

.system-message-subtle:before {
  display: none;
}

.system-message h2,
.system-message h3,
.system-message h4 {
  margin-bottom: 10px;
  color: #000;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-transform: none;
}

.system-message-subtle h2,
.system-message-subtle h3,
.system-message-subtle h4 {
  margin-bottom: 5px;
  font-size: 17px;
  line-height: 25px;
}

.system-message ul li:before {
  color: #09f;
}

/* Confirmation */
.confirmation-message {
  border-color: #00be48;
}

.confirmation-message ul li:before {
  color: #00be48;
}

.confirmation-message h2 {
  color: #00be48;
}


.confirmation-message:before {
  background-image: url('#{$assets-images}system-message/system-message-success-73x66.svg');
  background-image: linear-gradient(transparent, transparent), url('#{$assets-images}system-message/system-message-success-73x66.svg');
}

.confirmation-message.system-message-subtle p strong {
  color: #00be48;
}

/* Warning */
.warning-message {
  border-color: #ff6a00;
}

.warning-message ul li:before {
  color: #ff6a00;
}

.warning-message h2 {
  color: #ff6a00;
}

.warning-message:before {
  background-image: url('#{$assets-images}system-message/system-message-warning-19x75.svg');
  background-image: linear-gradient(transparent, transparent), url('#{$assets-images}system-message/system-message-warning-19x75.svg');
}

.warning-message.system-message-subtle p strong {
  color: #ff6a00;
}

/* Error */
.error-message {
  border-color: #f03728;
}

.error-message ul li:before {
  color: #f03728;
}

.error-message h2 {
  color: #f03728;
}

.error-message:before {
  background-image: url('#{$assets-images}/system-message/system-message-error-60x60.svg');
  background-image: linear-gradient(transparent, transparent),  url('#{$assets-images}system-message/system-message-error-60x60.svg');
}

.error-message.system-message-subtle p strong {
  color: #f03728;
}

@media only screen and (max-width: 1050px) {
  .system-message {
    padding: 10px 20px;
  }
  .system-message h2 {
    font-size: 17px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 500px) {
  .system-message {
    border-left-width: 90px;
  }
  .system-message-subtle {
    border-left-width: 1px;
  }
  .system-message:before {
    left: -90px;
    width: 90px;
    background-position: 50% 20px;
  }
}
