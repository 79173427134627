.browser-notice {
  font-size: 1.1em;
  font-weight: 400;
  padding: 6px;
  background-color: hsl(52, 95%, 84%);
  border-bottom: 1px solid #A6983A;
  .material-icon {color: #ff6d00}
  a {
    color: #F1554B;
    font-weight: bold;
    border-bottom: none;
    text-decoration: underline;
  }
  @media only screen and (max-width: 1199px) {
    display: none;
  }

}
