import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Guid } from 'guid-typescript';
import { BroadcastService, LogService, MibpLogger, SignalR_Rendition } from 'root/services';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'mibp-images-carousel',
  templateUrl: './images-carousel.component.html',
  styleUrls: ['./images-carousel.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('1000ms', style({ opacity: 1}))
      ])
    ])
  ]
})

export class MibpImagesCarouselComponent implements OnInit, OnDestroy {
  @ViewChild('carousel') carouselElement: ElementRef;

  @Input() slideImages: ImageSlide[];
  @Input() renditionSize = SignalR_Rendition.Large;
  @Input() adjustImagesToContainer = false;
  @Input() enableLightboxForImages = false;
  isLoading = true;
  currentSlide = 0;
  imageNotFound: boolean;
  base64String: SafeUrl;
  log: MibpLogger;

  screenSizeSub?: Subscription;
  loadedImage= false;
  heightAdjustedBy = 0;

  constructor(
    private renderer: Renderer2,
    private broadcast: BroadcastService,
    logger: LogService

  ) {
    this.log = logger.withPrefix('iamges-carousel');
  }

  ngOnInit(): void {
    this.screenSizeSub = this.broadcast.screenSize
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.updateCarouselHeightBasedOnCurrentImage();
      });
  }

  imageLoaded(){
    this.loadedImage = true;
    this.onImageLoad();
  }

  ngOnDestroy(): void {
    this.screenSizeSub?.unsubscribe();
  }

  onPreviousClick() {
    this.loadedImage = false;
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slideImages.length - 1 : previous;
  }

  onNextClick() {
    this.loadedImage = false;
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slideImages.length ? 0 : next;
  }

  async navigateToSlide(selectedSlide: number) {
    this.currentSlide = selectedSlide;
  }

  onImageLoad(): void {
     this.updateCarouselHeightBasedOnCurrentImage();
  }

  updateCarouselHeightBasedOnCurrentImage(): void {
    if( this.heightAdjustedBy < (this.slideImages[this.currentSlide].imageDescription.length / 50)) {
      let newCalculatedHeight = this.carouselElement.nativeElement.clientHeight + 15 * (this.slideImages[this.currentSlide].imageDescription.length / 50);
      this.renderer.setStyle(this.carouselElement.nativeElement, 'minHeight', newCalculatedHeight + 'px');
      this.heightAdjustedBy = (this.slideImages[this.currentSlide].imageDescription.length / 50);
    }
  }

  determineNewHeight(originalWidth: number, originalHeight: number,  newWidth: number): number {
    return (originalHeight / originalWidth) * newWidth;
  }

}

export interface ImageSlide {
  imageId?: string,
  imageTitle?: string;
  imageDescription?: string;
  userFileId?: Guid,
  url?: string | null;
  imageNotFound?: boolean | null;
}
