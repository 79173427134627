import { CacheScope, ClientSideCacheService, FrontendContextService } from 'root/services';
/* eslint-disable @angular-eslint/no-input-rename */
import { DropdownInput, DropdownData, DropdownArgs } from 'root/components';
import { Component, OnInit, Input, forwardRef, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, DoCheck, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { DropDownDataSource } from './dropdown.component.types';

export enum DataDrivenStatus {
  Idle,

  /**
   * Filter event is triggered. Waiting for data to be updated.
   */
  FilterTriggered,

  /**
   * Data is updated. Wait for it to finish loading
   */
  LoadingData
}

@Component({
  selector: 'mibp-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownComponent),
    }
  ]
})
export class DropdownComponent implements ControlValueAccessor, OnInit, AfterViewInit, DoCheck, OnDestroy {

  private data$: Observable<DropdownData>;
  private dataSubscription: Subscription;
  private windowClickSubscription: Subscription;
  private filterTimer: number;
  private currentQuery = "";
  private currentIndex = 0;  
  private isInitLoad = true;
  private isLoadingChildCount = -1;
  private blurTimeout: number;
  private dropDownData: DropdownData;
  private changes: MutationObserver;
  private lastEventData: string;
  private userClickedApply = false;
  private internalId: string;
  private pendingChanges: (DropdownInput | DropdownInput[])[] = [];

  protected footerDetails: {
    hasMoreResults?: boolean;
    visibleItemCount?: number;
    count?: number;
  } = {};
  protected errorFetchingData = false;
  protected invalidvalues: string[] = [];
  protected dataSource: DropDownDataSource;
  protected activeListIndex: number;
  protected isDataDriven = false;
  protected dataDrivenStatus: DataDrivenStatus = DataDrivenStatus.Idle;
  protected showDropdownList = false;
  protected showLoader = false;
  protected isValidationPending = false;
  protected visibleItems: DropdownInput[] = [];
  protected multiSelectOptionsChanged = false;

  @ViewChild("dropdownlist", { read: ElementRef }) dropdownListElement: ElementRef;
  @ViewChild("dataDrivenDropdown", { read: ElementRef }) dataDrivenDropdownElement: ElementRef;
  @ViewChild("tbx", { read: ElementRef }) filterInputElement: ElementRef;

  @Input() disabled = false;
  @Input() multiselect = false;
  @Input() alwaysTriggerSearch = false;
  @Input() multiselectApply = false;
  @Input('multiselect.hideOnClick') multiselectHideOnSelection = false;
  @Input('multiselect.tagPlaceholder') makePlaceholderLookLikeATag = false;
  @Input('multiselect.cannotRemoveLast') cannotRemoveLastItem = false;
  @Input() placeholder = 'Global_Select_Placeholder';
  @Input() required: boolean;
  @Input() selectedOption: DropdownInput | DropdownInput[];
  @Input() newMultiSelectDesign = false;
  @Input() doFirstLoadOnInit = false;
  @Input() filterDelayMs = 900;
  @Input() enableClearSingleSelect = false;
  @Input() counterIndexSetter = 0;
  @Input() loading: boolean;
  @Input() take = 10;
  @Input()
  set items(items: DropdownInput[]) {
    if (items === null || items === undefined) {
      this.currentIndex = 0;
      this.dataSource = null;
      this.data = null;
      this.currentIndex = 0;
    } else {
      this.isDataDriven = true;
      this.dataSource = new DropDownDataSource(items);
      this.dataDrivenStatus = DataDrivenStatus.Idle;
      this.currentIndex = 0;
      this.data = this.dataSource.filter(<DropdownArgs>{
        index: 0,
        query: '',
        take: this.take
      });
    }
  }

  @Input()
  set data(newData: Observable<DropdownData>) {
    if (!newData) {
      this.footerDetails = {};
      this.currentIndex = 0;
      this.currentQuery = '';
      this.visibleItems = null;
      this.lastEventData = null;
      this.dataDrivenStatus = DataDrivenStatus.Idle;
    }

    if (this.dataDrivenStatus === DataDrivenStatus.FilterTriggered || this.dataDrivenStatus === DataDrivenStatus.Idle) {
      this.showLoader = true;
      this.errorFetchingData = false;
      this.dataDrivenStatus = DataDrivenStatus.LoadingData;

      this.data$ = newData;
      if (this.data$) {
        if (this.dataSubscription) {
          this.dataSubscription.unsubscribe();
        }
        this.dataSubscription = this.data$.subscribe((changedData) => this.onDataChange(changedData), () => {
          this.errorFetchingData = true;
          this.loading = false;
          this.showLoader = false;
        });
      } else {
        this.showLoader = false;
        this.visibleItems = null;
      }
    }
  }

  @Output() filter = new EventEmitter<DropdownArgs>();
  @Output() valueChange = new EventEmitter();
  @Output() dropdownShow = new EventEmitter();
  @Output() dropdownHide = new EventEmitter();
  @Output() initLoad = new EventEmitter<number>();
  @Output() invalidValuesChanged = new EventEmitter();
  @Output() removed = new EventEmitter<{ dropdown: boolean, item: DropdownInput }>();

  constructor(
    private cache: ClientSideCacheService,
    private element: ElementRef,
    private frontendContext: FrontendContextService) { }

  ngOnInit(): void {
    this.internalId = this.frontendContext.newRandomIdString();
    // If a filter observer exists, then we'll assume this is data driven
    this.isDataDriven = !!this.dataSource || this.filter.observers.length > 0;

    if (this.doFirstLoadOnInit) {
      this.triggerFilterEvent();
    }
  }

  ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
    this.windowClickSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    // Here is the Mutation Observer for the element
    // This will make sure that ngDoCheck is invoked properly
    // otherwise, the ng-pending class does not always disappear in time
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.changes = new MutationObserver(() => { });
    this.changes.observe(this.element.nativeElement, {
      attributes: true,
      childList: true,
      characterData: true
    });
  }

  /***
   * When user clicks "cancel" or outside the dropdown if multiselect and apply
   * is activated, this will revert the selected items to the items that existed
   * before the dropdown was opened
   */
  private revertMultiselectChanges(): void {
    this.multiSelectOptionsChanged = false;
    const originalSelected = this.cache.get<DropdownInput[]>(`${this.internalId}_items`);

    this.multiSelectedOption?.slice(0).forEach(currentOption => {
      const item = originalSelected?.find((item: DropdownInput) => item.value === currentOption.value);
      if (!item) {
        this.selectItem(currentOption, null);
      }
    });

    originalSelected?.forEach((item: DropdownInput) => {
      if (!this.multiSelectedOption.find((selectedItem: DropdownInput) => selectedItem.value === item.value)) {
        this.selectItem(item, null);
      }
    });
  }

  private deselectAllMultiselectOptions(): void {
    if (this.multiSelectedOption) {
      this.multiSelectedOption.slice(0).forEach(currentOption => {
        this.selectItem(currentOption, null);
      });
    }
  }

  private hasMultiSelectedOptionsChanged(): boolean {
    if (!this.multiselect) {
      return false;
    }

    const originalSelected = this.cache.get<DropdownInput[]>(`${this.internalId}_items`)?.map(ddi => ddi.value ).sort();
    const currentSelection = (<DropdownInput[]>this.selectedOption)?.map(ddi => ddi.value ).sort();

    return (originalSelected == null && currentSelection != null && currentSelection.length > 0) || 
    (originalSelected != null && !(originalSelected.length === currentSelection.length && 
      originalSelected.every((element, index) => element === currentSelection[index])));
  }

  onApply(e: Event, action: 'apply' | 'cancel' | 'clear'): void {
    e.preventDefault();
    e.stopPropagation();

    if ((e.target as HTMLElement).classList.contains('disabled') ||
      (e.target as HTMLElement).parentElement.classList.contains('disabled')) {
      return;
    }

    if (action === 'cancel') {
      this.revertMultiselectChanges();

      this.showDropdownList = false;
      this.filterInputElement.nativeElement.blur();
      this.filterInputElement.nativeElement.value = "";
    } else if (action === 'apply') {
      this.userClickedApply = true;
      this.showDropdownList = false;
      this.filterInputElement.nativeElement.blur();
      this.filterInputElement.nativeElement.value = "";
      this.triggerChange(this.multiSelectedOption);
      this.dropdownHide.emit();
    } else {
      this.deselectAllMultiselectOptions();
      this.filterInputElement.nativeElement.focus();
    }

  }

  trackByDropdownInput(_index: number, item: DropdownInput): string {
    if (!item) { return null; }
    return item.value;
  }

  public disable(): void {
    this.disabled = true;
  }

  public enable(): void {
    this.disabled = false;
  }

  get singleSelectedOption(): DropdownInput {
    return <DropdownInput>this.selectedOption;
  }

  get multiSelectedOption(): DropdownInput[] {
    return <DropdownInput[]>this.selectedOption;
  }

  /**
   * Test if an item is currently selected
   */
  isSelected(item: DropdownInput): boolean {

    if (!this.selectedOption) {
      return false;
    }

    if (this.multiselect && Array.isArray(this.selectedOption) && !item.disabled) {
      return (<DropdownInput[]>this.selectedOption).findIndex(selectedItem => selectedItem.value === item.value) !== -1;
    } else {
      return this.selectedOption && (<DropdownInput>this.selectedOption).value === item.value;
    }
  }

  /**
   * Multiselect - set a number of selected values as invalid
   * Set to null or an empty array to clear list
   *
   * @param values The list of invalid values
   */
  setInvalidValues(values: string[]): void {
    if (values) {
      if (Array.isArray(values)) {
        this.invalidvalues = values;
        this.invalidValuesChanged.emit(values);
        return;
      }
    }
    if (this.invalidvalues !== null) {
      this.invalidValuesChanged.emit(null);
    }
    this.invalidvalues = null;
  }

  isOptionInvalid(item: DropdownInput): boolean {
    if (this.multiselect && this.invalidvalues) {
      if (this.invalidvalues.find(invalidValue => invalidValue === item.value)) {
        return true;
      }
    }
    return false;
  }

  public get hasInvalidValues(): boolean {
    if (this.invalidvalues) {
      if (this.invalidvalues.length > 0) {
        return true;
      }
    }
    return false;
  }

  /**
   * @param [fromSelectedList=false] Is true when item is removed from by clicking on the multiselect item outside of the dropdown
   */
  removeSelectedOption(event: Event, item: DropdownInput, fromSelectedList = false): void {

    if (this.disabled || this.isValidationPending || this.loading) {
      return;
    }

    if (fromSelectedList && this.multiselect && this.multiselectApply && this.showDropdownList) {
      const ix = this.multiSelectedOption.findIndex(item => item.value === item.value);
      if (ix !== -1) {
        this.multiSelectedOption.splice(ix, 1);
      }
      return;
    }

    event?.stopPropagation();
    const selectedOptions = <DropdownInput[]>this.selectedOption;
    const selectedIndex = selectedOptions.findIndex(i => {
      return i.value === item.value;
    });

    if (selectedIndex !== -1) {
      if (this.invalidvalues) {
        const invalidIndex = this.invalidvalues.findIndex(invalidValue => selectedOptions[selectedIndex].value === invalidValue);
        if (invalidIndex !== -1) {
          this.invalidvalues.splice(invalidIndex, 1);
          this.invalidValuesChanged.emit(this.invalidvalues);
        }
      }
      selectedOptions.splice(selectedIndex, 1);
      if (!this.newMultiSelectDesign) {
        this.triggerTouched(null, selectedOptions);
      }
      this.removed.emit({ item: item, dropdown: !fromSelectedList });
    }
  }

  removeSelectedOptionSingleSelect(event: Event) {
    if (this.disabled || this.isValidationPending || this.loading) {
      return;
    }

    event?.stopImmediatePropagation();
    event?.stopPropagation();
    this.selectedOption = null;
    this.triggerChange(null);
    this.valueChange?.emit();
  }

  private findSelectedItemParent(elm: HTMLElement) {
    let i = 0;
    do {
      if (elm.className.indexOf && elm.className.indexOf('selected-item') !== -1) {
        return elm;
      } else {
        elm = elm.parentElement;
      }
      i++;
    } while (elm && i < 10);
    return null;
  }

  focusFilterInput(event: Event, elm: HTMLInputElement): void {

    if (this.multiselect) {
      const selectedItemElement = this.findSelectedItemParent(<HTMLElement>event.target);
      if (selectedItemElement) {
        if (selectedItemElement.className.indexOf('is-placeholder') !== -1) {
          if (!this.disabled && !this.isValidationPending) {
            elm.focus();
          }
        }
        event.preventDefault();
        return;
      }
    }

    const targetElement = (<HTMLElement>event.target);
    if (targetElement.nodeName === 'SPAN') {      
      event.preventDefault();

      if (targetElement.matches('.clear')) {
        return; // User clicked on the 'clear' (x) icon so we do not want to show the dropdown
      }      
    }

    if (!this.disabled && !this.isValidationPending) {
      elm.focus();
    } else {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
  }

  ngDoCheck(): void {
    const classNames = this.element.nativeElement.getAttribute('class');
    let isPending = false;

    if (classNames && classNames.indexOf('ng-pending') !== -1) {
      isPending = true;
    }

    if (isPending && !this.isValidationPending) {
      this.isValidationPending = true;
      this.showLoader = true;
    } else if (!isPending && this.isValidationPending) {
      this.showLoader = false;
      this.isValidationPending = false;
    }

  }

  private closeDropdownList(): void {
    this.filterInputElement.nativeElement.value = "";
    this.showDropdownList = false;
    this.dropdownHide.emit();
  }

  onFilterInputFocus(): void {
    if (this.filter.observers.length > 0) {
      if (this.alwaysTriggerSearch) {
        this.currentIndex = 0;
        this.visibleItems = [];
      }
      this.triggerFilterEvent();
    }
    if (!this.data && !this.data$) {
      this.visibleItems = [];
      this.loading = false;
      this.showLoader = false;
      this.footerDetails.visibleItemCount = 0;
      this.footerDetails.count = 0;
    }
    this.showDropdown();
    if (this.currentQuery) {
      this.filterInputElement.nativeElement.value = this.currentQuery;
      setTimeout(() => {
        this.filterInputElement.nativeElement.select();
      }, 50);
    }
  }

  get hasSelectedItems(): boolean {
    if (this.multiselect) {
      if (this.selectedOption === null || typeof this.selectedOption === 'undefined' || !Array.isArray(this.selectedOption) || this.selectedOption.length === 0) {
        return false;
      }
      return true;
    } else if (this.selectedOption) {
      return true;
    }
    return false;
  }

  selectItem(item: DropdownInput, event: Event = null): void {

    event?.stopPropagation();
    event?.preventDefault();
    event?.stopImmediatePropagation();

    if (!item || item.disabled) {
      return;
    }

    if (this.isSelected(item) && (this.cannotRemoveLastItem && this.multiSelectedOption && this.multiSelectedOption.length === 1)) {
      clearTimeout(this.blurTimeout);
      this.filterInputElement.nativeElement.focus();
      return;
    }

    if (this.multiselect && !this.multiselectHideOnSelection) {
      clearTimeout(this.blurTimeout);
      if (event) {
        this.filterInputElement.nativeElement.focus();
      }
      this.repositionDropdown();
    }

    if (this.multiselect) {
      if (!Array.isArray(this.selectedOption)) {
        this.selectedOption = [];
      }
      if (this.isSelected(item)) {
        this.removeSelectedOption(event, item);
      } else {
        this.selectedOption.push(item);
        if (!this.newMultiSelectDesign) {
          this.triggerTouched(null, this.selectedOption);
        }
      }

      //2025-01-30
      //If we have a multiselect dropdown without apply it automatically
      //closes today, even though multiselectHideOnSelection is false.
      //Not sure what desired default behavior should be, but below code will close
      //dropdown on select/deselect if multiselect without apply
      if (!this.multiselectApply) {
        this.closeDropdownList();
      }

      this.multiSelectOptionsChanged = this.hasMultiSelectedOptionsChanged();
    } else {
      this.selectedOption = item;
      this.triggerTouched(null, item);
      this.closeDropdownList();
    }


    if (this.multiselect && !this.multiselectHideOnSelection) {
      setTimeout(() => { this.repositionDropdown(); });
    }

  }

  get isItemSelected(): boolean {
    if (this.selectedOption && !Array.isArray(this.selectedOption)) {
      return true;
    }
    return false;
  }

  showDropdown(): void {
    this.repositionDropdown();
    if (this.multiselectApply && !this.showDropdownList) {
      this.userClickedApply = false;      
      this.cache.add(`${this.internalId}_items`, this.selectedOption, null, CacheScope.UserSessionStorage);
    }

    this.multiSelectOptionsChanged = this.hasMultiSelectedOptionsChanged();

    this.attachWindowClickListener();

    this.showDropdownList = true;
    this.dropdownShow.emit();
  }

  private attachWindowClickListener(): void {
    if (!this.windowClickSubscription || this.windowClickSubscription.closed) {
      this.windowClickSubscription = fromEvent<MouseEvent>(window, 'mousedown')
      .subscribe((e) => {
        // Click on closed dropdown
        if (this.showDropdownList && (e.target == this.dataDrivenDropdownElement.nativeElement || this.dataDrivenDropdownElement.nativeElement.contains(e.target as HTMLElement))) {
          return;
        }

        // Close if clicked outside of element
        if (this.dropdownListElement && e.target != this.dropdownListElement.nativeElement && !this.dropdownListElement.nativeElement.contains(e.target as HTMLElement)) {
          if (!this.userClickedApply && this.multiselectApply) {
            this.revertMultiselectChanges();
          }

          this.closeDropdownList();
          this.windowClickSubscription?.unsubscribe();
        }
      });
    }
  }

  repositionDropdown(): void {
    const rect = this.dataDrivenDropdownElement.nativeElement.getBoundingClientRect();
    const childRect = this.dataDrivenDropdownElement.nativeElement.firstElementChild.getBoundingClientRect();
    this.dropdownListElement.nativeElement.style.width = rect.width + 'px';
    this.dropdownListElement.nativeElement.style.top = childRect.height + 'px';
  }

  private onDataChange(data: DropdownData): void {
    if (this.isInitLoad) {
      this.isInitLoad = false;
      this.initLoad.emit(data?.totalCount);
    }

    this.dropDownData = data;

    if (this.currentIndex > 0) {
      this.visibleItems = this.visibleItems.concat(data.items);
    } else {
      this.visibleItems = data.items;
      this.isLoadingChildCount = -1;
    }
    this.footerDetails = {
      count: data.totalCount ? data.totalCount : (data.items ? data.items.length : undefined),
      visibleItemCount: this.visibleItems.length - this.counterIndexSetter,
      hasMoreResults: data.hasMoreResults
    };
    this.showLoader = false;
  }

  scrollHandler($event: Event): void {
    if (this.dropDownData.hasMoreResults) {
      const target: HTMLElement = $event.target as HTMLElement;
      const lastChild: HTMLElement = ($event.target as HTMLDivElement).lastElementChild as HTMLElement;
      const listHeight = target.getBoundingClientRect().height;
      if (lastChild) {
        if (target.scrollTop + listHeight > lastChild.offsetTop) {
          if (this.isLoadingChildCount === target.children.length) {
            return;
          }
          this.isLoadingChildCount = target.children.length;
          this.currentIndex += this.take;
          this.triggerFilterEvent();
        }
      }
    }
  }

  /**
 * Keyboard navigation - highlight next item in list
 */
  private highlightNextItem(): void {
    if (this.activeListIndex < this.visibleItems.length - 1) {
      this.activeListIndex++;
      if (!this.multiselect) {
        this.selectedOption = this.visibleItems[this.activeListIndex];
      }
    } else {
      return;
    }

    if (this.activeListIndex !== -1) {
      const elm = this.dropdownListElement.nativeElement.querySelectorAll(`.datadriven-item:nth-child(${this.activeListIndex + 1})`);
      if (elm.length > 0) {
        elm[0].scrollIntoViewIfNeeded();
      }
    }
  }

  /**
   * Keyboard navigation - highlight previous item in list
   */
  private highlightPrevItem(): void {
    if (this.activeListIndex > 0) {
      this.activeListIndex--;
      if (!this.multiselect) {
        this.selectedOption = this.visibleItems[this.activeListIndex];
      }
    } else {
      return;
    }

    if (this.activeListIndex !== -1) {
      const elm = this.dropdownListElement.nativeElement.querySelectorAll(`.datadriven-item:nth-child(${this.activeListIndex + 1})`);
      if (elm.length > 0) {
        elm[0].scrollIntoViewIfNeeded();
      }
    }
  }

  filterInputChange(value: string, event: KeyboardEvent): void {
    if (event.type === 'keydown') {

      const isArrowDown = event.code === 'ArrowDown';
      const isArrowUp = event.code === 'ArrowUp';
      const isEnter = event.code === 'Enter';

      if (isArrowDown || isArrowUp || isEnter) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (isArrowDown) {
        return this.highlightNextItem();
      } else if (isArrowUp) {
        return this.highlightPrevItem();
      } else if (isEnter) {
        if (this.activeListIndex !== -1) {
          setTimeout(() => {
            this.selectItem(this.visibleItems[this.activeListIndex]);
          }, 10);
        }
        return;
      }
    }

    if (this.filterTimer) {
      clearTimeout(this.filterTimer);
    }

    value = value?.trim();

    if (value !== this.currentQuery || this.alwaysTriggerSearch) {
      this.showLoader = true;
      this.filterTimer = window.setTimeout(() => {
        this.visibleItems = [];
        this.currentIndex = 0;
        this.currentQuery = value;
        this.triggerFilterEvent();
      }, this.filterDelayMs);
    }
  }

  /**
   * Will force dropdown to execute a new search the next time dropdown is shown
   */
  public removeLastSearch(): void {
    this.lastEventData = null;
    this.visibleItems = [];
  }

  /**
   * Trigger the filter event so the consuming component can fetch more data
   */
  triggerFilterEvent(): void {
    const eventData = <DropdownArgs>{
      index: this.currentIndex,
      query: this.currentQuery,
      take: this.take
    },
      queryIdentifier = `${this.currentIndex}/${this.currentQuery}/${this.take}`;

    if (this.lastEventData === queryIdentifier && !this.alwaysTriggerSearch) {
      // This filter event was the same as the last one. Do nothing
      return;
    }

    this.lastEventData = queryIdentifier;

    this.showLoader = true;
    this.dataDrivenStatus = DataDrivenStatus.FilterTriggered;
    if (this.dataSource) {
      this.data = this.dataSource.filter(eventData);
    } else {
      this.filter.emit(eventData);
    }
  }

  // Function to call when the rating changes.
  // Here we must store changes so we can trigger when registerOnChange is invoked
  onChange = (value: DropdownInput | DropdownInput[]): void => {
    this.pendingChanges.push(value);
  };

  // Function to call when the input is touched (when value is changed).
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = (): void => { };

  get value(): string | DropdownInput[] {
    if (this.multiselect) {
      return <DropdownInput[]>this.selectedOption || null;
    } else {
      return this.selectedOption ? (<DropdownInput>this.selectedOption).value : null;
    }
  }

  /**
   * Invoked when Angular sets the form field value
   */
  writeValue(obj: DropdownInput | DropdownInput[]): void {

    if (!obj) {
      this.lastEventData = null;
    }

    const nullOrUndefined = obj != null && typeof obj !== 'undefined';
    if (this.multiselect) {
      if (nullOrUndefined) {
        this.selectedOption = null;
        this.triggerChange(null);
      } else {
        this.selectedOption = <DropdownInput[]>obj;
        this.triggerChange(obj);
      }
    }

    this.activeListIndex = -1;

    if (nullOrUndefined) {
      if (this.visibleItems) {
        const itemIndexInList = this.visibleItems.findIndex(i => i.value === obj);
        if (itemIndexInList !== -1) {
          this.activeListIndex = itemIndexInList;
          this.selectedOption = this.visibleItems[itemIndexInList];
          this.triggerChange(this.items[itemIndexInList]);
        } else {
          this.selectedOption = <DropdownInput>obj;
          this.triggerChange(obj);
        }
      } else {
        this.selectedOption = <DropdownInput>obj;
        this.triggerChange(obj);
      }
    } else {
      this.selectedOption = null;
      this.triggerChange(null);
    }

  }

  triggerChange(event: DropdownInput | DropdownInput[]): void {
    // Do things before triggering the change?
    this.onChange(event);
    this.valueChange.emit(event);
  }

  triggerTouched(_event: Event, selectedItem: DropdownInput | DropdownInput[] = null): void {
    this.onTouched();
    this.triggerChange(selectedItem ? selectedItem : null);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
