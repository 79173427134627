
import { Subscription, filter, firstValueFrom, fromEvent, map, skip, timer } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, ViewChild, TemplateRef, EmbeddedViewRef, ViewContainerRef, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { DownloadedManualErrorCode, DownloadedManualFileType, DownloadedManualStatus, JobStatus, MediaSyncStatusVm } from 'root/mibp-openapi-gen/models';
import { MediaApiController } from 'root/mibp-openapi-gen/controllers';
import { ApiService, BroadcastService, GlobalConfigService, LogService, MibpHttpApi, MibpLogger, SignalR_ManualDownloadRequestedByUserEvent, ToastService } from 'root/services';
import { DownloadedManualsService } from 'root/services/downloaded-manuals/downloaded-manuals.service';
import { ActiveMibpToast } from 'root/services/toast-service/toast.service.interface';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MySandvikFeatures } from 'root/services/permission';
import { UserAction } from '../context-menu';

/**
 * Contains the calculated status of what to show at a given point
 * based on all the different input values
 */
interface CalculatedStatus {
  buttonToShow: 'request-download' | 'download' | 'inprogress' | 'queued';
  isDownloadedByCurrentUser: boolean;
  statusToShow: 'error' | 'updating' | null;
  showBlueButton: boolean;
  buttonResourceString: string;
  buttonIconName: string;
  fileTypeAsString: string;
  buttonIsDisabled: boolean;
}

interface PopupPosition {
  x: number; 
  y: number, 
  fullWidth?: boolean
}

@Component({
  selector: 'mibp-manual-download-button',
  templateUrl: './manual-download-button.component.html',
  styleUrl: './manual-download-button.component.scss',

})
export class ManualDownloadButtonComponent implements OnInit, OnDestroy, OnChanges {

  private static instanceIdCounter = 0;
  protected downloadFileTypes = DownloadedManualFileType;
  protected downloadedManualStatus = DownloadedManualStatus;
  protected errorCodes = DownloadedManualErrorCode;
  protected jobStatus = JobStatus;
  private log: MibpLogger;
  private activeToast?: ActiveMibpToast;
  private activeToastActionSub?: Subscription;
  private activeToastCloseSub?: Subscription;
  private mediaDownloadEventsSub?: Subscription;
  private manualDownloadRequestedByUserSub?: Subscription; 
  protected canDownloadManuals = false;
  isSupportTeamMember = false;
  protected mediaSyncStatus?: MediaSyncStatusVm;
  protected loadingSyncStatus = false;
  protected componentId: number;
  protected showStatus = false;

  // For the popover
  @ViewChild('statusPopupTemplate', {static: true}) statusPopupTemplate: TemplateRef<any>;
  private embeddedView: EmbeddedViewRef<any>;
  private clickOutsideSub?: Subscription;
  private scrollSub?: Subscription;
  private resizeSub?: Subscription;
  private escapeSub?: Subscription;
  
  protected calculatedStatus: CalculatedStatus;

  @Input() equipmentId: number;
  @Input() downloadErrorCode: DownloadedManualErrorCode;
  @Input() downloadStatus: DownloadedManualStatus;
  @Input() downloadedManualId?: number;
  @Input() fileSize?: number;
  @Input() fileType?: DownloadedManualFileType;
  @Input() mediaId: number;
  @Input() mediaName?: string;
  @Input() shelf?: string ;
  @Input() shelfId: number;
  @Input() userDownloadedManualId?: number;
  @Input() displayBlock = true;

  protected statusPosition: PopupPosition;

  // Menu options for support team members to simulate button statuses
  protected supportMenuItems: UserAction[] = [
    // {textOverride: 'Trigger expiration', name: 'expire-download', 'iconName': 'auto_delete', danger: true},
    // {textOverride: 'Queue update of Downloaded Manual', name: 'queue-update', 'iconName': 'play_arrow'},
    // {separator: true, name: 's1'},
    {textOverride: '1.1 PDF. Has file size. User downloaded. (Blue+type+file size)', name: 'scenario1_1'},
    {textOverride: '1.2 Zip. Has file size. User has Not downloaded (Gray+type+file size)', name: 'scenario1_2'},
    {textOverride: '3. File size is null. Status unknown. ("Request download")', name: 'scenario3'},
    {textOverride: '4.1 File size is null. Status queued. User downloaded. ("Queued")', name: 'scenario4_1'},
    {textOverride: '4.2 Queued. User has not requested download ("Request download")', name: 'scenario4_2'},
    {textOverride: '5.1 Zip. Has file size. User downloaded. Updating. (Blue button + update icon)', name: 'scenario5_1'},
    {textOverride: '5.2 Txt. Has file size. Not user downloaded. Updating. (Gray button + update icon)', name: 'scenario5_2'},
    {textOverride: '6.1 Download requested (Disabled "In progress" button with spinner)', name: 'scenario6_1'},
    {textOverride: '6.2 In progress. Download requested by other user ("Request download")', name: 'scenario6_2'},
    {textOverride: '7.1 Error. Download requested by other user ("Request download")', name: 'scenario7_1'},
    {textOverride: '7.2 Error. Download requested by user ("Request download" + Error status)', name: 'scenario7_2'}

  ];


  constructor(
    private signalRApi: ApiService,
    protected session: MibpSessionService,
    private mediaApiController: MediaApiController,
    private downloadedManualsService: DownloadedManualsService,
    protected globalConfigService: GlobalConfigService,
    private mibpHttp: MibpHttpApi,
    private viewContainerRef: ViewContainerRef,
    private broadcastService: BroadcastService,
    logService: LogService,
    private elementRef: ElementRef,
    private toastService: ToastService) {
    this.log = logService.withPrefix('mibp-manual-download-button');
  }
  ngOnChanges(_: SimpleChanges): void {
    this.refreshUI();
  }
  ngOnDestroy(): void {
    this.mediaDownloadEventsSub?.unsubscribe();
    this.manualDownloadRequestedByUserSub?.unsubscribe();
    this.embeddedView?.destroy();
    this.clickOutsideSub?.unsubscribe();
    this.resizeSub?.unsubscribe();
    this.scrollSub?.unsubscribe();
    this.escapeSub?.unsubscribe();

    if (this.activeToast) {
      this.toastService.removeToast(this.activeToast.toastId);
      this.activeToastActionSub?.unsubscribe();
      this.activeToastCloseSub?.unsubscribe();
    }
  }

  private get element(): HTMLElement {
    return this.elementRef.nativeElement as HTMLElement;
  }

  private downloadManualStatusToString(s: DownloadedManualStatus): string {
    switch(s) {
    case DownloadedManualStatus.Downloaded: return 'Downloaded';
    case DownloadedManualStatus.Expired: return 'Expired';
    case DownloadedManualStatus.Queued: return 'Queued';
    case DownloadedManualStatus.Error: return 'Error';
    case DownloadedManualStatus.InProgress: return 'InProgress';
    case DownloadedManualStatus.Unknown: return 'Unknown';
    }
  }

  private whenElementHasWidth(element: HTMLElement, callback: (rect: DOMRect) => void): void {
    const tick = function() {
      const rect = element?.getBoundingClientRect();
      if (rect?.width > 0) {
        callback(rect);
      } else {
        window.setTimeout(() => tick(), 150);
      }
    };

    window.setTimeout(() => tick(), 150);

  }

  private async whenElementExists(selector: string,): Promise<HTMLElement> {
    return new Promise<HTMLElement>(resolve => {
      const tick = (): void => {
        const element = document.querySelector(selector) as HTMLElement;
        if (element) {
          resolve(element);
        } else {
          window.setTimeout(() => tick(), 50);
        }
      };
      window.setTimeout(() => tick(), 50);
    });
  }

  private async calculatePopoverPosition(): Promise<PopupPosition> {
    const popoverElement = await this.whenElementExists('.manual-button-status-popover');
    const componentPosition = this.element.getBoundingClientRect();
    const popoverElementSize = popoverElement.getBoundingClientRect();
    const screenSize = this.broadcastService.snapshot.responsiveBreakpoint;

    const result: PopupPosition = {
      x: componentPosition.left,
      y: componentPosition.top + componentPosition.height + window.scrollY + 4,
      fullWidth: false
    };

    // If content is below visible area then show it above the element
    if (result.y + popoverElementSize.height > (window.innerHeight + window.scrollY)) {
      result.y = componentPosition.top - popoverElementSize.height - 4 + window.scrollY;
    }

    return new Promise<PopupPosition>(resolve => {
      timer(10).subscribe(() => {
        if (screenSize.lteq('xs')) {
          result.x = 15;
          result.fullWidth = true;
        } else {
          // If element is scraping right edge of browser window, then anchor to the 
          if (result.x + popoverElementSize.width + 15 > (window.innerWidth - 15)) {
            result.x = componentPosition.left - ( popoverElementSize.width - componentPosition.width);
          }
        }
        resolve(result);
      });      
    });
  }

  private get popoverElement(): HTMLElement {
    return document.querySelector('.manual-button-status-popover') as HTMLElement;
  }

  protected async openStatus(): Promise<void> {
    const bodyElement = document.querySelector('body');
    this.embeddedView = this.viewContainerRef.createEmbeddedView(this.statusPopupTemplate);
    this.embeddedView.rootNodes.forEach(rootNode => bodyElement.appendChild(rootNode));

    this.whenElementHasWidth(this.popoverElement, async (e) => {

      this.statusPosition = await this.calculatePopoverPosition();

      new ResizeObserver(async () => {
        this.statusPosition = await this.calculatePopoverPosition();
      }).observe(this.popoverElement);

      this.showStatus = true;

      if (this.session.current.user.isSupportTeamMember) {
        this.loadExtraManualErrorInfo();
      }
      
    });

    this.clickOutsideSub = fromEvent(window, 'click').pipe(
      skip(1),
      map(event => event.target as HTMLElement),
      filter(elm => {
        return !elm.closest('.manual-button-status-popover') || !!(elm.closest('.manual-button-status-popover') && elm.closest('span.close'));
      })
    ).subscribe(() => this.hideStatus());

    this.scrollSub = fromEvent(window, 'scroll')
      .subscribe(() => this.hideStatus());

    this.resizeSub = fromEvent(window, 'resize')
      .subscribe(() => this.hideStatus());

    this.escapeSub = fromEvent<KeyboardEvent>(window, 'keydown')
      .pipe(
        filter(k => k.key == 'Escape')
      )
      .subscribe(() => this.hideStatus());
  }

  private hideStatus(): void {
    this.showStatus = false;
    timer(250).subscribe(() => {
      this.embeddedView?.destroy();
      this.clickOutsideSub?.unsubscribe();
      this.resizeSub?.unsubscribe();
      this.scrollSub?.unsubscribe();
      this.escapeSub?.unsubscribe();
      this.statusPosition = null;
      this.mediaSyncStatus = null;
    });
  }
  
  private async loadExtraManualErrorInfo(): Promise<void> {
    try {
      this.loadingSyncStatus = true;
      this.mediaSyncStatus = await firstValueFrom(this.mediaApiController.getMediaSyncStatusById({
        mediaId: this.mediaId
      }));
     
    } catch (e) {
      this.log.error('Could not load media sync status', e);
    } finally {
      this.loadingSyncStatus = false;
    }
  }

  ngOnInit(): void {
    ManualDownloadButtonComponent.instanceIdCounter++;
    this.componentId = ManualDownloadButtonComponent.instanceIdCounter;

    this.isSupportTeamMember = this.session.current?.user?.isSupportTeamMember;
    this.canDownloadManuals = this.session.hasFeature(MySandvikFeatures.MyfleetDownloadManuals);

    this.mediaDownloadEventsSub = this.signalRApi.EventHub.ManualDownloadStatusUpdate.subscribe(async event => {
      this.log.info('ManualDownloadStatusUpdate', event ? this.downloadManualStatusToString(<any>event.status) : null, event);

      if (event) {
        if (this.mediaId ==  event.mediaId) {
          this.downloadStatus = (<any>event.status as DownloadedManualStatus);
          this.fileSize = event.pdfFileSize;
          this.downloadErrorCode = <any>event.downloadErrorCode;
          this.fileType = <any>event.fileType;
          this.refreshUI();
        }
      }
    });

    this.manualDownloadRequestedByUserSub = this.signalRApi
      .EventHub
      .ManualDownloadRequestedByUser
      .pipe(filter(event => !!event))
      .subscribe(event => this.onUserDownloadedManualIdUpdateEvent(event));
  }

  /**
   * Event occurs when a UserDownloadedManuals row is added for the user
   */
  private onUserDownloadedManualIdUpdateEvent(event: SignalR_ManualDownloadRequestedByUserEvent): void {
    // Update the UserDownloadedManual id so we show gray/blue button accordingly
    this.userDownloadedManualId = event.userDownloadedManualId;
    this.refreshUI();
    this.log.info('User requested manual download', event);
  }


  async onDownloadManualClick(): Promise<void> {

    if (!this.fileSize) {
      const previousStatus = this.downloadStatus;
      try {
        this.downloadStatus = DownloadedManualStatus.Queued;
        this.refreshUI();

        const result = await firstValueFrom(this.mediaApiController.requestManualDownload({
          mediaId: this.mediaId,
          equipmentId: this.equipmentId
        }));

        this.downloadStatus = result.userDownloadedManuals[0].downloadedManualStatus;
        this.fileSize = result.userDownloadedManuals[0].fileSize;
        this.userDownloadedManualId = result.userDownloadedManuals[0].userDownloadedManualId;
        this.refreshUI();

        if (this.activeToast) {
          this.activeToastActionSub?.unsubscribe();
          this.activeToastActionSub?.unsubscribe();
          this.toastService.removeToast(this.activeToast.toastId);
        }

        if (this.downloadStatus == DownloadedManualStatus.Downloaded) {
          this.downloadedManualsService.showDownloadReadyToast(this.mediaName);
        } else {
          this.downloadedManualsService.showExportStartedToast(this.mediaName);
        }

      } catch (e) {
        this.userDownloadedManualId = null;
        this.downloadStatus = previousStatus;
        this.downloadedManualsService.showErrorStartingExport();
        this.log.error('Error starting export of media', e);
      }

    } else {
      window.location.href = this.mibpHttp.resolveUriWithJwtToken(`media/${this.mediaId}/${this.equipmentId}/download`);

      // When a user downloads a manual that is already available in My Sandvik, but user has not requested the manual

      if (!this.userDownloadedManualId) {
        this.downloadedManualsService.showManualAddedToDownloadsToast(this.mediaName);
        this.userDownloadedManualId = -1; // We will not know the id, but it should exist...
      }
    }
  }

//TODO Remove test methods when refactoring has completed.
  //#region METHODS USED FOR TESTING SCENARIOS, can be used when refactor logic.

  protected supportAction(e: UserAction): void {
    this[e.name]();
    this.refreshUI();
  }

  private refreshUI(): void {


    const calculatedStatus: CalculatedStatus = {
      buttonIconName: 'play_circle',
      buttonIsDisabled: false,
      buttonResourceString: 'DownloadedManual_RequestDownloadButton',
      buttonToShow: 'request-download',
      fileTypeAsString: this.fileTypeToString(this.fileType),
      isDownloadedByCurrentUser: this.userDownloadedManualId > 0,
      showBlueButton: false,
      statusToShow: null
    };

    const hasFilesize = this.fileSize != null && typeof this.fileSize != 'undefined';

    if (hasFilesize) {
      // If we have a file size we will always show download button
      calculatedStatus.buttonToShow = 'download';
      if (this.downloadStatus == DownloadedManualStatus.Queued || this.downloadStatus == DownloadedManualStatus.InProgress) {
        calculatedStatus.statusToShow = 'updating';
      }
      calculatedStatus.showBlueButton = calculatedStatus.isDownloadedByCurrentUser;
    } else if (calculatedStatus.isDownloadedByCurrentUser) {
      if (this.downloadStatus == DownloadedManualStatus.Queued) {
        calculatedStatus.buttonToShow = 'queued';
      } else if (this.downloadStatus == DownloadedManualStatus.InProgress) {
        calculatedStatus.buttonToShow = 'inprogress';
      }
    }

    if (this.downloadStatus == DownloadedManualStatus.Error && calculatedStatus.isDownloadedByCurrentUser) {
      calculatedStatus.statusToShow = 'error';
    }

    if (calculatedStatus.buttonToShow == 'download') {
      calculatedStatus.buttonResourceString = null;
      calculatedStatus.buttonIconName = 'download';
    } else if (calculatedStatus.buttonToShow == 'queued') {
      calculatedStatus.buttonResourceString = `DownloadedManualStatusCode_${this.downloadStatus}`;
      calculatedStatus.buttonIconName = 'hourglass';
      calculatedStatus.buttonIsDisabled = true;
    } else if (calculatedStatus.buttonToShow == 'inprogress') {
      calculatedStatus.buttonResourceString = `DownloadedManualStatusCode_${this.downloadStatus}`;
      calculatedStatus.buttonIconName = 'progress_activity';
      calculatedStatus.buttonIsDisabled = true;
    }

    this.calculatedStatus = calculatedStatus;

  }

  private fileTypeToString(type: DownloadedManualFileType): string {
    switch (type) {
    case DownloadedManualFileType.MicrosoftExcelOpenXml:
    case DownloadedManualFileType.MicrosoftExcel: 
      return 'Excel';
    case DownloadedManualFileType.Pdf: return 'PDF';
    case DownloadedManualFileType.PlainText: return 'Text';
    case DownloadedManualFileType.Zip: return 'Zip';
    default: return '';
    }
  }



  //User requested (blue button)
  //If fileSize is not null we can ignore status and always show Download button - so then request download button should always be hidden
  scenario1_1(){
    this.fileType = DownloadedManualFileType.Pdf;
    this.fileSize = 100;
    this.downloadStatus = null;
    this.userDownloadedManualId = 1;

  }

  //User NOT requested (grey button)
  //If fileSize is not null we can ignore status and always show Download button - so then request download button should always be hidden
  scenario1_2(){
    this.fileType = DownloadedManualFileType.Zip;
    this.fileSize = 150;
    this.downloadStatus = null;
    this.userDownloadedManualId = null;
  }

  //If fileSize is null then we should show Request Download button when status is Unknown, Error or Expired
  scenario3(){
    this.fileSize = null;
    this.downloadStatus = this.downloadedManualStatus.Unknown;
  }

  //User requested
  //If fileSize is null and status is queued we should show a disabled "queued" button
  //(The queued/inprogress should only show if the current user has requested the file previously. Otherwise it will still show "Request download".()
  scenario4_1(){
    this.fileSize = null;
    this.downloadStatus = this.downloadedManualStatus.Queued;
    this.userDownloadedManualId = 1;
  }

  //User NOT requested
  //show "Request download"
  //The queued/inprogress should ONLY show if the current user has requested the file previously. Otherwise it will still show "Request download".()
  scenario4_2(){
    this.fileSize = null;
    this.downloadStatus = this.downloadedManualStatus.Queued;
    this.userDownloadedManualId = null;
  }


  //User requested
  //download button displayed in blue and also an icon informing that an update is in progress.
  scenario5_1(){
    this.fileType = DownloadedManualFileType.Zip;
    this.fileSize = 150;
    this.downloadStatus = this.downloadedManualStatus.Queued;
    this.userDownloadedManualId = 1;
  }

  //User NOT requested
  //download button displayed in GREY and also an icon informing that an update is in progress.
  scenario5_2(){
    this.fileSize = 150;
    this.fileType = DownloadedManualFileType.PlainText;
    this.downloadStatus = this.downloadedManualStatus.Queued;
    this.userDownloadedManualId = null;

  }

  //User has requested
  //show a disabled "in progress" button with a spinner
  //If fileSize is null and status is inprogress we should show a disabled "in progress" button with a spinner (same here, not sure if it's that the same button in new component)
  scenario6_1(){
    this.fileSize = null;
    this.downloadStatus = this.downloadedManualStatus.InProgress;
    this.userDownloadedManualId = 1;
  }


  //User has NOT requested
  //Show request button
  scenario6_2(){
    this.fileSize = null;
    this.downloadStatus = this.downloadedManualStatus.InProgress;
    this.userDownloadedManualId = null;
  }

  scenario7_1(){
    this.fileSize = null;
    this.downloadStatus = this.downloadedManualStatus.Error;
    this.userDownloadedManualId = null;
  }

  scenario7_2(){
    this.fileSize = null;
    this.downloadStatus = this.downloadedManualStatus.Error;
    this.userDownloadedManualId = 1;
  }
  

  //#endregion

}
