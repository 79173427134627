/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createOpportunity } from '../fn/machine-upgrades/create-opportunity';
import { CreateOpportunity$Params } from '../fn/machine-upgrades/create-opportunity';
import { delete$ } from '../fn/machine-upgrades/delete';
import { Delete$Params } from '../fn/machine-upgrades/delete';
import { EquipmentUpgrade } from '../models/equipment-upgrade';
import { EquipmentUpgradeEditVm } from '../models/equipment-upgrade-edit-vm';
import { getMachineUpgradeForEdit } from '../fn/machine-upgrades/get-machine-upgrade-for-edit';
import { GetMachineUpgradeForEdit$Params } from '../fn/machine-upgrades/get-machine-upgrade-for-edit';
import { getMachineUpgradeForEdit$Plain } from '../fn/machine-upgrades/get-machine-upgrade-for-edit-plain';
import { GetMachineUpgradeForEdit$Plain$Params } from '../fn/machine-upgrades/get-machine-upgrade-for-edit-plain';
import { importMachineUpgrades } from '../fn/machine-upgrades/import-machine-upgrades';
import { ImportMachineUpgrades$Params } from '../fn/machine-upgrades/import-machine-upgrades';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { saveMachineUpgrades } from '../fn/machine-upgrades/save-machine-upgrades';
import { SaveMachineUpgrades$Params } from '../fn/machine-upgrades/save-machine-upgrades';
import { saveMachineUpgrades$Plain } from '../fn/machine-upgrades/save-machine-upgrades-plain';
import { SaveMachineUpgrades$Plain$Params } from '../fn/machine-upgrades/save-machine-upgrades-plain';
import { searchEquipmentMachineUpgradeDetails } from '../fn/machine-upgrades/search-equipment-machine-upgrade-details';
import { SearchEquipmentMachineUpgradeDetails$Params } from '../fn/machine-upgrades/search-equipment-machine-upgrade-details';
import { searchEquipmentMachineUpgradeDetails$Plain } from '../fn/machine-upgrades/search-equipment-machine-upgrade-details-plain';
import { SearchEquipmentMachineUpgradeDetails$Plain$Params } from '../fn/machine-upgrades/search-equipment-machine-upgrade-details-plain';
import { searchEquipmentMachineUpgrades } from '../fn/machine-upgrades/search-equipment-machine-upgrades';
import { SearchEquipmentMachineUpgrades$Params } from '../fn/machine-upgrades/search-equipment-machine-upgrades';
import { searchEquipmentMachineUpgrades$Plain } from '../fn/machine-upgrades/search-equipment-machine-upgrades-plain';
import { SearchEquipmentMachineUpgrades$Plain$Params } from '../fn/machine-upgrades/search-equipment-machine-upgrades-plain';
import { SearchEquipmentUpgradeResultItemVm } from '../models/search-equipment-upgrade-result-item-vm';
import { SearchEquipmentUpgradeResultItemVmSearchResponse } from '../models/search-equipment-upgrade-result-item-vm-search-response';
import { searchMachineUpgrades } from '../fn/machine-upgrades/search-machine-upgrades';
import { SearchMachineUpgrades$Params } from '../fn/machine-upgrades/search-machine-upgrades';
import { searchMachineUpgrades$Plain } from '../fn/machine-upgrades/search-machine-upgrades-plain';
import { SearchMachineUpgrades$Plain$Params } from '../fn/machine-upgrades/search-machine-upgrades-plain';

@Injectable({ providedIn: 'root' })
export class MachineUpgradesApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `machineUpgradesControllerCreateOpportunityopportunityRequest()` */
  static readonly MachineUpgradesControllerCreateOpportunityopportunityRequestPath = '/machineupgrades/opportunity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOpportunity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOpportunity$Response(params?: CreateOpportunity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createOpportunity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOpportunity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOpportunity(params?: CreateOpportunity$Params, context?: HttpContext): Observable<void> {
    return this.createOpportunity$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `machineUpgradesControllerImportMachineUpgradesimportVm()` */
  static readonly MachineUpgradesControllerImportMachineUpgradesimportVmPath = '/machineupgrades/import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importMachineUpgrades()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importMachineUpgrades$Response(params?: ImportMachineUpgrades$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return importMachineUpgrades(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importMachineUpgrades$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importMachineUpgrades(params?: ImportMachineUpgrades$Params, context?: HttpContext): Observable<void> {
    return this.importMachineUpgrades$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `machineUpgradesControllerSearchMachineUpgradesoptions()` */
  static readonly MachineUpgradesControllerSearchMachineUpgradesoptionsPath = '/machineupgrades/search';

  /**
   * Search for products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchMachineUpgrades$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchMachineUpgrades$Plain$Response(params?: SearchMachineUpgrades$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchMachineUpgrades$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchMachineUpgrades$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchMachineUpgrades$Plain(params?: SearchMachineUpgrades$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchMachineUpgrades$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchMachineUpgrades()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchMachineUpgrades$Response(params?: SearchMachineUpgrades$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchMachineUpgrades(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchMachineUpgrades$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchMachineUpgrades(params?: SearchMachineUpgrades$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchMachineUpgrades$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `machineUpgradesControllerSearchEquipmentMachineUpgradesrequest()` */
  static readonly MachineUpgradesControllerSearchEquipmentMachineUpgradesrequestPath = '/machineupgrades/search/equipmentmachineupgrades';

  /**
   * Search for machine upgrades for an equipment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEquipmentMachineUpgrades$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentMachineUpgrades$Plain$Response(params?: SearchEquipmentMachineUpgrades$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchEquipmentUpgradeResultItemVmSearchResponse>> {
    return searchEquipmentMachineUpgrades$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for machine upgrades for an equipment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEquipmentMachineUpgrades$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentMachineUpgrades$Plain(params?: SearchEquipmentMachineUpgrades$Plain$Params, context?: HttpContext): Observable<SearchEquipmentUpgradeResultItemVmSearchResponse> {
    return this.searchEquipmentMachineUpgrades$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchEquipmentUpgradeResultItemVmSearchResponse>): SearchEquipmentUpgradeResultItemVmSearchResponse => r.body)
    );
  }

  /**
   * Search for machine upgrades for an equipment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEquipmentMachineUpgrades()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentMachineUpgrades$Response(params?: SearchEquipmentMachineUpgrades$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchEquipmentUpgradeResultItemVmSearchResponse>> {
    return searchEquipmentMachineUpgrades(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for machine upgrades for an equipment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEquipmentMachineUpgrades$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentMachineUpgrades(params?: SearchEquipmentMachineUpgrades$Params, context?: HttpContext): Observable<SearchEquipmentUpgradeResultItemVmSearchResponse> {
    return this.searchEquipmentMachineUpgrades$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchEquipmentUpgradeResultItemVmSearchResponse>): SearchEquipmentUpgradeResultItemVmSearchResponse => r.body)
    );
  }

  /** Path part for operation `machineUpgradesControllerSearchEquipmentMachineUpgradeDetailsrequest()` */
  static readonly MachineUpgradesControllerSearchEquipmentMachineUpgradeDetailsrequestPath = '/machineupgrades/search/upgradedetails';

  /**
   * Search for products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEquipmentMachineUpgradeDetails$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentMachineUpgradeDetails$Plain$Response(params?: SearchEquipmentMachineUpgradeDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchEquipmentUpgradeResultItemVm>> {
    return searchEquipmentMachineUpgradeDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEquipmentMachineUpgradeDetails$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentMachineUpgradeDetails$Plain(params?: SearchEquipmentMachineUpgradeDetails$Plain$Params, context?: HttpContext): Observable<SearchEquipmentUpgradeResultItemVm> {
    return this.searchEquipmentMachineUpgradeDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchEquipmentUpgradeResultItemVm>): SearchEquipmentUpgradeResultItemVm => r.body)
    );
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchEquipmentMachineUpgradeDetails()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentMachineUpgradeDetails$Response(params?: SearchEquipmentMachineUpgradeDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchEquipmentUpgradeResultItemVm>> {
    return searchEquipmentMachineUpgradeDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Search for products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchEquipmentMachineUpgradeDetails$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchEquipmentMachineUpgradeDetails(params?: SearchEquipmentMachineUpgradeDetails$Params, context?: HttpContext): Observable<SearchEquipmentUpgradeResultItemVm> {
    return this.searchEquipmentMachineUpgradeDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchEquipmentUpgradeResultItemVm>): SearchEquipmentUpgradeResultItemVm => r.body)
    );
  }

  /** Path part for operation `machineUpgradesControllerGetMachineUpgradeForEditupgradeId()` */
  static readonly MachineUpgradesControllerGetMachineUpgradeForEditupgradeIdPath = '/machineupgrades/search/{upgradeId}/edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineUpgradeForEdit$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMachineUpgradeForEdit$Plain$Response(params: GetMachineUpgradeForEdit$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentUpgradeEditVm>> {
    return getMachineUpgradeForEdit$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineUpgradeForEdit$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMachineUpgradeForEdit$Plain(params: GetMachineUpgradeForEdit$Plain$Params, context?: HttpContext): Observable<EquipmentUpgradeEditVm> {
    return this.getMachineUpgradeForEdit$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentUpgradeEditVm>): EquipmentUpgradeEditVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineUpgradeForEdit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMachineUpgradeForEdit$Response(params: GetMachineUpgradeForEdit$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentUpgradeEditVm>> {
    return getMachineUpgradeForEdit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineUpgradeForEdit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMachineUpgradeForEdit(params: GetMachineUpgradeForEdit$Params, context?: HttpContext): Observable<EquipmentUpgradeEditVm> {
    return this.getMachineUpgradeForEdit$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentUpgradeEditVm>): EquipmentUpgradeEditVm => r.body)
    );
  }

  /** Path part for operation `machineUpgradesControllerSaveMachineUpgradesMachineUpgrade()` */
  static readonly MachineUpgradesControllerSaveMachineUpgradesMachineUpgradePath = '/machineupgrades/savemachineupgrades';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveMachineUpgrades$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveMachineUpgrades$Plain$Response(params?: SaveMachineUpgrades$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentUpgrade>>> {
    return saveMachineUpgrades$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveMachineUpgrades$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveMachineUpgrades$Plain(params?: SaveMachineUpgrades$Plain$Params, context?: HttpContext): Observable<Array<EquipmentUpgrade>> {
    return this.saveMachineUpgrades$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentUpgrade>>): Array<EquipmentUpgrade> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveMachineUpgrades()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveMachineUpgrades$Response(params?: SaveMachineUpgrades$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EquipmentUpgrade>>> {
    return saveMachineUpgrades(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveMachineUpgrades$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveMachineUpgrades(params?: SaveMachineUpgrades$Params, context?: HttpContext): Observable<Array<EquipmentUpgrade>> {
    return this.saveMachineUpgrades$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EquipmentUpgrade>>): Array<EquipmentUpgrade> => r.body)
    );
  }

  /** Path part for operation `machineUpgradesControllerDeleteupgradeId()` */
  static readonly MachineUpgradesControllerDeleteupgradeIdPath = '/machineupgrades/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params?: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params?: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
