import { isFutureDateValidator } from 'root/validators/is-future-date.validator';

import { add, isValid } from 'date-fns';
import { Component, OnInit, ViewChild, Input, ElementRef, AfterViewInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonColors, ButtonStyles } from 'root/components/button/button.enum';
import { DialogComponent } from 'root/components/dialog/dialog.component';
import { DropdownArgs, DropdownData, DropdownInput } from 'root/components/dropdown';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { MediaApiController, SupportCaseApiController } from 'root/mibp-openapi-gen/controllers';

import { FormValidationService, FrontendContextService, LoaderService, LocalizationService, NoticebarService,GlobalConfigService, ToastService } from 'root/services';
import { Observable, Subscription, firstValueFrom, map } from 'rxjs';

import { ReportErrorDto } from './report-error-dto';
import { SupportCaseAttachmentDto, PartsManualSupportCaseRequest } from 'root/mibp-openapi-gen/models';
import { ToastType } from 'root/services/toast-service/toast.enum';
import { ActiveMibpToast } from 'root/services/toast-service/toast.service.interface';
import { Router } from '@angular/router';
import { MibpAllowedCharactersValidator } from 'root/validators/allowed-characters.validator';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { FileValidationService } from 'root/services/file-validation/file-type-validation.service';

@Component({
  selector: "mibp-electronics-manual-report-error-dialog",
  templateUrl: "./electronics-manual-reporterror.component.html",
  styleUrls: ["./electronics-manual-reporterror.component.scss"],
})
export class ElectronicManualReporterrorComponent implements OnInit, OnDestroy {
  @ViewChild(DialogComponent) reportErrorDialog: DialogComponent;
  @Input() request: ReportErrorDto;
  @Input() showEquipmentList = false;
  @ViewChildren("uploadInputElement")
  uploadInputElements: QueryList<ElementRef>;

  activeToast: ActiveMibpToast;
  toasterSubscription: Subscription;

  reportErrorForm: UntypedFormGroup;
  formValidated = false;
  uploadFormBusy = false;
  buttonColors = ButtonColors;
  buttonStyles = ButtonStyles;
  categories: DropdownInput[] = [];

  attachedImages: SupportCaseAttachmentDto[] = [];
  attachmentError = false;
  caseId: string;
  isUploadingAttachments = false;
  mustSelectEquipment = false;
  private stopUsingResources: () => void;
  categoryResourceStringKeys = [
    "PartsManualReport_ItemRelatedIssue",
    "PartsManualReport_IncorrectStructure",
    "PartsManualReport_PageMissing",
    "PartsManualReport_ManualMissing",
    "PartsManualReport_Other",
  ];
  categoryValues = [
    "Item related issue",
    "Incorrect structure",
    "Page missing",
    "Manual missing",
    "Other",
  ];

  fileName: string;
  fileNames: string[] = [];
  inputFields: number[] = [0];
  pageSize = 10;
  skip = 0;
  equipmentMedias$: Observable<DropdownData>;
  allowedFileExtensions: string = '';
  constructor(
    private fb: UntypedFormBuilder,
    private validation: FormValidationService,
    private element: ElementRef,
    private noticeService: NoticebarService,
    private loader: LoaderService,
    private supportCaseApi: SupportCaseApiController,
    private localizationService: LocalizationService,
    private toastService: ToastService,
    private frontEndContext: FrontendContextService,
    private globalConfigService: GlobalConfigService,
    private router: Router,
    private mediaApiController: MediaApiController,
    private sessionService: MibpSessionService,
    private fileValidationService: FileValidationService
  ) {}

  ngOnDestroy(): void {
    if (this.stopUsingResources) {
      this.stopUsingResources();
    }
    if (this.toasterSubscription) {
      this.toasterSubscription.unsubscribe();
    }
  }
  async ngOnInit(): Promise<void> {
    this.allowedFileExtensions = this.fileValidationService.getAllowedFileExtensions();
    this.loadForm();
  }

  loadForm() {
    this.isEquipmentRequired();
    this.initializereportForm();
    this.setResourceStringSubscription(
      this.categoryResourceStringKeys,
      (resourceStrings) => {
        const categories = [];
        resourceStrings.forEach((rs, index) => {
          categories.push(<DropdownInput>{
            value: this.categoryValues[index],
            text: rs,
          });
        });
        this.categories = categories;
      }
    );
  }
  isEquipmentRequired() {
    this.mustSelectEquipment =
      this.sessionService.current?.activeDeliverySequence == null &&
      this.sessionService.current?.user?.defaultDeliverySequence == null &&
      this.showEquipmentList;
  }
  setResourceStringSubscription(
    resourceStringKeys: string[],
    onComplete: (translatedStrings: string[]) => void
  ) {
    this.stopUsingResources = this.localizationService.using(
      resourceStringKeys,
      (resourceStrings) => {
        onComplete(resourceStrings);
      }
    );
  }

  initializereportForm() {
    this.formValidated = false;
    this.reportErrorForm = this.fb.group({
      category: [null, Validators.required],
      comment: [null, [Validators.required, MibpAllowedCharactersValidator()]],
      fileInput: [null],
      equipmentId: [null, this.mustSelectEquipment ? [Validators.required] : null],
      mediaIdentifier: [null],
    });
    this.attachedImages = [];
    this.fileNames = [];
    this.reportErrorForm.get("mediaIdentifier").disable();
  }

  async submitForm() {
    this.formValidated = true;
    if (this.reportErrorForm.invalid) {
      this.reportErrorForm.markAllAsTouched();
      this.validation.scrollToFirstError(this.reportErrorForm, this.element);
      return;
    }

    try {
      this.loader.showFullScreenLoader();

      //First we create the case in salesforce...
      const caseId = await firstValueFrom(
        this.supportCaseApi.submitPartsManualSupportCase({
          body: this.preparePartsManualSupportCaseRequest(),
        })
      );
      this.caseId = caseId;
      // ..then we add the attachments to the case
      await firstValueFrom(
        this.supportCaseApi.uploadAttachments({
          caseId: this.caseId,
          body: this.attachedImages,
        })
      );

      this.activeToast = this.toastService.showWithTitle(
        "PartsManualReport_Created",
        "SupportCase_SubmittedSuccessfully",
        {
          isHtml: false,
          type: ToastType.Success,
          disableTimeOut: false,
          buttons: [
            {
              id: "redirect",
              textResourceString: "SupportCases_Title",
            },
          ],
        }
      );

      if (this.activeToast.onButtonClick) {
        this.toasterSubscription = this.activeToast.onButtonClick.subscribe(
          (res) => {
            if (res.id == "redirect") {
              this.router.navigate([
                `/${this.frontEndContext.getLang()}/support-cases`,
              ]);
              this.toastService.removeToast(this.activeToast.toastId);
            }
          }
        );
      }

      this.closeDialog();
      this.loader.hideFullScreenLoader();
    } catch (error) {
      this.loader.hideFullScreenLoader();
      this.activeToast = this.toastService.showWithTitle(
        null,
        "PartsManualReport_ErrorMessage",
        {
          isHtml: false,
          type: ToastType.Error,
          disableTimeOut: false,
        }
      );
    }
  }

  preparePartsManualSupportCaseRequest() {
    let equipmentId = this.reportErrorForm.value.equipmentId;
    let identifier = this.reportErrorForm.value.mediaIdentifier?.value;
    let mediaName = this.reportErrorForm.value.mediaIdentifier?.text;
    return <PartsManualSupportCaseRequest>{
      category: this.reportErrorForm.controls["category"].value.value,
      comment: this.reportErrorForm.controls["comment"].value,
      chapter: this.request.chapter,
      page: this.request.page,
      equipmentId: equipmentId ?? this.request.equipmentId,
      mediaName: mediaName ?? this.request.mediaName,
      mediaIdentifier: identifier ?? this.request.mediaIdentifier,
      url:
        this.request.url +
        (identifier != null ? "/" + equipmentId + "/" + identifier : ""),
    };
  }

  // Handle file upload
  private async readfile(file: File): Promise<string> {
    const reader: FileReader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      reader.onload = (e: any) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  addFiles(): void {
    this.uploadInputElements.last.nativeElement.click();
    this.addFileInput();
  }
  addFileInput(): void {
    this.inputFields.push(0);
  }
  fileChange(e: Event): void {
    const input = e.target as HTMLInputElement;
    const validFileExtensions =
      this.globalConfigService.supportedImageFileExtensions
        .toLowerCase()
        .split(",") // Ensure it's an array
        .map((ext) => ext.trim().replace(".", ""));

    let invalidFileSelected = false;
    let invalidFileNames: string[] = []; // Store invalid file names in an array
    for (let i = 0; i < input.files.length; i++) {
      const file = input.files.item(i);
      const fileExtension = file.name.split(".").pop()?.toLowerCase(); // Extract extension safely

      if (fileExtension && validFileExtensions.includes(fileExtension)) {
        this.addSingleFile(file);
      } else {
        invalidFileSelected = true;
        invalidFileNames.push(file.name); // Add invalid file name to array
      }
    }

    if (invalidFileSelected) {
      this.toastService.showWithTitle(
        "Global_Invalid_ImageFileType_Message",
        "Global_Invalid_FileType_Title",
        {
          type: ToastType.Error,
          bodyResourceMacros: {
            fileNames: invalidFileNames.length
              ? invalidFileNames.join(", ")
              : "Unknown File",
            fileTypes:
              this.globalConfigService.supportedImageFileExtensions.toLowerCase(),
          },
        }
      );
    }
  }
  private async addSingleFile(file: File): Promise<void> {
    const fileContents = await this.readfile(file);
    const [fileMetadata, base64Data] = fileContents.split(",");
    this.fileNames.push(file.name);

    this.attachedImages.push(<SupportCaseAttachmentDto>{
      title: file.name,
      pathOnClient: file.name,
      description: "",
      fileCategory: "Pictures of Defect",
      body: fileContents,
    });
  }

  uploadAttachments() {
    this.isUploadingAttachments = true;
    firstValueFrom(
      this.supportCaseApi.uploadAttachments({
        caseId: this.caseId,
        body: this.attachedImages,
      })
    )
      .then((r) => {
        this.noticeService.show(
          "PartsManualReport_AttachmentsSuccess",
          NoticeType.Success
        );
        this.attachmentError = false;
        this.closeDialog();
        this.loader.hideFullScreenLoader();
      })
      .catch((ex) => {
        this.attachmentError = true;
        this.noticeService.show(
          "PartsManualReport_AttachmentsError",
          NoticeType.Error
        );
        this.isUploadingAttachments = false;
      });
  }
  deleteAttachment(filename: string) {
    this.fileNames.splice(this.fileNames.indexOf(filename), 1);
    this.attachedImages.splice(this.fileNames.indexOf(filename), 1);
  }

  openDialog() {
    this.loadForm();
    this.reportErrorDialog.open();
  }
  closeDialog() {
    this.attachmentError = false;
    this.reportErrorDialog.close();
  }

  private getMediasForEquipment(
    equipmentId: number,
    skip: number = null,
    take: number = null
  ) {
    this.equipmentMedias$ = this.mediaApiController
      .listEquipmentMedias({
        equipmentId: equipmentId,
        take: take ?? this.pageSize,
        skip: skip ?? this.skip,
      })
      .pipe(
        map(
          (searchResult) =>
            <DropdownData>{
              hasMoreResults:
                searchResult.totalCount > searchResult.items.length,
              items: searchResult.items.map(
                (media) =>
                  <DropdownInput>{
                    text: media.mediaName,
                    htmlText: `<span class="dropdown-item-tag ">${media.shelf}</span> ${media.mediaName}`,
                    value: media.mediaIdentifier,
                  }
              ),
              totalCount: searchResult.totalCount,
            },
          (error) => {
            this.equipmentMedias$ = null;
            this.noticeService.show(
              "Equipment_ElectronicManuals_Search_Error",
              NoticeType.Error
            );
          }
        )
      );
  }

  protected onEquipmentChanged(equipmentId: number) {
    this.equipmentMedias$ = null;
    this.reportErrorForm.controls["mediaIdentifier"].setValue("");

    if (equipmentId != null) {
      this.reportErrorForm.get("mediaIdentifier").enable();
    }
  }

  protected filterEquipment(args: DropdownArgs): void {
    this.getMediasForEquipment(
      this.reportErrorForm.value.equipmentId,
      args.index,
      args.take
    );
  }
}

