/* eslint-disable no-console */
import { UrlHelperService } from './../url-helper/url-helper.service';
import { BroadcastService } from 'root/services/broadcast-service/broadcast.service';
// import { routes } from './../../modules/settings/settings.routes';
import { Injectable } from "@angular/core";
import { MySandvikNavigationItem } from "./navigation-types";
import { PermissionService } from '../permission/permission.service';
import {
  NAVITEMS_MYFLEET,
  NAVITEMS_SHOP,
  NAVITEMS_WARRANTYPORTAL,
  NAVITEMS_BULLETINS,
  NAVITEMS_REPORTS,
  NAVITEMS_ADMINISTRATION,
  NAVITEMS_SERVICE,
  NAVITEMS_MYACCOUNT,
  NAVITEMS_HELP
} from './navitems/';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree, NavigationExtras } from '@angular/router';
import { GlobalConfigService } from '../global-config/global-config.service';
import { LogService, MibpLogger } from '../logservice';
import { ApiService } from '../mibp-api-services';
import { LoaderService } from '../loader/loader.service';
import { MibpBreadcrumbsV2Component } from './../../components/navigation/breadcrumbs/breadcrumbs-v2.component';
import { MibpCrumb } from 'root/components/navigation/breadcrumbs/breadcrumbs-v2.types';
import { NAVITEMS_OPERATIONS } from './navitems/navitems-operations';
import { NavItemBadge } from '.';
import { NAVITEMS_SMARTMATE } from './navitems/navitems-smartmate';
import { OperationSiteService } from '../operation-sites/operation-sites.service';
import { SignalR_UserType } from 'root/services';
import { ChangeLogsApiController, PromotionsApiController } from 'root/mibp-openapi-gen/controllers';
import { delay, firstValueFrom, of } from 'rxjs';
import { LocalizationService } from 'root/services/index';
import { NAVITEMS_MYSANDVIK_DIGITAL_SERVICES } from './navitems/navitems-mysandvik-digital-services';
import { PagedPromotionRefinementOptions, UserStatus, UserType } from 'root/mibp-openapi-gen/models';
import { NAVITEMS_NEWS } from './navitems/navitems-news';
import { NAVITEMS_MYROCKTOOLS} from './navitems/navitems-myrocktools';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { NAVITEMS_SUPPORTCASES } from './navitems/navitems-supportcases';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private currentFilteredNavigation?: MySandvikNavigationItem[];
  private currentNavigationIdMapping?: { [uniqueId: string]: MySandvikNavigationItem};
  private autoInc = 0;
  log: MibpLogger;
  private badges: { [ key: string]: NavItemBadge } = {};
  private currentNavigationIdentifier: string;
  private breadcrumbComponents: MibpBreadcrumbsV2Component[] = [];
  private hasAlreadyTestedForUnseenChangelog = false;
  private isSubscribingToChanges = false;
  refinementOptions: PagedPromotionRefinementOptions = {
    skip: 0,
    take: 1000,
    sortBy: "priority",
    query: null,
    types: ["Internal"],
    placements: null,
    companyCode: null,
    erpCustomerId: null,
  };

  constructor(private broadcast: BroadcastService,
              private urlHelper: UrlHelperService,
              logger: LogService,
              private permissionService: PermissionService,
              private router: Router,
              private globalConfigService: GlobalConfigService,
              private api: ApiService,
              private loader: LoaderService,
              private operationSitesService: OperationSiteService,
              private changelogApi: ChangeLogsApiController,
              private promotionsApiController:PromotionsApiController,
              private localizationService: LocalizationService
  ) {
    this.log = logger.withPrefix("navigation service");
  }

  public subscribeToNavigationChanges(): void {
    if (this.isSubscribingToChanges) {
      return;
    }
    this.isSubscribingToChanges = true;
    this.broadcast.deliverySequence.subscribe( () => this.refreshNavigation() );
    this.broadcast.mibpSession.subscribe( () => this.refreshNavigation() );
    this.broadcast.language.subscribe( () => this.refreshNavigation() );
  }

  public getBreadcrumbComponents(): MibpBreadcrumbsV2Component[] {
    return this.breadcrumbComponents;
  }

  public registerBeadcrumbComponent(component: MibpBreadcrumbsV2Component): void {
    this.breadcrumbComponents.push(component);
  }

  public unregisterBeadcrumbComponent(component: MibpBreadcrumbsV2Component): void {
    const ix = this.breadcrumbComponents.findIndex(i => i === component);
    if (ix != -1) {
      this.breadcrumbComponents.splice(ix, 1);
    }
  }

  public updateBreadcrumbs(updateFunction: (crumbs: MibpCrumb[]) => MibpCrumb[]): void {
    const clonedCrumbs = this.broadcast.snapshot.breadcrumbs.map(crumb => Object.assign({}, crumb));
    const newCrumbs = updateFunction ? updateFunction(clonedCrumbs) : undefined;
    if (typeof newCrumbs !== 'undefined') {
      this.breadcrumbComponents.forEach(breadcrumbComponent => {
        breadcrumbComponent.update(newCrumbs);
      });
    }
  }

  private refreshNavigation(): void {

    // Only when the "identifier" has changed will we refresh navigation
    // This should occur when user acts as, language changes or user permission changes
    const identifier = [
      this.broadcast.snapshot.mibpSession?.activeDeliverySequence?.deliverySequenceId || '-',
      this.broadcast.snapshot.mibpSession?.activeDeliverySequence?.businessRelationPermission || '-',
      this.broadcast.snapshot.language || '-',
      this.broadcast.snapshot.mibpSession?.user?.status || '-',
      this.broadcast.snapshot.mibpSession?.user?.isFeatureAdministrator || '-',
      this.broadcast.snapshot.mibpSession?.user?.isPunchoutUser || '-',
      this.broadcast.snapshot.mibpSession?.user?.isPunchoutMultiProfileUser || '-',
      this.broadcast.snapshot.mibpSession?.user?.isSupportTeamMember || '-',
      this.broadcast.snapshot.mibpSession?.user?.enabledFeatures?.join(',') || '-'
    ].join('_');

    if (this.currentNavigationIdentifier != identifier) {
      this.currentNavigationIdentifier = identifier;
      // this.permissionService.setup(this.broadcast.snapshot.userEvent, true, this.broadcast.snapshot.globalConfig.mySandvikFeaturesEnabled);

      this.getNavigation(true)
        .then(items => {
          this.broadcast.setNavigationItems(items);
        }).catch(e => {
          this.log.error(`Error loading navigation`, e);
        });

    }

   

  }

  /***
   * Called when navigation is prepared before its shown to the user
   */
  private async beforeNavigationCompleted(): Promise<void> {

    // Setup custom rules for more advanced logic of certain navigation item
    if (this.broadcast.snapshot.mibpSession?.user?.status == UserStatus.Active) {
      this.testLocationItemVisibility();
      this.testSmartMate();
      this.testMyRockTools();
      this.testPartsManualsVisibility();
      this.testChangelogVisibility();
      this.testUnseenChangelogRelease();
      this.testFeatureAdmin();
      this.testPromotionVisibility();
    }
  }

  /**
   * Hide feature admin menu if features are disabled
   */
  private testFeatureAdmin(): void {
    const item = this.getById('#features', true);

    if (item!=null) {
      if (!this.globalConfigService.mySandvikFeaturesEnabled) {
        item.calculated.hidden = true;
      }
    }
  }

  private testPartsManualsVisibility(): void {
    const item = this.getById('shop/parts-manuals', true);

    if (item!=null) {
      if (!this.globalConfigService.enablePartsManuals) {
        item.calculated.hidden = true;
      } else {
        if (this.globalConfigService.enablePartsManualsUserIds.length > 0) {
          if (!this.globalConfigService.enablePartsManualsUserIds.find(enabledForUserId => enabledForUserId.toLowerCase() == this.broadcast.snapshot.mibpSession?.user?.userId?.toString().toLowerCase() )) {
            item.calculated.hidden = true;
          }
        }
      }
    }
  }

  private async testPromotionVisibility(): Promise<void> {




    const item = this.getById('shop/promotions', true);

    if (item!=null) {
      item.calculated.hidden = true;
      if (this.globalConfigService.disablePromotions || !this.permissionService.test(allPermissionPolicies.promotions)) {
        item.calculated.hidden = true;
      } else {
        await firstValueFrom(this.promotionsApiController.search({body:this.refinementOptions})).then(result => {
          if (result.items.length >0 ) {
            item.calculated.hidden = false;
          }
          else
          {
            item.calculated.hidden = true;
          }
        }).catch(e => {
          item.calculated.hidden = false;
          // Don't break if any exception occurs here
          this.log.warn(`Error checking for Promotion.`, e);
        });
      }
    }
  }

  private testChangelogVisibility(): void {
    const item = this.getById('help/changelog', true);

    if (item && !this.globalConfigService.enableChangeLog) {
      item.calculated.hidden = true;
    }
  }

  private testMyRockTools() {

    const item = this.getById('myrocktools', true);
    if (item && !this.globalConfigService.enableMyRockToolsMenu) {
      item.calculated.hidden = true;
    }

  }



  private testSmartMate(): void {

    //Always show smartmate for internal users, ignoring features and permissions
    //External users will rely on having Smartmate feature/permission for them to see it.
    const item = this.getById('smartmate', true);
    if (item && this.broadcast.snapshot?.mibpSession?.user?.type == UserType.Internal) {
      item.calculated.hidden = false;
    }

  }

  private async testUnseenChangelogRelease(): Promise<void> {

    if (this.hasAlreadyTestedForUnseenChangelog || !this.globalConfigService.enableChangeLog || !this.broadcast.snapshot.mibpSession?.user) {
      return;
    }

    this.hasAlreadyTestedForUnseenChangelog = true;

    firstValueFrom(this.changelogApi.hasUnseenChangelog()).then(newReleaseAvailable => {
      if (newReleaseAvailable) {
        this.setBadge('#changelog', {
          value: this.localizationService.get('Changelog_MenuBadge_New'),
          color: 'blue'
        });
        this.setBadge('#help', {
          value: this.localizationService.get('Changelog_MenuBadge_New'),
          color: 'blue'
        });
      }
    }).catch(e => {
      // Don't break if any exception occurs here
      this.log.warn(`Error checking for last seen changelog release`, e);
    });
  }

  private async testLocationItemVisibility(): Promise<void> {

    const item = this.getById('myfleet/location', true);
    if (item?.calculated?.hidden !== true) {

      // Always hide menu if location is disabled in global config
      if (this.globalConfigService.disableMyFleetLocation) {
        item.calculated.hidden = true;
        return;
      }

      // Show location item if there are any operation sites in organization
      try {
        item.calculated.hidden = true; // Hide until we know if it should be hidden/shown
        const hasOperationSites = await this.operationSitesService.hasAnySites();
        item.calculated.hidden = !hasOperationSites;
      } catch {
        item.calculated.hidden = true;
        this.log.error(`Error when checking if organization has operation sites`);
      }
    }

  }

  public async getNavigation(force?: boolean): Promise<MySandvikNavigationItem[]> {

    if (!force && this.currentFilteredNavigation?.length > 0  ) {
      return this.currentFilteredNavigation;
    }

    const allNavigationItems = [
      ...this.getUnfilteredNavigation()
    ];

    this.currentNavigationIdMapping = {};
    const filteredItems = this.calculateNavigationItemDetails(allNavigationItems);

    this.currentFilteredNavigation = filteredItems;
    await this.beforeNavigationCompleted();

    return filteredItems;
  }


  /**
   * Update the "count/badge" for an item in the navigation
   * @param navItemUniqueId The unique id of the navitem. Can be set manually or it will be the path without language (for example shop/cart)
   * @param value The text inside the badge. Set to falsy to remove badge
   */
  public setBadge(navItemUniqueId: string, badge: NavItemBadge): void {

    if (this.currentNavigationIdMapping && this.currentNavigationIdMapping[navItemUniqueId]) {
      this.currentNavigationIdMapping[navItemUniqueId].calculated.badge = badge || undefined;
      this.badges[navItemUniqueId] = this.currentNavigationIdMapping[navItemUniqueId].calculated.badge;
    }
  }

  /**
   * Get a Navigation Item based on an activated route.
   * This will check the value of { data: { navItemId: 'xx' } } for the route
   */
  public getByActivatedRoute(route: ActivatedRoute): MySandvikNavigationItem {
    const id = this.getNavItemIdFromActivatedRouteSnapshot(route.snapshot);
    return id ? this.getById(id) : undefined;
  }

  public getById(uniqueId: string, asReference = false): MySandvikNavigationItem {

    uniqueId = uniqueId || '';
    const idsToTry: string[] = [uniqueId];


    if (!uniqueId.startsWith('/')) {
      idsToTry.push(`/${uniqueId}`);
    }
    if (!uniqueId.startsWith('#')) {
      idsToTry.push(`#${uniqueId}`);
    }
    if (uniqueId.endsWith('/')) {
      uniqueId = uniqueId.substring(0, uniqueId.length -1);
    }

    if (!this.currentNavigationIdMapping) {
      this.getNavigation();
    }

    for (let i = 0; i < idsToTry.length; i++) {
      if (this.currentNavigationIdMapping[idsToTry[i]]) {
        if (asReference) {
          return this.currentNavigationIdMapping[idsToTry[i]];
        } else {
          return JSON.parse(JSON.stringify(this.currentNavigationIdMapping[idsToTry[i]]));
        }
      }
    }

    return undefined;
  }

  public getNavItemIdFromUrl(url: string): string {
    if (url) {
      const withoutLanguage = url.replace(/^\/[a-z]{2}\//i, '/');
      return withoutLanguage;
    }
    return null;
  }

  public getNavItemIdFromActivatedRouteSnapshot(snapshot: ActivatedRouteSnapshot): string {
    let current = snapshot;
    let navItemId: string;

    do {
      if (current.routeConfig) {
        if (current.routeConfig.data?.navItemId) {
          navItemId = current.routeConfig.data?.navItemId;
        }
      }
      current = current.firstChild;
    } while (current !== null);

    return navItemId;

  }

  private calculateNavigationItemDetails(items: MySandvikNavigationItem[], parentPath?: string[], depth = 0, parentId?: string): MySandvikNavigationItem[] {

    parentPath = parentPath || [];

    // Hide items that the user does not have permission to see

    //console.warn("THINKING", items.length, items, parentPath, depth, parentId);

    // const filteredItems = items.filter( navItem => !navItem.permissionPolicy || (navItem.permissionPolicy && this.permissionService.test(navItem.permissionPolicy) ));

    items.forEach(navItem => {
      let pathIdentifier: string;

      navItem.calculated = {
        parentId: parentId
      };

      if (navItem.hidden == true) {
        navItem.calculated.hidden = true;
      }

      if (navItem.permissionPolicy && !this.permissionService.test(navItem.permissionPolicy) ) {
        navItem.calculated.hidden = true;
      }

      if (navItem.route?.addLanguage) {
        parentPath.push(this.broadcast.snapshot.language);
      }

      // Make sure the calculated full route is available
      if (navItem.route) {
        navItem.calculated.fullPath = parentPath.concat(navItem.route.path.map(p => p.replace(/^\/|\/$/g, '')));

        if (navItem.calculated.fullPath.length > 0 && navItem.calculated.fullPath[0] !== null) {

          // Make sure first path starts with / so the URL is absolute
          if (!navItem.calculated.fullPath[0].startsWith('/')) {
            navItem.calculated.fullPath[0] = `/${navItem.calculated.fullPath[0]}`;
          }

        }

        // Remove any empty paths
        navItem.calculated.fullPath = navItem.calculated.fullPath.filter(value => value);


        pathIdentifier = navItem.calculated.fullPath.join('/').replace(/^\/[a-z]{2}/, '');
      }

      if (navItem.uniqueId) {
        if (!navItem.uniqueId.startsWith('#')) {
          navItem.uniqueId = `#${navItem.uniqueId}`;
        }
      } else {
        this.autoInc++;
        navItem.uniqueId = `navitem${this.autoInc}`;
      }

      if (this.badges[navItem.uniqueId]) {
        navItem.calculated.badge = this.badges[navItem.uniqueId];
      }

      this.currentNavigationIdMapping[navItem.uniqueId] = navItem;
      if (typeof pathIdentifier !== 'undefined') {
        this.currentNavigationIdMapping[pathIdentifier] = navItem;
      }

      if (depth === 0) {
        if (navItem.children?.find(child => child.route?.path?.length === 0 || (child.route?.path?.length == 1 && child.route?.path[0] === ''))) {
          navItem.calculated.hasChildWithEmptyPath = true;
        }
      }

      if (navItem.children) {
        navItem.children = this.calculateNavigationItemDetails(navItem.children, navItem.calculated?.fullPath || parentPath, depth + 1, navItem.uniqueId);
      }

      // If not hidden by permission/feature
      // And we should show if it has visible children
      if (!navItem.calculated.hidden && navItem.showIfAnyChildren && depth === 0 && navItem.children?.length > 0) {
        if (navItem.children?.filter(f => f.calculated?.hidden !== true).length > 0) {
          navItem.calculated.hidden = false;
        } else {
          navItem.calculated.hidden = true;
        }
      }

      //Only for ability to hide cross sell and upsell in admin
      if (navItem.resourceKey === 'Cross-sell' && this.globalConfigService.disableCrossSell) {
        navItem.calculated.hidden = true;
      }

      if (navItem.resourceKey === 'Up_sell' && this.globalConfigService.disableUpSell) {
        navItem.calculated.hidden = true;
      }

      if (navItem.resourceKey === 'Global_FleetDocuments' && this.globalConfigService.disableFleetDocuments)
      {
        navItem.calculated.hidden = true;
      }
      if (navItem.resourceKey === 'Global_Machine_Documents' && this.globalConfigService.disableFleetDocuments)
      {
        navItem.calculated.hidden = true;
      }

      if (navItem.resourceKey === 'Bulletin_Edit_Title' || navItem.resourceKey === 'Bulletin_EmailStatus_Title' || navItem.resourceKey === 'Bulletin_Details_title'||navItem.resourceKey === 'Bulletin_Unread_Title'||navItem.resourceKey === 'Bulletin_Archived_Title'||navItem.resourceKey === 'Bulletin_AddNew') {
        navItem.calculated.hidden = true;
      }

      if (navItem.resourceKey === 'NewsArticle_Details') {
        navItem.calculated.hidden = true;
      }

      if (navItem.resourceKey === 'Global_Promotions' && this.globalConfigService.disablePromotions) {
        navItem.calculated.hidden = true;
      }
      if (navItem.resourceKey === 'Location_Title' && this.globalConfigService.disableMyFleetLocation) {
        navItem.calculated.hidden = true;
      }
      if(navItem.resourceKey === 'Warranty-Applications' && !this.globalConfigService.enableWarrantyApplication){
        navItem.calculated.hidden = true;
      }
    });

    return items;

  }

  /**
   * Get the entire unfiltered navigation regardless of user permissions etc
   */
  private getUnfilteredNavigation(): MySandvikNavigationItem[] {

    NAVITEMS_MYROCKTOOLS.externalUrl = this.globalConfigService.myRockToolsMenuUrl;
    NAVITEMS_SMARTMATE.externalUrl =  this.globalConfigService.smartMateMenuUrl;

    const items: MySandvikNavigationItem[] = [
      {
        uniqueId: 'home',
        resourceKey: 'Global_Home',
        automatedTestId: 'sidebar-home',
        sandvikIconName: 'house',
        route: {
          addLanguage: true,
          path: ['/'],
          exactMatch: true
        }
      },

      NAVITEMS_SHOP,
      NAVITEMS_MYFLEET,
      NAVITEMS_BULLETINS,
      NAVITEMS_MYSANDVIK_DIGITAL_SERVICES,
      NAVITEMS_REPORTS,
      NAVITEMS_WARRANTYPORTAL,
      NAVITEMS_SMARTMATE,
      NAVITEMS_MYROCKTOOLS,
      NAVITEMS_ADMINISTRATION,
      NAVITEMS_SERVICE,
      NAVITEMS_OPERATIONS,
      NAVITEMS_MYACCOUNT,
      NAVITEMS_HELP,
      NAVITEMS_NEWS,
      NAVITEMS_SUPPORTCASES
    ];

    // Make sure we don't have references to the original objects
    return JSON.parse(JSON.stringify(items)) as MySandvikNavigationItem[];
  }

  public navigateTo(uniqueId: string, extras?: NavigationExtras): void {
    const navItem = this.getById(uniqueId);
    if (navItem) {
      this.router.navigate(navItem.calculated.fullPath, extras);
    }
  }

  public getFullRoute(item: MySandvikNavigationItem): string[] {
    if (item) {

      if (item.externalUrl) {
        return [];
      }

      let p: string[] = [];

      if (item.calculated.parentId) {
        const parent = this.getById(item.calculated.parentId);
        if (parent) {
          p = this.getFullRoute(parent);
        }
      } else {
        p.push(this.broadcast.snapshot.language);
      }

      item?.route?.path?.forEach(i => {
        p.push(i);
      });

      return p;

    }
  }

  // Can be removed when we start using new UX and all routes link to new ux and not old
  public tryRedirectOldRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {


    const url = state.url.toString().replace(/^\/[a-z]{2}\//, '/');
    let newUrl: string[];
    let newQueryParams: { [key: string]: string};

    // Easy mappings - if it starts with key, redirect to new navitem + rest of the url
    const redirectIfStartsWith = {
      '/cart/active': '/shop/cart',
      '/cart/search': '/shop/products',
      '/cart/saved': '/shop/cart/saved',
      '/cart/orders': '/shop/orders',
      '/cart/quotations': '/shop/quotations',
      '/cart/punchedout': '/shop/punchedout',
      '/cart/template': '/shop/templates',
      '/cart/reports': '/reports',
      '/electronic-manuals': '/myfleet/electronic-manuals',

      '/cart/bulletin/details': '/bulletins/details',
      '/cart/bulletin/edit': '/bulletins/edit',
      '/cart/bulletin/unread': '/bulletins/unread',
      '/cart/bulletin/archived': '/bulletins/archived',
      '/cart/bulletin/add': '/bulletins/add',
      //'/cart/bulletin': '/bulletins',

      '/cart/myfleet/list': '/myfleet/summary',
      '/cart/myfleet/details': '/myfleet/summary/details',

      '/cart/myfleet': '/myfleet'
    };


    const keys = Object.keys(redirectIfStartsWith);
    for (let i = 0; i <  keys.length; i++) {
      const startsWith = keys[i];
      if (url?.startsWith(startsWith)) {
        const navitem = this.getById(redirectIfStartsWith[startsWith]);
        if (navitem) {

          const restOfUrl = url.substring(startsWith.length).split('/').filter(f => f);
          const querystring = restOfUrl.length > 0 ? (restOfUrl[restOfUrl.length - 1].includes('?') ? restOfUrl[restOfUrl.length - 1].substring(restOfUrl[restOfUrl.length - 1].lastIndexOf('?')) : null) : null;
          if (querystring) {
            restOfUrl[restOfUrl.length - 1] = restOfUrl[restOfUrl.length - 1].replace(querystring, '');
            newQueryParams = this.urlHelper.parseUrl(querystring).query;
          }
          newUrl = navitem?.calculated?.fullPath.concat(restOfUrl);
          break;
        }
      }
    }

    newUrl= newUrl.filter(path=> path);
    if (newUrl) {
      return Promise.resolve( this.router.createUrlTree(newUrl, {queryParams: newQueryParams}) );
    } else {

      if (url.includes('emailstatus')) {

        const navitem = this.getById('bulletin-emailstatus');
        if (navitem) {

          const path = navitem.calculated.fullPath.slice(0, navitem.calculated.fullPath.length - 1);
          path.push(route.params.id);
          path.push(navitem.calculated.fullPath[navitem.calculated.fullPath.length - 1]);
          return Promise.resolve( this.router.createUrlTree(path) );
        }
      }


      // eslint-disable-next-line no-console
      console.error(`Could not find new url for ${url}`);
    }


    return Promise.resolve(true);
  }
}
