
@import '../../..//scss/variables';


mibp-timezone-datetimepicker {
  &.ng-invalid {
    .tzdatetimepicker {
      &__date, &__time {
        border: 1px solid #f36a22;
        background-color: #fffbf9;
      }
    }

  }
}

.tzdatetimepicker {
  display: flex;
  > *:not(:first-child) {
    margin-left: 10px;
  }
}

.tzdatetimepicker__date {
  flex-basis: min-content;
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #000;
  outline: none;
  border: 1px solid $my-color-n3;
  border-radius: 0;
  font-size: 1rem;
  -webkit-appearance: none;


  .mat-mdc-form-field-infix {
    display: flex;
  }

  .mat-mdc-form-field-subscript-wrapper,
  .mdc-line-ripple {
    display: none;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .picker-icon {
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }


  .mat-form-field {
    display: block;

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-subscript-wrapper {
      display: none;
    }

  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: transparent;
    visibility: hidden;
  }

  .mat-form-field-flex {
    display: flex;
    flex-direction: column;
    align-items: initial;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0;
  }

  .mat-form-field-infix {
    display: flex;
    align-items: center;
    flex-basis: 42px;
    border-width: 0;
    width: 100%;
    input {
      background: transparent;
      border: none;
      flex-grow: 1;
      padding: 10px 10px;
      margin: 0;
      min-height: auto;

      &.ng-invalid.ng-dirty,
      &.ng-invalid.ng-touched {
        border-width: 0;
      }

    }


  }
}



:host.ng-invalid {
    .mibp-new-datetimepicker {
      .mat-form-field-infix {
        input {
          border-color: red;
        }
      }
    }
}
