@import '../../../scss/variables.scss';

#{ $mysandvik }popup-container {
  position: fixed;
  left: 0;
  z-index: var(--z-index--popover);
  &--solid {
    background-color: #fff;
    border: 2px solid #EEEEEE;
  }
  &--overlay {
    background-color: rgba(0,0,0,0.7);
  }
  &--fitwidth {
    width: 100%;
  }
  &--absolute {
    position: absolute;
    max-height: none;
    display: inline-block;
    overflow-y:initial;
  }
  &--has-arrow {
    overflow-y:initial;
    position: absolute;
    overflow-y: initial;
  }
  &--scrollable {
    overflow-y: auto;
  }
  &--above-header {
    z-index: calc(var(--z-index-header) + 1)
  }
}

#{ $mysandvik }popup-content {
  &--has-arrow {
    padding: 4px;
    min-width: 80px;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
  }

  @mixin arrow-left-base {
    min-width: 100px;
    min-height: 60px;
    background: #fff;
    position: absolute;
    overflow-y: initial;
    border: 2px solid $my-color-border-dark;
    
    &:after, &:before {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-right-color: #fff;
      border-width: 15px;
      margin-top: -15px;
    }

    &:before {
      border-color: rgba(240, 173, 78, 0);
      border-right-color:  $my-color-border-dark;
      border-width: 18px;
      margin-top: -18px;
    }
  }

  @mixin arrow-bottom-base {
    position: relative;
    background: #fff;
    border: 2px solid  $my-color-border-dark;
    
    &:after, &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #fff;
      border-width: 15px;
      margin-left: -15px;
    }

    &:before {
      border-color: rgba($my-color-border-dark, 0);
      border-top-color:  $my-color-border-dark;
      border-width: 18px;
      margin-left: -18px;
    }
  }


  @mixin arrow-right-base {
    min-width: 100px;
    min-height: 60px;
    background: #fff;
    border: 2px solid $my-color-border-dark;
    position: absolute;
    overflow-y: initial;

    &:after,
    &:before {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-left-color: #fff;
      border-width: 15px;
      margin-top: -15px;
    }

    &:before {
      border-color: rgba($my-color-border-dark, 0);
      border-left-color: $my-color-border-dark;
      border-width: 18px;
      margin-top: -18px;
    }
  }

  @mixin arrow-top-base {
    padding: 4px;
    background: #ffffff;
    border: 2px solid $my-color-border-dark;
    overflow-y: initial;

    &:after,
    &:before {
      bottom: calc(100% - 2px);
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ffffff;
      border-width: 15px;
      margin-left: -15px;
    }

    &:before {
      border-color: rgba($my-color-border-dark, 0);
      border-bottom-color: $my-color-border-dark;
      border-width: 18px;
      margin-left: -18px;
    }
  }

  &--arrow-top-center {
    @include arrow-top-base();
  }

  &--arrow-top-left {
    @include arrow-top-base();
    &:before,
    &:after {
      left: 15%;
    }
  }

  &--arrow-right-center {
    @include arrow-right-base();
  }

  &--arrow-left-center {
    @include arrow-left-base();
  }

  &--arrow-bottom-center {
    @include arrow-bottom-base();
  }
}
