  <h3 *ngIf="crossSellProducts.length>0" class="upsell-header my-header"><mibp-resource-string key="Cross-sell_component_header"></mibp-resource-string></h3>
  <mibp-loader *ngIf="isLoading"></mibp-loader>

  <div  class="columns" [ngClass]="{'hidden': this.isLoading}"  >
    <div class="column" *ngFor="let crossSellproduct of crossSellProducts">
      <section class="upsell-card" *ngIf="crossSellProducts.length>0">
        <div class="image-container">
          <a   (click)="onClickProductImage(crossSellproduct.code)">
          <mibp-image *ngIf="crossSellproduct.partsPictureImageUrl" [urlOfImage]="crossSellproduct.partsPictureImageUrl"></mibp-image>
          <img *ngIf="!crossSellproduct.partsPictureImageUrl"  src="assets/images/NewDefaultImage.png" alt="image missing">
          </a>
        </div>
        <a   (click)="onClickProductImage(crossSellproduct.code)">
        <h4 class="item-title" >{{crossSellproduct.name}}</h4></a>
        <div class="upsell-card-info">
          <ul class="item-list">
            <li class="item-name"> <span class="item-label"><mibp-resource-string key="Global_ProductNumber"></mibp-resource-string>: </span> {{crossSellproduct.code}}</li>
            <li *ngIf="canSeeAvailability" class="item-availability">
              <span class="item-label"><mibp-resource-string key="Global_ProductAvailability"></mibp-resource-string>: </span>
              <div class="item-availability__wrapper">
                <mibp-availability *ngIf="!useexpectedDeliveryDateAndAvailability"
                  [productAvailability] = "crossSellproduct.productAvailability"
                  [quantity]="crossSellproduct.quantity"></mibp-availability>
                <mibp-expected-delivery-date-availability *ngIf="useexpectedDeliveryDateAndAvailability"
                  [quantity]="crossSellproduct.quantity"
                  [productExpectedDate] = "crossSellproduct.productExpectedDate"
                  [productCode]="crossSellproduct.code"
                ></mibp-expected-delivery-date-availability>
              </div>
            </li>
            <li class="item-price">
              <mibp-product-price
              [productPrice]="crossSellproduct.productPrice"
              [productCode]="crossSellproduct.code"
              >
            </mibp-product-price>
            </li>
          </ul>
          <div class="upgrade-btn">
            <button type="button" data-gtm="cross-sell-add-to-cart-btn" class="my-button my-button--fill" (click)="onAddToActiveCart(crossSellproduct.code)"><mibp-resource-string key="Carts_Active_AddToCartButton"></mibp-resource-string></button>
          </div>
        </div>

      </section>
    </div>
  </div>
