/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DocumotoPropagateMediaAccessJobVm } from '../../models/documoto-propagate-media-access-job-vm';

export interface ListDocumotoPropagateMediaAccessJobs$Plain$Params {
  skip?: number;
  take?: number;
  onlyRunningJob?: boolean;
  query?: string;
}

export function listDocumotoPropagateMediaAccessJobs$Plain(http: HttpClient, rootUrl: string, params?: ListDocumotoPropagateMediaAccessJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumotoPropagateMediaAccessJobVm>>> {
  const rb = new RequestBuilder(rootUrl, listDocumotoPropagateMediaAccessJobs$Plain.PATH, 'get');
  if (params) {
    rb.query('skip', params.skip, {});
    rb.query('take', params.take, {});
    rb.query('onlyRunningJob', params.onlyRunningJob, {});
    rb.query('query', params.query, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<DocumotoPropagateMediaAccessJobVm>>;
    })
  );
}

listDocumotoPropagateMediaAccessJobs$Plain.PATH = '/operations/documoto/propagate-media-access-jobs/running';
