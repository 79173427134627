<mibp-dialog [buttons]="dialogButtons" (buttonClick)="dialogClick($event)" hideCloseButton='false' size='narrow' [id]="'add-to-cart-dialog'">
  <div class="dialog-container">
    <div class="dialog-header">
      <h1 class="heading-type-2">
        <mibp-resource-string key="CartsDialog_Product_Added"></mibp-resource-string>
      </h1>
      <button id="btnCloseNotice" (click)="closeDialog()"></button>
    </div>
    <h1 class="article-heading-3 error"><strong>{{errorText}}</strong></h1>
    <h1 class="article-heading-3 error">{{itemCodesText}}</h1>

    <div class="product-info">
      <div class="product" *ngFor="let product of firstTwoProdcuts; let i = index">
        <div class="columns">
          <div class="column image-container">
            <a (click)="onClickProductImage(product.code)">
            <mibp-image *ngIf="product.imageUrl"  [urlOfImage]="product.imageUrl"></mibp-image>
            <!-- <img *ngIf="product.partsPictureImageUrl"  [src]="product.partsPictureImageUrl" [alt]="product.name"> -->
            <img *ngIf="!product.imageUrl"  src="assets/images/NewDefaultImage.png" alt="image missing">
            </a>
          </div>
          <div class = column>
            <a  (click)="onClickProductImage(product.code)">
              <h1 class="article-heading-h2">
              <strong>
                {{product.translatedName || product.name}}
              </strong>
            </h1>
          </a>
            <h1>
              <span class="item-label"><mibp-resource-string key="Global_ProductNumber"></mibp-resource-string>: </span> {{product.code}}
            </h1>
            <mibp-product-price
            [productPrice]="product.productPrice"
            [productCode]="product.code">
            </mibp-product-price>
          </div>
        </div>
      </div>
      <p class="additionalProductsText" *ngIf="additionalProducts > 0">
        <mibp-resource-string key="CartsDialog_More_Products" [macros]="{count: additionalProducts}">
        </mibp-resource-string>
      </p>

      <div *ngIf="hasDuplicates" data-aut="dialog-cart-has-duplicate-products" class="duplicate" >
        <span translate="no" class="material-icon material-icon--red material-icon--fill">error</span>
        <p>
          <mibp-resource-string key="CartsDialog_Duplicate_Products"></mibp-resource-string>
        </p>
      </div>
    </div>

    <div class="upsell-cross-sell-container" *ngIf="hasUpsell">
      <mibp-upsell (closeDialogBox)="onCloseCrossSellUpsellDialog($event)" [setUpsellCrossSellProductId]="upsellProductId" (productHasBeenUpgradedEvent)="productHasBeenUpgradedByUpsell()"></mibp-upsell>
    </div>

    <div class="upsell-cross-sell-container" *ngIf="!hasUpsell && hasCrossSell">
      <mibp-cross-sell (closeDialogBox)="onCloseCrossSellUpsellDialog($event)" [setCrossSellProductIds]="crossSellProductIds" (crossSellProductAddedToCartEvent)="onCrossSellProductAddedToCart()"></mibp-cross-sell>
    </div>

  </div>
</mibp-dialog>
