@import '../../../../scss/variables.scss';
@import '../../../../scss/mixins/all';




.mobile-acting-as-text {
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 16px;

  &--open {
    color: $my-color-g;
  }


  @media screen and (max-width: 600px) {
    max-width: 200px;
  }

  .long  {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 800px) {
    max-width: 400px;
  }

  .short  {
    @media screen and (min-width: 599px) {
      display: none;
    }
  }

}


#{ $mysandvik }mobile-menu-actas-popup {
  background-color: rgba(0,0,0,0.5);
  width: 100%;
}

#{ $mysandvik }mobile-menu-statusbar {
  height: 42px;
  background-color: #fff;
  justify-content: space-between;
  border-bottom: 1px solid $my-color-border;
  display: flex;
  align-items: center;

  a {
    color: #000;
    text-decoration: none;
    border: none;
  }

  &__cart {
    color: #000;
    text-decoration: none;
    border: none;
    padding: 0 16px;

    .my-badge {
      position: absolute;
      margin-left: -6px;
      margin-top: -5px;
    }

  }

  &__notifications {

    padding: 0 16px;
    .my-badge {
      position: absolute;
      margin-left: -6px;
      margin-top: -5px;
    }
  }
  &__systemmessage {
    flex-grow: 1;
    padding: 0 16px;
    .my-badge {
      position: absolute;
      margin-left: -6px;
      margin-top: -2px;
    }
  }

  &__actingas {
    padding-right: 10px;
    display: flex;
  }

  &__login {
    padding: 10px;
  }

}

#{ $mysandvik}mobile-menu-search-popup {
  background-color: #fff;
  padding: 16px;
}

#mobile-toolbar-search-input {
  width: 100%;
}

#{ $mysandvik }mobile-menu-container {

  background-color: #fff;



  &__settings {
    padding: 10px 20px;
    display: flex;
    padding-bottom: 10px;
    border-bottom: #eee;
    margin-bottom: 10px;
  }

  &__user {
    padding: 0 16px;
    display: flex;
    padding-bottom: 10px;
    border-bottom: #eee;
    align-items: center;

    &--email {
      flex-grow: 1;
      margin-left: 16px;
      a {
        color: #000;
        text-decoration: none;
        border: none;
      }
    }
  }

  &__languagepicker {
    border: 1px solid #eee;
    padding:16px;
    margin: 0 16px;
    > div {
      display: flex;
      justify-content: flex-end
    }
    a {
      text-decoration: none;
      border: none;
    }
  }

}
.toggel-sidebar{
  width: auto;
  cursor: pointer;
  padding: 20px 25px 20px 25px;
  &:hover {
    background-color: #f5f5f5;
  }
}
#{ $mysandvik }mobile-menu-toolbar {
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;

  &__logo {
    padding: 12px 10px; flex-grow: 1;
    text-decoration: none;
    border: none;
  }
  &__search {
    padding: 12px 20px;
  }
  &__hamburger {
    padding: 12px 20px;
  }
}

#{ $mysandvik }mobile-menu-language-toggle {

  flex-grow: 1;
  text-decoration: none;
  border:none;
  color: #000;

  span {
    margin-left: 12px;
  }

  &--open a,
  &--open:focus,
  &--open:active {
    color: $my-color-vo;
  }

}

#{ $mysandvik }mobile-menu-signout {

  text-decoration: none;
  border:none;
  color: #000

}



.floatRight{
  float: right;
}

#{ $mysandvik }site-header {
  background-color: #fff;

  display: block;
  height: 75px;
  border-bottom: 1px solid #d3d3d3;
  // top: 0;
  // left:0;
  // position: fixed;
  // width: 100%;

  &__icon{
    .material-icon {
      font-size: 38px;
    }
    display: flex;

  }

  &__language-icon {
    filter: drop-shadow(grey 4px 8px 8px);
    width: 50%;
    height: 45%;
    border-radius: 0px;
    background-repeat: no-repeat;
    background-size: 44px 44px;
    background-position: 50% 50%;
    &--zh {
      background-position: 10% 36%;;
    }
    &--es {
      background-position: 30% 50%;
    }

    &--fi,
    &--sv,
    &--pt {
      background-position: 35% 50%;
    }
  }

  &__cart {
    @include media-xs-and-smaller {
      margin-right: 150px;
    }
  }


  ul {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    justify-content: space-between;
  }

  li {


    display: inline-block;
    display: flex;
    min-width: 94px;

    &:first-child {
    border-left: 1px solid #d3d3d3 ;
      flex-grow: 1;
      > span {
        flex-grow: 0;
      }
    }


    > *  {
      height: 100%;
      > span,
      > a {
        cursor: pointer;
        border: none;
        text-decoration: none;
        display: flex;
        flex-grow: 1;
        min-width: 92px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: #f5f5f5;
        }
      }


      &:hover {
        #{ $mysandvik }header__iconbadge {
          background-color: $my-color-vo;
        }
      }
    }

  }


  &__iconbadge {
    position: absolute;
    margin-top: -32px;
    margin-left: 32px;
  }


  li {
    border-right: 1px solid #d3d3d3;
    height: 100%;
    align-items: center;
    align-content: center;
    display: flex;
  }

}

#{ $mysandvik }languages,
#{ $mysandvik }userinfo {
  padding-top: 16px;
  padding-bottom: 16px;
  a {
    color: #000;
    padding: 2px 30px 2px 30px;
    display: flex;
    cursor: pointer;
    border: none;
    text-decoration: none;
    align-items: center;
    span:first-child {
      margin-right: 5px;
    }
    span:last-child {
      flex-grow: 1;
    }
    &:hover {
      color: $my-color-vo;
    }
  }

}

.toggel-sidebar {
  @media screen and (max-width: 1100px) {
  padding: 20px 5px 20px 5px;
}
}

.bold--icon{
  font-weight: bold
}
