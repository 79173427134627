/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { downloadAttachment } from '../fn/support-case/download-attachment';
import { DownloadAttachment$Params } from '../fn/support-case/download-attachment';
import { downloadAttachment$Plain } from '../fn/support-case/download-attachment-plain';
import { DownloadAttachment$Plain$Params } from '../fn/support-case/download-attachment-plain';
import { getAttachments } from '../fn/support-case/get-attachments';
import { GetAttachments$Params } from '../fn/support-case/get-attachments';
import { getAttachments$Plain } from '../fn/support-case/get-attachments-plain';
import { GetAttachments$Plain$Params } from '../fn/support-case/get-attachments-plain';
import { getSupportCaseDetails } from '../fn/support-case/get-support-case-details';
import { GetSupportCaseDetails$Params } from '../fn/support-case/get-support-case-details';
import { getSupportCaseDetails$Plain } from '../fn/support-case/get-support-case-details-plain';
import { GetSupportCaseDetails$Plain$Params } from '../fn/support-case/get-support-case-details-plain';
import { getSupportCasesListGrid } from '../fn/support-case/get-support-cases-list-grid';
import { GetSupportCasesListGrid$Params } from '../fn/support-case/get-support-cases-list-grid';
import { getSupportCasesListGrid$Plain } from '../fn/support-case/get-support-cases-list-grid-plain';
import { GetSupportCasesListGrid$Plain$Params } from '../fn/support-case/get-support-cases-list-grid-plain';
import { gridSearchForOrganization } from '../fn/support-case/grid-search-for-organization';
import { GridSearchForOrganization$Params } from '../fn/support-case/grid-search-for-organization';
import { gridSearchForOrganization$Plain } from '../fn/support-case/grid-search-for-organization-plain';
import { GridSearchForOrganization$Plain$Params } from '../fn/support-case/grid-search-for-organization-plain';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { rescope } from '../fn/support-case/rescope';
import { Rescope$Params } from '../fn/support-case/rescope';
import { rescope$Plain } from '../fn/support-case/rescope-plain';
import { Rescope$Plain$Params } from '../fn/support-case/rescope-plain';
import { submitContactUsCase } from '../fn/support-case/submit-contact-us-case';
import { SubmitContactUsCase$Params } from '../fn/support-case/submit-contact-us-case';
import { submitContactUsCase$Plain } from '../fn/support-case/submit-contact-us-case-plain';
import { SubmitContactUsCase$Plain$Params } from '../fn/support-case/submit-contact-us-case-plain';
import { submitPartsManualSupportCase } from '../fn/support-case/submit-parts-manual-support-case';
import { SubmitPartsManualSupportCase$Params } from '../fn/support-case/submit-parts-manual-support-case';
import { submitPartsManualSupportCase$Plain } from '../fn/support-case/submit-parts-manual-support-case-plain';
import { SubmitPartsManualSupportCase$Plain$Params } from '../fn/support-case/submit-parts-manual-support-case-plain';
import { SupportCaseDetailVm } from '../models/support-case-detail-vm';
import { SupportCaseExtApiGetAttachmentItemDto } from '../models/support-case-ext-api-get-attachment-item-dto';
import { uploadAttachments } from '../fn/support-case/upload-attachments';
import { UploadAttachments$Params } from '../fn/support-case/upload-attachments';

@Injectable({ providedIn: 'root' })
export class SupportCaseApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `supportCaseControllerRescope()` */
  static readonly SupportCaseControllerRescopePath = '/supportcase/rescope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Plain$Response(params?: Rescope$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescope$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Plain(params?: Rescope$Plain$Params, context?: HttpContext): Observable<number> {
    return this.rescope$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rescope()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope$Response(params?: Rescope$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return rescope(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rescope$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rescope(params?: Rescope$Params, context?: HttpContext): Observable<number> {
    return this.rescope$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `supportCaseControllerSubmitPartsManualSupportCasesupportCase()` */
  static readonly SupportCaseControllerSubmitPartsManualSupportCasesupportCasePath = '/supportcase/savepartsmanualinfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitPartsManualSupportCase$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitPartsManualSupportCase$Plain$Response(params?: SubmitPartsManualSupportCase$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return submitPartsManualSupportCase$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitPartsManualSupportCase$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitPartsManualSupportCase$Plain(params?: SubmitPartsManualSupportCase$Plain$Params, context?: HttpContext): Observable<string> {
    return this.submitPartsManualSupportCase$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitPartsManualSupportCase()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitPartsManualSupportCase$Response(params?: SubmitPartsManualSupportCase$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return submitPartsManualSupportCase(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitPartsManualSupportCase$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitPartsManualSupportCase(params?: SubmitPartsManualSupportCase$Params, context?: HttpContext): Observable<string> {
    return this.submitPartsManualSupportCase$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `supportCaseControllerSubmitContactUsCasesupportCase()` */
  static readonly SupportCaseControllerSubmitContactUsCasesupportCasePath = '/supportcase/submitcontactuscase';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitContactUsCase$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitContactUsCase$Plain$Response(params?: SubmitContactUsCase$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return submitContactUsCase$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitContactUsCase$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitContactUsCase$Plain(params?: SubmitContactUsCase$Plain$Params, context?: HttpContext): Observable<string> {
    return this.submitContactUsCase$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitContactUsCase()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitContactUsCase$Response(params?: SubmitContactUsCase$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return submitContactUsCase(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitContactUsCase$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitContactUsCase(params?: SubmitContactUsCase$Params, context?: HttpContext): Observable<string> {
    return this.submitContactUsCase$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `supportCaseControllerUploadAttachmentsattachmentsCaseId()` */
  static readonly SupportCaseControllerUploadAttachmentsattachmentsCaseIdPath = '/supportcase/addattachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAttachments()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadAttachments$Response(params?: UploadAttachments$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return uploadAttachments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAttachments$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  uploadAttachments(params?: UploadAttachments$Params, context?: HttpContext): Observable<void> {
    return this.uploadAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `supportCaseControllerGetAttachmentssalesForceId()` */
  static readonly SupportCaseControllerGetAttachmentssalesForceIdPath = '/supportcase/getattachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachments$Plain$Response(params?: GetAttachments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SupportCaseExtApiGetAttachmentItemDto>>> {
    return getAttachments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachments$Plain(params?: GetAttachments$Plain$Params, context?: HttpContext): Observable<Array<SupportCaseExtApiGetAttachmentItemDto>> {
    return this.getAttachments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SupportCaseExtApiGetAttachmentItemDto>>): Array<SupportCaseExtApiGetAttachmentItemDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAttachments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachments$Response(params?: GetAttachments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SupportCaseExtApiGetAttachmentItemDto>>> {
    return getAttachments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAttachments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAttachments(params?: GetAttachments$Params, context?: HttpContext): Observable<Array<SupportCaseExtApiGetAttachmentItemDto>> {
    return this.getAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SupportCaseExtApiGetAttachmentItemDto>>): Array<SupportCaseExtApiGetAttachmentItemDto> => r.body)
    );
  }

  /** Path part for operation `supportCaseControllerDownloadAttachmenttitleAttachmentId()` */
  static readonly SupportCaseControllerDownloadAttachmenttitleAttachmentIdPath = '/supportcase/downloadattachment/{title}/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Plain$Response(params: DownloadAttachment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return downloadAttachment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Plain(params: DownloadAttachment$Plain$Params, context?: HttpContext): Observable<string> {
    return this.downloadAttachment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment$Response(params: DownloadAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return downloadAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadAttachment(params: DownloadAttachment$Params, context?: HttpContext): Observable<string> {
    return this.downloadAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `supportCaseControllerGetSupportCasesListGridoptions()` */
  static readonly SupportCaseControllerGetSupportCasesListGridoptionsPath = '/supportcase/search/supportcases';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupportCasesListGrid$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSupportCasesListGrid$Plain$Response(params?: GetSupportCasesListGrid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return getSupportCasesListGrid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSupportCasesListGrid$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSupportCasesListGrid$Plain(params?: GetSupportCasesListGrid$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.getSupportCasesListGrid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupportCasesListGrid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSupportCasesListGrid$Response(params?: GetSupportCasesListGrid$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return getSupportCasesListGrid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSupportCasesListGrid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSupportCasesListGrid(params?: GetSupportCasesListGrid$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.getSupportCasesListGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `supportCaseControllerGetSupportCaseDetailsid()` */
  static readonly SupportCaseControllerGetSupportCaseDetailsidPath = '/supportcase/get/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupportCaseDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportCaseDetails$Plain$Response(params: GetSupportCaseDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SupportCaseDetailVm>> {
    return getSupportCaseDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSupportCaseDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportCaseDetails$Plain(params: GetSupportCaseDetails$Plain$Params, context?: HttpContext): Observable<SupportCaseDetailVm> {
    return this.getSupportCaseDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SupportCaseDetailVm>): SupportCaseDetailVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupportCaseDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportCaseDetails$Response(params: GetSupportCaseDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<SupportCaseDetailVm>> {
    return getSupportCaseDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSupportCaseDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportCaseDetails(params: GetSupportCaseDetails$Params, context?: HttpContext): Observable<SupportCaseDetailVm> {
    return this.getSupportCaseDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<SupportCaseDetailVm>): SupportCaseDetailVm => r.body)
    );
  }

  /** Path part for operation `supportCaseControllerGridSearchForOrganizationoptionsOrganizationId()` */
  static readonly SupportCaseControllerGridSearchForOrganizationoptionsOrganizationIdPath = '/supportcase/searchgrid/organization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearchForOrganization$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Plain$Response(params?: GridSearchForOrganization$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearchForOrganization$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearchForOrganization$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Plain(params?: GridSearchForOrganization$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearchForOrganization$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gridSearchForOrganization()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization$Response(params?: GridSearchForOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return gridSearchForOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `gridSearchForOrganization$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  gridSearchForOrganization(params?: GridSearchForOrganization$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.gridSearchForOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

}
