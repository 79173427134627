/* tslint:disable */
/* eslint-disable */
export enum PriceAndAvailabilitySource {
  ActiveCart = 1,
  ApprovedCart = 2,
  SavedCart = 3,
  SentCart = 4,
  UnApprovedCart = 5,
  PrivateTemplate = 6,
  SharedTemplate = 7,
  WarrantyPortal = 8,
  KitConfigurator = 10,
  ProductSearch = 11,
  PunchedOut = 12,
  SendRequestForQuote = 13,
  SendOrder = 14,
  ViewQuotation = 15,
  Promotion = 16,
  CartExcelExport = 17,
  ProductDetail = 18,
  CartQuickView = 19,
  ComponentFinder = 20
}
