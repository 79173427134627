.fullscreen-iframe-wrapper {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  flex-direction: column;
  background-color: #fff;


  .fullscreen-above {
    display: none;
  }

  .fullscreen-content {
    flex: 1;
  }

  .loader-container {
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: wait;
    padding-top: 70px;
  }

  .loading-text {
    font-size: 48px;
    margin-top: 24px;
    font: 200 28px/6px 'Sandvik Sans', Helvetica, Arial, sans-serif;
    color: #101010;
  }

  iframe {
    height: 100%;
  }

}

.ux-version--v1 .fullscreen-iframe-wrapper,
.ux-version--v2 .fullscreen-iframe-wrapper {
  z-index: 10;
}


