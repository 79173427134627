import { BroadcastService, GlobalConfigService } from 'src/app/services/';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { AvailabilityType, Message, ProductExpectedDate, SessionDeliverySequenceViewModel } from 'root/mibp-openapi-gen/models';
import { ContactUsService, FrontendContextService, LocalizationService, LogService, MibpLogger } from 'root/services';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { ContactUsTopic } from '../contact-dialog/contact-us-form/contact-us-form.types';
import { MibpPopoverComponent } from '../popover-component/popover.component';
import { UserAction } from '../context-menu';
import {StockroomService} from './../../services/stockroom/stockroom.service';
import { SupportCaseItemEnquiryType } from '../contact-dialog/new-contact-us-form/contact-us-form-item-enquiry/contact-us-item-enquiry-form.interface';

@Component({
  selector: 'mibp-expected-delivery-date-availability',
  templateUrl: './expected-delivery-date-availability.component.html',
  styleUrls: ['./expected-delivery-date-availability.component.scss']
})
export class ExpectedDeliveryDateAvailabilityComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MibpPopoverComponent) availabilityPopover: MibpPopoverComponent;
  log: MibpLogger;
  isLoading = true;
  availabilityProduct: ProductExpectedDate;
  isAuroraCompany = false;
  userCanSeeAvailability: boolean;
  availabilityClass  = "available-not";
  deliverySequence: SessionDeliverySequenceViewModel;
  availabilityDescriptionForContactUs: string;
  stopUsingResources: () => void;
  availabilityDescription = "";
  availabilityType =  AvailabilityType;
  stockroomEnabled: boolean;
  contactUsMessageExpectedDate:string;
  auroraMessages: Message[];

  @Input() productExpectedDate: ProductExpectedDate;
  @Input() productCode?: string;
  @Input() quantity?: number;
  @Input() isRockToolItem: boolean;
  @Input() isQuotation = false;

  constructor(
    logger: LogService,
    private frontEndContext: FrontendContextService,
    private sessionService: MibpSessionService,
    private contactUsService: ContactUsService,
    private localizationService: LocalizationService,
    private globalConfigService : GlobalConfigService,
    private broadcastService : BroadcastService,
    protected stockroomService : StockroomService) {
    this.log = logger.withPrefix('mibp-expected-delivery-date-availability');
    this.deliverySequence = this.sessionService.activeDeliverySequence;
  }
  ngOnInit(): void {
    this.stockroomEnabled = this.stockroomService.isStockroomEnable;
    this.sessionService.activeDeliverySequence$.subscribe(ds => this.onChangeInDeliverySequence(ds));

    const currentCompanyCode =  this.sessionService.activeDeliverySequence &&
    this.sessionService.activeDeliverySequence.companyCode.toLowerCase();

    this.stopUsingResources = this.localizationService.using(['availabilityDescription_'+currentCompanyCode, 'availabilityDescription', 'Carts_AvailabilityForProductMessage', 'Carts_RequestDeliveryDateMessage'], resourceStrings => {
      this.availabilityDescription = resourceStrings[0].endsWith('_'+currentCompanyCode) ? resourceStrings[1] : resourceStrings[0];
      this.availabilityDescriptionForContactUs = resourceStrings[2];
      this.contactUsMessageExpectedDate = resourceStrings[3];
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.productExpectedDate && changes.productExpectedDate.currentValue){
      const expectedDate = changes.productExpectedDate.currentValue;
      if(expectedDate.expectedDateError) {
        const availability = expectedDate;
        if(availability != null){
          this.availabilityProduct = availability;
          this.isLoading = false;
        }
      } else {

        const availability = expectedDate;
        if(availability != null){
          this.availabilityProduct = availability;
        }
        this.isLoading = false;
      }
      this.auroraMessages = expectedDate.availabilities[0]?.messages ?? [];
    }
    else{
      this.isLoading = true;
    }
    this.refreshUI();
  }

  public onSupportMenuAction(action: UserAction): void {
    if (action.name == 'quote-tool-data') {
      alert(JSON.stringify(this.productExpectedDate, null, 2));
    }
  }

  clickedRequestExpectedDateLink(): void {
    if(this.globalConfigService.enableNewContactUs){
      this.newContactUs(this.availabilityProduct.productCode, this.availabilityProduct.quantity, SupportCaseItemEnquiryType.ExpectedDeliveryDate);
    }
    else{
      const message = `${this.contactUsMessageExpectedDate} ${this.availabilityProduct.productCode}`;
      this.contactUs(ContactUsTopic.Enquiry, message);
    }
  }

  newContactUs(productCode: string, quantity?: number, enquiryType?: SupportCaseItemEnquiryType): void {
    let error = '';
    let messages = [];
    if(this.globalConfigService.enableSendExpectedDeliveryDateAuroraMessageToSupportCase){
      error = this.productExpectedDate.errorCode;
      if(error){
        error += " : " + this.productExpectedDate.errorDescription;
      }
      messages = this.auroraMessages?.map(i => {return "\n" + i.exception +" : "+ i.messageText;});
    }
    this.contactUsService.openItemEnquiryContactUs(productCode, quantity, enquiryType, error, messages);
  }

  refreshUI(): void {
    this.isAuroraCompany = this.sessionService.isAuroraCompany();
    const hasActiveDeliverySequence = this.sessionService.activeDeliverySequence;
    this.userCanSeeAvailability = !!hasActiveDeliverySequence;
  }

  onChangeInDeliverySequence(ds : SessionDeliverySequenceViewModel) : void {
    if(ds !== this.deliverySequence){
      this.deliverySequence = ds;
    }
  }

  contactUs(topic: ContactUsTopic, message: string): void {
    let error = '';
    let messages = [];
    if(this.globalConfigService.enableSendExpectedDeliveryDateAuroraMessageToSupportCase){
      let error = this.productExpectedDate.errorCode;
      if(error){
        error += " : " + this.productExpectedDate.errorDescription;
      }
      messages = this.auroraMessages?.map(i => {return "\n" + i.exception +" : "+ i.messageText; });
    }

    this.contactUsService.openContactUs(topic, message, undefined, undefined, undefined, error, messages);
  }

  ngOnDestroy(): void {
    if (this.stopUsingResources) {
      this.stopUsingResources();
    }
  }
}
