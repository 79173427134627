
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/naming-convention */
/************************************************************
 * 
 * THIS FILE IS AUTOMATICALLY GENERATED. DO NOT MODIFY.
 * 
 **************************************************************/

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Guid } from 'guid-typescript';
import { TimeSpan } from 'root/types/timeSpan';
/***
 * Generated from Entities\ApiResponse.cs
 */
export interface SignalR_ApiResponse {
  success: boolean;
  error: string;
  errorCode: string;
  errors?: SignalR_ErrorInfo[];
}
/***
 * Generated from Entities\ApiResponse.cs
 */
export interface SignalR_ErrorInfo {
  code: string;
  description: string;
  publicMessage: string;
  exceptionType: string;
  resourceKey: string;
  data?: { [key: string]: any  };
}
/***
 * Generated from Entities\Sample.cs
 */
export interface SignalR_Sample {
  id: number;
  description: string;
  date: Date;
  processed: boolean;
}
/***
 * Generated from Entities\ApiResponse.cs
 */
export interface SignalR_ApiResponseGeneric<T> extends SignalR_ApiResponse {
  data: T;
}
// NOTE: This Interface was generated from a C# Tuple (https://docs.microsoft.com/en-us/dotnet/csharp/language-reference/builtin-types/value-tuples)
export interface SignalR_SampleFindResult {
  totalCount: number;
  items?: SignalR_Sample[];
}
/***
 * Generated from Configuration\ApplicationConfigurationItems.cs
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignalR_ApplicationConfigurationItems {
}
/***
 * Generated from Configuration\ApplicationSettings.cs
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignalR_ApplicationSettings {
}
/***
 * Generated from Configuration\CustomerLiveChatConfig.cs
 */
export interface SignalR_CustomerLiveChatConfig {
  scriptUrl: string;
  mySiteUrl: string;
  mySalesforceUrl: string;
  organizationId: string;
  deploymentId: string;
  buttonId: string;
  liveAgentUrl: string;
  liveAgentContentUrl: string;
  liveAgentName: string;
  liveAgentDeveloperName: string;
}
/***
 * Generated from Configuration\GlobalConfigValue.cs
 */
export interface SignalR_GlobalConfigValue {
  configurationItem: SignalR_ConfigurationItem;
}
/***
 * Generated from Entities\Configuration.cs
 */
export interface SignalR_ConfigurationItem {
  listItemSeparator: string;
  appConfigKeyName: string;
  keyVaultSecretName: string;
  defaultValue: string;
}
/***
 * Generated from Configuration\GlobalConfigValue.cs
 */
export interface SignalR_GlobalConfigValueGeneric<T> extends SignalR_GlobalConfigValue {
  value: T;
}
/***
 * Generated from Configuration\GlobalConfig.cs
 */
export interface SignalR_GlobalConfig {
  warrantyPortal: SignalR_GlobalWarrantyPortalConfig;
  customerLiveChat: SignalR_CustomerLiveChatConfig;
  signalR: SignalR_SignalRGlobalConfig;
  newProductSearch: SignalR_NewProductsGlobalConfig;
  enablePartsManuals: boolean;
  enablePartsManualsUserIds: string[];
  enableChangeLog: boolean;
  useNewExceptionDialog: boolean;
  loadInternalUserPhoto: boolean;
  disableQuickAddOnCartPage: boolean;
  disableResourceServerSideCache: boolean;
  disableApiDocs: boolean;
  maxCartSize: number;
  disableShowProductError: boolean;
  activeEquipmentStatuses: string[];
  inActiveEquipmentStatuses: string[];
  onOrderEquipmentStatuses: string[];
  decommisionedEquipmentStatuses: string[];
  useB2CDocumoto: boolean;
  auroraBatchSize: number;
  useNewAddToCartDialog: boolean;
  disableCompressionOfLogFilterParameter: boolean;
  idleShowPopupAfterSeconds: number;
  idlePopupLogoutAfterSeconds: number;
  hideCustomerCredit: boolean;
  showCustomerCreditForCompanies: string[];
  checkboxFacetRedIndicator: string[];
  disableOnboardingTour: boolean;
  disableFleetDocuments: boolean;
  enableStockroom: boolean;
  enableStockroomCompanies: string[];
  disableUpSell: boolean;
  disableUpSellOnCartItems: boolean;
  disableCrossSell: boolean;
  disableCrossSellOnCartItems: boolean;
  maxNumberOfTargetProducts: number;
  maxNumberOfRelatedProducts: number;
  disableBulletinsOld: boolean;
  disableBulletinsNew: boolean;
  disableToggleSideBar: boolean;
  disablePromotions: boolean;
  disableMachineUpdateRequest: boolean;
  disableFeatureTourAfterDays: number;
  lastDeploymentDate: Date;
  systemMessageDefaultImageId: string;
  dynatraceUserEmails: string[];
  disableNewFeatureTour: boolean;
  mySandvikFeaturesEnabled: boolean;
  enableFeatureTestNotice: boolean;
  activateDynamicDashboard: boolean;
  disableDashboardDragDrop: boolean;
  disableSupportQueueTile: boolean;
  enableNewHelpSection: boolean;
  enableNewTermsAndConditions: boolean;
  enableDeployEvents: boolean;
  enableWarrantyApplication: boolean;
  disableMyFleetDetailsGeoLocation: boolean;
  disableMyFleetLocation: boolean;
  disableEngineHoursManualUpdate: boolean;
  enableProductDetails: boolean;
  disableAuroraOrderSyncProcess: boolean;
  syncAuroraOrderSkipInterval_Minutes: number;
  auroraSyncEnabledCompanies: string[];
  enableSalesforceLiveChat: boolean;
  mailFileSizeLimitInBytes: number;
  disableKitSuggestions: boolean;
  disableTopEngineHoursTile: boolean;
  disableEngineHoursStatusTile: boolean;
  hideMySandvikBulletinAddEdit: boolean;
  machineFiltersEnabled: boolean;
  richTextEditorImageUploadMaxSize: number;
  richTextEditorAllowedImageMimeTypes: string;
  supportedImageFileExtensions: string;
  mibpPaymentAdyenEnvironment: string;
  mibpPaymentAdyenCardHasHolderName: boolean;
  mibpPaymentAdyenCardHolderNameRequired: boolean;
  mibpPaymentAdyenCardBillingAddressRequired: boolean;
  enableClaimSoftDeleteMySandvik: boolean;
  googleMapsScriptKey: string;
  enableClaimsPrimeCausalPartValidation: boolean;
  environment: string;
  enableMyRockToolsMenu: boolean;
  myRockToolsMenuUrl: string;
  smartMateMenuUrl: string;
  enablePostalCodeValidationForCountries: string[];
  enableEditAddressMapsLookup: boolean;
  systemMessagePollIntervalMinutes: number;
  maxNumberOfPromotionProducts: number;
  maxNumberOfPromotionResponsibilities: number;
  enablePopoutHelpPages: boolean;
  checkoutEnableContactDetails: boolean;
  sidebarHoverEnabled: boolean;
  enableNewContactUs: boolean;
  availabilityCacheDuration: TimeSpan;
  enablePartsManualDescription: boolean;
  activeAddKitItemsToCartTypes: string[];
  enableSupersededDataSyncStatus: boolean;
  supersededDataSyncStatusVisbleForUsers: string[];
  useProductBlobUrlsForProducts: boolean;
  documotoWidgetScriptUrl: string;
  enablePartsManualDownload: boolean;
  useOrderStatusFromExpectedDeliveryDate: boolean;
  productNotificationMaxNumberOfProductCodes: number;
  enablePartsManualDetailsSearch: boolean;
  disableMySandvikPdfViewerForElectronicManuals: boolean;
  enableEquipmentScopingBasedOnCustomers: boolean;
  enableOperationSiteInResponsibilityPicker: boolean;
  enableGlobalItemMDGIntegration: boolean;
  enablePartsManualIllustrationHotpointLinks: boolean;
  enableSendPriceAuroraMessageToSupportCase: boolean;
  enableSendAvailabilityAuroraMessageToSupportCase: boolean;
  enableSendExpectedDeliveryDateAuroraMessageToSupportCase: boolean;
}
// Generated class since GenerateClassForApiServiceGenerator attribute was found
export class SignalR_GlobalConfigClass implements SignalR_GlobalConfig {
  warrantyPortal: SignalR_GlobalWarrantyPortalConfig;
  customerLiveChat: SignalR_CustomerLiveChatConfig;
  signalR: SignalR_SignalRGlobalConfig;
  newProductSearch: SignalR_NewProductsGlobalConfig;
  enablePartsManuals: boolean;
  enablePartsManualsUserIds: string[];
  enableChangeLog: boolean;
  useNewExceptionDialog: boolean;
  loadInternalUserPhoto: boolean;
  disableQuickAddOnCartPage: boolean;
  disableResourceServerSideCache: boolean;
  disableApiDocs: boolean;
  maxCartSize: number;
  disableShowProductError: boolean;
  activeEquipmentStatuses: string[];
  inActiveEquipmentStatuses: string[];
  onOrderEquipmentStatuses: string[];
  decommisionedEquipmentStatuses: string[];
  useB2CDocumoto: boolean;
  auroraBatchSize: number;
  useNewAddToCartDialog: boolean;
  disableCompressionOfLogFilterParameter: boolean;
  idleShowPopupAfterSeconds: number;
  idlePopupLogoutAfterSeconds: number;
  hideCustomerCredit: boolean;
  showCustomerCreditForCompanies: string[];
  checkboxFacetRedIndicator: string[];
  disableOnboardingTour: boolean;
  disableFleetDocuments: boolean;
  enableStockroom: boolean;
  enableStockroomCompanies: string[];
  disableUpSell: boolean;
  disableUpSellOnCartItems: boolean;
  disableCrossSell: boolean;
  disableCrossSellOnCartItems: boolean;
  maxNumberOfTargetProducts: number;
  maxNumberOfRelatedProducts: number;
  disableBulletinsOld: boolean;
  disableBulletinsNew: boolean;
  disableToggleSideBar: boolean;
  disablePromotions: boolean;
  disableMachineUpdateRequest: boolean;
  disableFeatureTourAfterDays: number;
  lastDeploymentDate: Date;
  systemMessageDefaultImageId: string;
  dynatraceUserEmails: string[];
  disableNewFeatureTour: boolean;
  mySandvikFeaturesEnabled: boolean;
  enableFeatureTestNotice: boolean;
  activateDynamicDashboard: boolean;
  disableDashboardDragDrop: boolean;
  disableSupportQueueTile: boolean;
  enableNewHelpSection: boolean;
  enableNewTermsAndConditions: boolean;
  enableDeployEvents: boolean;
  enableWarrantyApplication: boolean;
  disableMyFleetDetailsGeoLocation: boolean;
  disableMyFleetLocation: boolean;
  disableEngineHoursManualUpdate: boolean;
  enableProductDetails: boolean;
  disableAuroraOrderSyncProcess: boolean;
  syncAuroraOrderSkipInterval_Minutes: number;
  auroraSyncEnabledCompanies: string[];
  enableSalesforceLiveChat: boolean;
  mailFileSizeLimitInBytes: number;
  disableKitSuggestions: boolean;
  disableTopEngineHoursTile: boolean;
  disableEngineHoursStatusTile: boolean;
  hideMySandvikBulletinAddEdit: boolean;
  machineFiltersEnabled: boolean;
  richTextEditorImageUploadMaxSize: number;
  richTextEditorAllowedImageMimeTypes: string;
  supportedImageFileExtensions: string;
  mibpPaymentAdyenEnvironment: string;
  mibpPaymentAdyenCardHasHolderName: boolean;
  mibpPaymentAdyenCardHolderNameRequired: boolean;
  mibpPaymentAdyenCardBillingAddressRequired: boolean;
  enableClaimSoftDeleteMySandvik: boolean;
  googleMapsScriptKey: string;
  enableClaimsPrimeCausalPartValidation: boolean;
  environment: string;
  enableMyRockToolsMenu: boolean;
  myRockToolsMenuUrl: string;
  smartMateMenuUrl: string;
  enablePostalCodeValidationForCountries: string[];
  enableEditAddressMapsLookup: boolean;
  systemMessagePollIntervalMinutes: number;
  maxNumberOfPromotionProducts: number;
  maxNumberOfPromotionResponsibilities: number;
  enablePopoutHelpPages: boolean;
  checkoutEnableContactDetails: boolean;
  sidebarHoverEnabled: boolean;
  enableNewContactUs: boolean;
  availabilityCacheDuration: TimeSpan;
  enablePartsManualDescription: boolean;
  activeAddKitItemsToCartTypes: string[];
  enableSupersededDataSyncStatus: boolean;
  supersededDataSyncStatusVisbleForUsers: string[];
  useProductBlobUrlsForProducts: boolean;
  documotoWidgetScriptUrl: string;
  enablePartsManualDownload: boolean;
  useOrderStatusFromExpectedDeliveryDate: boolean;
  productNotificationMaxNumberOfProductCodes: number;
  enablePartsManualDetailsSearch: boolean;
  disableMySandvikPdfViewerForElectronicManuals: boolean;
  enableEquipmentScopingBasedOnCustomers: boolean;
  enableOperationSiteInResponsibilityPicker: boolean;
  enableGlobalItemMDGIntegration: boolean;
  enablePartsManualIllustrationHotpointLinks: boolean;
  enableSendPriceAuroraMessageToSupportCase: boolean;
  enableSendAvailabilityAuroraMessageToSupportCase: boolean;
  enableSendExpectedDeliveryDateAuroraMessageToSupportCase: boolean;
}
/***
 * Generated from Configuration\GlobalWarrantyPortalConfig.cs
 */
export interface SignalR_GlobalWarrantyPortalConfig {
  maxUploadBytes: number;
  disabled: boolean;
}
/***
 * Generated from Configuration\SignalRGlobalConfig.cs
 */
export interface SignalR_SignalRGlobalConfig {
  hubName: string;
  reconnectAttempts: number;
  reconnectDelayMs: number;
  skipNegotiation: boolean;
}
/***
 * Generated from Configuration\NewProductsGlobalConfig.cs
 */
export interface SignalR_NewProductsGlobalConfig {
  replaceSearchPage: boolean;
  enableEquipmentFilter: boolean;
  enableActiveFilterList: boolean;
}
/***
 * Generated from Configuration\MibpConfiguration.cs
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignalR_MibpConfiguration {
}
/***
 * Generated from Interfaces\Events\IMibpApiEvent.cs
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignalR_MibpApiEvent {
}
/***
 * Generated from Entities\Events\CartExpectedDateUpdate.cs
 */
export interface SignalR_CartExpectedDateUpdate extends SignalR_MibpApiEvent {
  cartId: Guid;
  workComplete: boolean;
  expectedDateMissingCount?: number;
  expectedDateSuccessCount?: number;
  expectedDateInProgressCount?: number;
}
/***
 * Generated from Entities\Events\CartPriceAndAvailabilityUpdate.cs
 */
export interface SignalR_CartPriceAndAvailabilityUpdate extends SignalR_MibpApiEvent {
  cartId: Guid;
  workComplete: boolean;
  priceInProgressCount?: number;
  priceSuccessCount?: number;
  priceMissingCount?: number;
  availabilityMissingCount?: number;
  availabilitySuccessCount?: number;
  availabilityInProgressCount?: number;
}
/***
 * Generated from Entities\Events\CartUpdatedEvent.cs
 */
export interface SignalR_CartUpdatedEvent extends SignalR_MibpApiEvent {
  cartId: Guid;
  count?: number;
  addProductsResponse: SignalR_CartAddProductsResponseViewModel;
  productCodesRemoved?: string[];
  updatedItems?: Guid[];
  isActiveCart?: boolean;
  source: SignalR_AddToCartSource;
}
/***
 * Generated from Entities\ViewModels\CartAddProductsResponseViewModel.cs
 */
export interface SignalR_CartAddProductsResponseViewModel {
  cartId?: Guid;
  productsAdded?: string[];
  productErrors?: SignalR_CartAddProductErrorViewModel[];
  cartHasDuplicates: boolean;
  cartContainsAddedProduct: boolean;
  upsellCrossSellProductId?: number;
  crossSellProductIds?: number[];
  isSavedCart: boolean;
  isActiveCart: boolean;
  count?: number;
}
/***
 * Generated from Entities\ViewModels\CartAddProductErrorViewModel.cs
 */
export interface SignalR_CartAddProductErrorViewModel {
  index: number;
  productCode: string;
  reason: SignalR_CartAddProductErrorReason;
}
/***
 * Generated from Entities\Events\ConnectionRevokedEvent.cs
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignalR_ConnectionRevokedEvent extends SignalR_MibpApiEvent {
}
/***
 * Generated from Entities\Events\DashboardApiEvent.cs
 */
export interface SignalR_DashboardApiEvent extends SignalR_MibpApiEvent {
  items?: SignalR_DashBoardTile[];
}
/***
 * Generated from Entities\DashBoardTile.cs
 */
export interface SignalR_DashBoardTile {
  order: number;
  name: string;
  requiredRolePermissions: SignalR_RoleEcommercePermission;
}
/***
 * Generated from Entities\Events\DeploymentEvent.cs
 */
export interface SignalR_DeploymentEvent extends SignalR_MibpApiEvent {
  eventType: SignalR_DeploymentEventType;
  logoutTime?: Date;
}
/***
 * Generated from Entities\Events\ErrorEvent.cs
 */
export interface SignalR_ErrorEvent extends SignalR_MibpApiEvent {
  message: string;
  code: string;
  stack: string;
}
/***
 * Generated from Entities\Events\ManualDownloadCompletedUserEvent.cs
 */
export interface SignalR_ManualDownloadCompletedUserEvent extends SignalR_MibpApiEvent {
  mediaId: number;
  mediaName: string;
  status: SignalR_DownloadedManualStatus;
  pdfFileSize?: number;
}
/***
 * Generated from Entities\Events\ManualDownloadedPackageStatusEvent.cs
 */
export interface SignalR_ManualDownloadedPackageStatusEvent extends SignalR_MibpApiEvent {
  downloadedManualPackageId: number;
  packageHash: string;
  totalManualCount: number;
  manualCompletedCount: number;
  errorCount: number;
  equipmentId: number;
  status: SignalR_DownloadedManualStatus;
  fileSize?: number;
  downloadErrorCode?: SignalR_DownloadedManualErrorCode;
}
/***
 * Generated from Entities\Events\ManualDownloadEvent.cs
 */
export interface SignalR_ManualDownloadStatusUpdateEvent extends SignalR_MibpApiEvent {
  downloadedManualId: number;
  status: SignalR_DownloadedManualStatus;
  pdfFileSize?: number;
  downloadErrorCode?: SignalR_DownloadedManualErrorCode;
  mediaId?: number;
  fileType: SignalR_DownloadedManualFileType;
}
/***
 * Generated from Entities\Events\ManualDownloadRequestedByUserEvent.cs
 */
export interface SignalR_ManualDownloadRequestedByUserEvent extends SignalR_MibpApiEvent {
  mediaId: number;
  userDownloadedManualId: number;
}
/***
 * Generated from Entities\Events\ManualPackageCompletedUserEvent.cs
 */
export interface SignalR_ManualPackageCompletedUserEvent extends SignalR_MibpApiEvent {
  downloadedPackageId: number;
  customName: string;
  status: SignalR_DownloadedManualStatus;
  fileSize?: number;
  manualsInPackage: number;
  manualsMissingCount: number;
  equipmentId: number;
}
/***
 * Generated from Entities\Events\NavigationApiEvent.cs
 */
export interface SignalR_NavigationApiEvent extends SignalR_MibpApiEvent {
  items?: SignalR_NavigationLink[];
}
/***
 * Generated from Entities\NavigationLink.cs
 */
export interface SignalR_NavigationLink {
  id: number;
  parent: SignalR_NavigationLink;
  children?: SignalR_NavigationLink[];
  nameResource: SignalR_Resource;
  url: string;
  order: number;
  requiredRoleEcommercePermissions: SignalR_RoleEcommercePermission;
  requiredRoleAdminPermissions: SignalR_RoleAdministrationPermission;
  requiredBusinessRelationPermissions: SignalR_BusinessRelationPermission;
  icon: SignalR_NavigationLinkIcon;
  key: string;
}
/***
 * Generated from Interfaces\IOnModelCreating.cs
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignalR_OnModelCreating {
}
/***
 * Generated from Entities\Resource.cs
 */
export interface SignalR_Resource extends SignalR_OnModelCreating {
  id: number;
  key: string;
  description: string;
  defaultValue: string;
  type: SignalR_ResourceType;
  isCacheable: boolean;
  translations?: SignalR_ResourceTranslation[];
  modifiedDate: Date;
  replaces: string;
  exportForTranslation: boolean;
  systemMessageTitles?: SignalR_SystemMessage[];
  systemMessageDescriptions?: SignalR_SystemMessage[];
}
/***
 * Generated from Entities\ResourceTranslation.cs
 */
export interface SignalR_ResourceTranslation {
  id: number;
  resourceId: number;
  languageId: number;
  language: SignalR_Language;
  resource: SignalR_Resource;
  value: string;
  modifiedDate: Date;
}
/***
 * Generated from Entities\Language.cs
 */
export interface SignalR_Language {
  id: number;
  code: string;
  isVisible: boolean;
  isAdminVisible: boolean;
  languageMappings?: SignalR_LanguageMapping[];
  translations?: SignalR_ResourceTranslation[];
}
/***
 * Generated from Entities\LanguageMapping.cs
 */
export interface SignalR_LanguageMapping {
  id: number;
  auroraLanguageCode: string;
  languageId: number;
  language: SignalR_Language;
}
/***
 * Generated from Entities\AuditableEntity.cs
 */
export interface SignalR_AuditableEntity {
  id: number;
  createdDate?: Date;
  modifiedDate?: Date;
}
/***
 * Generated from Entities\SystemMessage.cs
 */
export interface SignalR_SystemMessage extends SignalR_AuditableEntity {
  userType: SignalR_UserType;
  publishFromDate: Date;
  publishToDate: Date;
  disruptionFromDate?: Date;
  disruptionToDate?: Date;
  createdByUserId: Guid;
  createdByUser: SignalR_User;
  modifiedByUserId?: Guid;
  modifiedByUser: SignalR_User;
  systemMessageResponsibilities?: SignalR_SystemMessageResponsibilities[];
  changeTracking: SignalR_ChangeTracking;
  changeTrackingId?: number;
  titleResource: SignalR_Resource;
  titleResourceId?: number;
  descriptionResource: SignalR_Resource;
  descriptionResourceId?: number;
  systemMessageImages?: SignalR_SystemMessageImage[];
}
/***
 * Generated from Entities\User.cs
 */
export interface SignalR_User {
  id: Guid;
  gdwUserId?: number;
  identityObjectId: Guid;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  cell: string;
  type: SignalR_UserType;
  status: SignalR_UserStatus;
  defaultDeliverySequence: SignalR_DeliverySequence;
  role: SignalR_Role;
  roleId?: number;
  acceptedTermsAndConditionsDate?: Date;
  acceptedDataPrivacyStatementDate?: Date;
  lastSigninDate?: Date;
  expireDate?: Date;
  comment: string;
  permissions: SignalR_UserPermission;
  maxOrderValue?: number;
  accessGroup: SignalR_AccessGroup;
  accessGroupId?: Guid;
  content: SignalR_Content;
  country: SignalR_Country;
  createdDate: Date;
  preferredDateFormat?: SignalR_UserDateFormat;
  emailRecipients?: SignalR_EmailRecipient[];
  isFeatureAdministrator: boolean;
  userFeatures?: SignalR_UserFeature[];
  isSupportTeamMember: boolean;
  isPunchoutUser: boolean;
  isPunchoutMultiProfileUser: boolean;
  disabledUserNotifications?: SignalR_DisabledUserNotificationSetting[];
  approvalDate?: Date;
  updatedDate?: Date;
  languageId?: number;
  language: SignalR_Language;
  toBeIndexed: boolean;
  toBeSentToSalesForce: boolean;
  punchoutProfileParentUserId?: Guid;
  punchoutProfileParentUser: SignalR_User;
  punchoutProfileUsers?: SignalR_User[];
  changeTrackingId?: number;
  changeTracking: SignalR_ChangeTracking;
  activeDeliverySequenceId?: number;
  activeDeliverySequence: SignalR_DeliverySequence;
}
/***
 * Generated from Entities\DeliverySequence.cs
 */
export interface SignalR_DeliverySequence extends SignalR_AuditableEntity {
  number: string;
  name: string;
  currencyCode: string;
  postalCode: string;
  state: string;
  city: string;
  country: SignalR_Country;
  masterDataStatus: string;
  masterDataId: string;
  addressLines?: SignalR_AddressLine[];
  content: SignalR_Content;
  equipment?: SignalR_Equipment[];
  creditLimit?: number;
  creditLimitCurrencyCode: string;
  isCreditStopped: boolean;
  active: boolean;
  mdgDeliveryPointId: string;
  businessRelationId?: number;
  businessRelation: SignalR_BusinessRelation;
  deliverySequenceOperationSites?: SignalR_DeliverySequenceOperationSite[];
  toBeIndexed: boolean;
  displayName: string;
}
/***
 * Generated from Entities\DeliverySequence.cs
 */
export interface SignalR_Country {
  id: number;
  code: string;
  name: string;
  company: SignalR_Company;
}
/***
 * Generated from Entities\Company.cs
 */
export interface SignalR_Company extends SignalR_AuditableEntity {
  code: string;
  name: string;
  countries?: SignalR_Country[];
  businessRelations?: SignalR_BusinessRelation[];
  auroraCompany: boolean;
  content: SignalR_Content;
  active: boolean;
  salesEntityCode: string;
  newProducts?: SignalR_NewProduct[];
  salesAreaId?: number;
  salesArea: SignalR_SalesArea;
  toBeIndexed: boolean;
}
/***
 * Generated from Entities\BusinessRelation.cs
 */
export interface SignalR_BusinessRelation extends SignalR_AuditableEntity {
  mdgBusinessRelationId: string;
  creditLimit?: number;
  creditLimitCurrencyCode: string;
  isCreditStopped: boolean;
  active: boolean;
  currencyCode: string;
  erpCustomerID: string;
  company: SignalR_Company;
  companyId: number;
  customer: SignalR_Customer;
  customerId: number;
  deliverySequences?: SignalR_DeliverySequence[];
  content: SignalR_Content;
  orderReferenceType: SignalR_OrderReferenceType;
  permissions: SignalR_BusinessRelationPermission;
  isTrippleZeroHidden: boolean;
  customerCreditLimit: string;
  customerClassification: SignalR_CustomerClassification;
  customerClassificationId?: number;
  toBeIndexed: boolean;
  invoicePaymentNotAllowed: boolean;
  primaryEquipments?: SignalR_Equipment[];
  secondaryEquipments?: SignalR_Equipment[];
}
/***
 * Generated from Entities\Customer.cs
 */
export interface SignalR_Customer extends SignalR_AuditableEntity {
  name: string;
  masterDataStatus: string;
  masterDataId: string;
  status: SignalR_CustomerStatus;
  mdgCustomerId: string;
  businessRelations?: SignalR_BusinessRelation[];
}
/***
 * Generated from Entities\Content.cs
 */
export interface SignalR_Content {
  id: number;
  contentAccessGroups?: SignalR_ContentAccessGroup[];
}
/***
 * Generated from Entities\ContentAccessGroup.cs
 */
export interface SignalR_ContentAccessGroup extends SignalR_OnModelCreating {
  id: number;
  permission: SignalR_ContentPermission;
  contentId: number;
  content: SignalR_Content;
  accessGroupId: Guid;
  accessGroup: SignalR_AccessGroup;
}
/***
 * Generated from Entities\AccessGroup.cs
 */
export interface SignalR_AccessGroup {
  id: Guid;
  name: string;
  description: string;
  isAutogenerated: boolean;
  isPropagated: boolean;
  accessGroupScopes?: SignalR_AccessGroupScope[];
  contentAccessGroups?: SignalR_ContentAccessGroup[];
  content: SignalR_Content;
  type: SignalR_AccessGroupType;
  permissions: SignalR_AccessGroupPermission;
  users?: SignalR_User[];
  createdDate?: Date;
  updatedDate?: Date;
  toBeIndexed: boolean;
  changeTrackingId?: number;
  changeTracking: SignalR_ChangeTracking;
}
/***
 * Generated from Entities\AccessGroupScope.cs
 */
export interface SignalR_AccessGroupScope extends SignalR_OnModelCreating {
  id: number;
  accessGroupId: Guid;
  accessGroup: SignalR_AccessGroup;
  scope: SignalR_Scope;
}
/***
 * Generated from Entities\Scope.cs
 */
export interface SignalR_Scope {
  id: number;
  companyId?: number;
  company: SignalR_Company;
  deliverySequenceId?: number;
  deliverySequence: SignalR_DeliverySequence;
  productAreaId?: number;
  productArea: SignalR_ProductArea;
  productGroupId?: number;
  productGroup: SignalR_ProductGroup;
  productSubGroupId?: number;
  productSubGroup: SignalR_ProductSubGroup;
  productModelId?: number;
  productModel: SignalR_ProductModel;
  equipmentId?: number;
  equipment: SignalR_Equipment;
  businessRelationId?: number;
  businessRelation: SignalR_BusinessRelation;
  operationSiteId?: number;
  operationSite: SignalR_OperationSite;
  accessGroupScopes?: SignalR_AccessGroupScope[];
}
/***
 * Generated from Entities\ProductArea.cs
 */
export interface SignalR_ProductArea {
  id: number;
  code: string;
  description: string;
  groups?: SignalR_ProductGroup[];
}
/***
 * Generated from Entities\ProductGroup.cs
 */
export interface SignalR_ProductGroup {
  id: number;
  code: string;
  description: string;
  productAreaId: number;
  productArea: SignalR_ProductArea;
  subGroups?: SignalR_ProductSubGroup[];
}
/***
 * Generated from Entities\ProductSubGroup.cs
 */
export interface SignalR_ProductSubGroup {
  id: number;
  code: string;
  description: string;
  productGroupId: number;
  productGroup: SignalR_ProductGroup;
  productModels?: SignalR_ProductModel[];
}
/***
 * Generated from Entities\ProductModel.cs
 */
export interface SignalR_ProductModel {
  id: number;
  name: string;
  description: string;
  productSubGroupId: number;
  productSubGroup: SignalR_ProductSubGroup;
  equipment?: SignalR_Equipment[];
  modelImageLastModifiedDate?: Date;
}
/***
 * Generated from Entities\Equipments\Equipment.cs
 */
export interface SignalR_Equipment extends SignalR_OnModelCreating {
  id: number;
  dcuStatus: number;
  name: string;
  installationDate?: Date;
  isPropagated: boolean;
  lastServiceDate?: Date;
  masterDataStatus: string;
  nextServiceDate?: Date;
  rebuildDate?: Date;
  createdDate?: Date;
  updatedDate?: Date;
  warrantyExpiryDate?: Date;
  warrantyMonths: number;
  alias: string;
  knowledgeBox: string;
  toBeIndexed: boolean;
  hasInterimDeliverySequence: boolean;
  mdgEquipmentId: string;
  valid: boolean;
  mdgDeliveryPointId: string;
  mdgPrimaryBusinessRelationId: string;
  mdgSecondaryBusinessRelationId: string;
  mdgPrimaryCustomerId: string;
  mdgSecondaryCustomerId: string;
  primaryBusinessRelationId?: number;
  primaryBusinessRelation: SignalR_BusinessRelation;
  secondaryBusinessRelationId?: number;
  secondaryBusinessRelation: SignalR_BusinessRelation;
  deliverySequenceId?: number;
  deliverySequence: SignalR_DeliverySequence;
  productModelId: number;
  productModel: SignalR_ProductModel;
  content: SignalR_Content;
  equipmentKits?: SignalR_EquipmentKit[];
  equipmentSignals?: SignalR_EquipmentSignal[];
  bulletinEquipments?: SignalR_BulletinEquipment[];
  equipmentMedias?: SignalR_EquipmentMedia[];
  fleetDocuments?: SignalR_FleetDocument[];
  operationSiteId?: number;
  operationSite: SignalR_OperationSite;
  equipmentProducts?: SignalR_EquipmentProduct[];
  equipmentCalenders?: SignalR_EquipmentCalendar[];
  claims?: SignalR_Claim[];
  componentWarrantyRegistrations?: SignalR_ComponentWarrantyRegistration[];
  warrantyApplications?: SignalR_WarrantyApplication[];
}
/***
 * Generated from Entities\Equipments\EquipmentKit.cs
 */
export interface SignalR_EquipmentKit {
  id: number;
  equipmentId: number;
  equipment: SignalR_Equipment;
  component: SignalR_Component;
  hours: number;
  type: SignalR_KitType;
  kitSectionType: SignalR_KitSectionType;
  newProductId: Guid;
  newProduct: SignalR_NewProduct;
}
/***
 * Generated from Entities\Component.cs
 */
export interface SignalR_Component {
  id: number;
  name: string;
  type: SignalR_ComponentType;
}
/***
 * Generated from Entities\ComponentType.cs
 */
export interface SignalR_ComponentType {
  id: number;
  name: string;
}
/***
 * Generated from Entities\AuditableEntityGuid.cs
 */
export interface SignalR_AuditableEntityGuid {
  id: Guid;
  createdDate?: Date;
  modifiedDate?: Date;
}
/***
 * Generated from Entities\NewProducts\NewProduct.cs
 */
export interface SignalR_NewProduct extends SignalR_AuditableEntityGuid, SignalR_OnModelCreating {
  clusterId: number;
  code: string;
  company: SignalR_Company;
  companyId?: number;
  priceCommodityCode: string;
  type: SignalR_ProductType;
  unitOfMeasure: SignalR_UnitsOfMeasure;
  unitOfMeasureId?: number;
  weight: number;
  globalProduct: SignalR_NewProduct;
  globalProductId?: Guid;
  localProducts?: SignalR_NewProduct[];
  productItemClass: SignalR_ProductItemClass;
  productItemClassId?: number;
  majorProductItemGroup: SignalR_ProductItemGroup;
  majorProductItemGroupId?: number;
  minorProductItemGroup: SignalR_ProductItemGroup;
  minorProductItemGroupId?: number;
  productItemType: SignalR_ProductItemType;
  productItemTypeId?: number;
  productImage: SignalR_ProductImage;
  productImageId?: number;
  superseededProducts?: SignalR_SuperseededProduct[];
  productTranslations?: SignalR_ProductTranslation[];
  equipmentKits?: SignalR_EquipmentKit[];
  aliases?: SignalR_ProductAlias[];
  productNotificationProducts?: SignalR_ProductNotificationProduct[];
  productAlias: string;
  productUNSPSCode: string;
  name: string;
  eclassCode: string;
  kitProducts?: SignalR_KitProduct[];
  equipmentProducts?: SignalR_EquipmentProduct[];
  promotionProducts?: SignalR_PromotionProduct[];
  toBeIndexed: boolean;
  currentlySupersededByNewProductId?: Guid;
  currentlySupersededByNewProduct: SignalR_NewProduct;
  excludeFromIndexing: boolean;
  nextSuperseededDate?: Date;
  isRockToolItem: boolean;
  productCode: SignalR_ProductCode;
  productCodeId?: number;
  productTypeCode: string;
  profileCode: string;
  eccN_EU_Code: string;
  eccN_USA_Code: string;
  eU_Tariff_Code: string;
  global_Tariff_Code: string;
  usA_Tariff_Code: string;
  itemCategoryGroupCode: string;
  dangerousGoodsIndicatorProfileCode: string;
  minimumOrderQuantity?: number;
  profileValidityDate?: Date;
  qunatityCharacteristics?: SignalR_NewProductQunatityCharacteristic[];
}
/***
 * Generated from Entities\UnitsOfMeasure.cs
 */
export interface SignalR_UnitsOfMeasure {
  id: number;
  uom: string;
  internalDescription: string;
  isDecimal: boolean;
  newProducts?: SignalR_NewProduct[];
}
/***
 * Generated from Entities\ProductItemClass.cs
 */
export interface SignalR_ProductItemClass {
  id: number;
  name: string;
  identifier: string;
  newProducts?: SignalR_NewProduct[];
}
/***
 * Generated from Entities\ProductItemGroup.cs
 */
export interface SignalR_ProductItemGroup {
  id: number;
  name: string;
  identifier: string;
  type: number;
  isRockToolItem: boolean;
  minorItemGroupNewProducts?: SignalR_NewProduct[];
  majorItemGroupNewProducts?: SignalR_NewProduct[];
}
/***
 * Generated from Entities\ProductItemType.cs
 */
export interface SignalR_ProductItemType {
  id: number;
  name: string;
  identifier: string;
  newProducts?: SignalR_NewProduct[];
}
/***
 * Generated from Entities\ProductImage.cs
 */
export interface SignalR_ProductImage {
  id: number;
  value: string;
  createdDate: Date;
  qbankId?: number;
  isInBlob: boolean;
}
/***
 * Generated from Entities\NewProducts\SuperseededProduct.cs
 */
export interface SignalR_SuperseededProduct extends SignalR_OnModelCreating {
  id: Guid;
  newProductId: Guid;
  newProduct: SignalR_NewProduct;
  superSeededProduct: SignalR_NewProduct;
  superSeededProductId: Guid;
  startDate?: Date;
  endDate?: Date;
  type: string;
}
/***
 * Generated from Entities\ProductTranslation.cs
 */
export interface SignalR_ProductTranslation {
  id: number;
  productId: Guid;
  product: SignalR_NewProduct;
  languageId: number;
  language: SignalR_Language;
  value: string;
}
/***
 * Generated from Entities\ProductAlias.cs
 */
export interface SignalR_ProductAlias extends SignalR_AuditableEntity {
  code: string;
  businessRelationId: number;
  newProduct: SignalR_NewProduct;
  newProductId?: Guid;
  businessRelation: SignalR_BusinessRelation;
}
/***
 * Generated from Entities\ProductNotificationProduct.cs
 */
export interface SignalR_ProductNotificationProduct {
  id: number;
  productNotificationId: number;
  productNotification: SignalR_ProductNotification;
  newProductId?: Guid;
  newProduct: SignalR_NewProduct;
}
/***
 * Generated from Entities\ProductNotification.cs
 */
export interface SignalR_ProductNotification extends SignalR_AuditableEntity {
  message: string;
  productNotificationTargets?: SignalR_ProductNotificationTarget[];
  productNotificationProducts?: SignalR_ProductNotificationProduct[];
  productNotificationTranslations?: SignalR_ProductNotificationTranslation[];
  changeTracking: SignalR_ChangeTracking;
  changeTrackingId?: number;
}
/***
 * Generated from Entities\ProductNotificationTarget.cs
 */
export interface SignalR_ProductNotificationTarget {
  id: number;
  companyId?: number;
  company: SignalR_Company;
  businessRelationId?: number;
  businessRelation: SignalR_BusinessRelation;
  productNotificationId: number;
  productNotification: SignalR_ProductNotification;
}
/***
 * Generated from Entities\ProductNotificationTranslation.cs
 */
export interface SignalR_ProductNotificationTranslation {
  id: number;
  productNotificationId: number;
  productNotification: SignalR_ProductNotification;
  messageUserContentShortId: number;
  messageUserContentShort: SignalR_UserContentShort;
  languageId: number;
  language: SignalR_Language;
  createdDate: Date;
}
/***
 * Generated from Entities\UserContent\UserContentShort.cs
 */
export interface SignalR_UserContentShort extends SignalR_AuditableEntity {
  content: string;
  languageId: number;
  language: SignalR_Language;
  modifiedById?: Guid;
}
/***
 * Generated from Entities\ChangeTracking.cs
 */
export interface SignalR_ChangeTracking {
  id: number;
}
/***
 * Generated from Entities\KitProducts.cs
 */
export interface SignalR_KitProduct extends SignalR_OnModelCreating {
  id: Guid;
  quantity: number;
  position: number;
  kitNewProduct: SignalR_NewProduct;
  kitParentNewProductId?: Guid;
  newProduct: SignalR_NewProduct;
  newProductId: Guid;
}
/***
 * Generated from Entities\Equipments\EquipmentProduct.cs
 */
export interface SignalR_EquipmentProduct extends SignalR_AuditableEntity {
  equipmentId: number;
  equipment: SignalR_Equipment;
  newProductId: Guid;
  newProduct: SignalR_NewProduct;
  productCode: string;
  mediaId: number;
  media: SignalR_Media;
}
/***
 * Generated from Entities\Media.cs
 */
export interface SignalR_Media {
  id: number;
  identifier: string;
  type: string;
  name: string;
  isPropagated: boolean;
  markedForDeletion?: boolean;
  latestSyncDate?: Date;
  createdDate?: Date;
  tags?: SignalR_Tag[];
  mediaShelves?: SignalR_MediaShelf[];
  latestCalculatedDate?: Date;
  equipmentMedias?: SignalR_EquipmentMedia[];
  updatedDate?: Date;
  billOfMaterialUpdatedDate?: Date;
  hasBomContent: boolean;
  toBeImported: boolean;
  description: string;
  downloadedManual: SignalR_DownloadedManual;
  downloadedManualJobs?: SignalR_DownloadManualJob[];
  relatedMediaIdentifiers?: SignalR_RelatedMediaIdentifier[];
  toBeIndexed: boolean;
  languageId?: number;
  language: SignalR_Language;
  downloadedManualPackageMedias?: SignalR_DownloadedManualPackageMedia[];
  documotoMediaId?: number;
  mediaAsJson: SignalR_MediaAsJson;
}
/***
 * Generated from Entities\Tag.cs
 */
export interface SignalR_Tag {
  id: number;
  name: string;
  value: string;
  media: SignalR_Media;
  mediaId: number;
  createdDate?: Date;
  tagHeader: SignalR_TagHeader;
  type: SignalR_TagType;
}
/***
 * Generated from Entities\MediaShelf.cs
 */
export interface SignalR_MediaShelf {
  id: number;
  shelf: SignalR_Shelf;
  shelfId: number;
  media: SignalR_Media;
  mediaId: number;
  isPropagated: boolean;
}
/***
 * Generated from Entities\Shelf.cs
 */
export interface SignalR_Shelf {
  id: number;
  name: string;
  hashKey: string;
  displayOrder?: number;
  createDate?: Date;
  updateDate?: Date;
  version?: number;
  contactEmail: string;
  isPropagated: boolean;
  enabledForUserType?: SignalR_UserType;
}
/***
 * Generated from Entities\Equipments\EquipmentMedia.cs
 */
export interface SignalR_EquipmentMedia extends SignalR_OnModelCreating {
  id: number;
  mediaId: number;
  equipmentId: number;
  equipment: SignalR_Equipment;
  media: SignalR_Media;
  createdDate?: Date;
  partManualToBeIndexed: boolean;
  recentlyViewedMedias?: SignalR_RecentlyViewedMedia[];
}
/***
 * Generated from Entities\RecentlyViewedMedia.cs
 */
export interface SignalR_RecentlyViewedMedia extends SignalR_OnModelCreating {
  id: number;
  viewDate: Date;
  userId: Guid;
  user: SignalR_User;
  equipmentMediaId: number;
  equipmentMedia: SignalR_EquipmentMedia;
}
/***
 * Generated from Entities\DownloadedManual.cs
 */
export interface SignalR_DownloadedManual extends SignalR_AuditableEntity {
  mediaId: number;
  media: SignalR_Media;
  latestDocumotoDownloadDate?: Date;
  status: SignalR_DownloadedManualStatus;
  fileType: SignalR_DownloadedManualFileType;
  fileSize?: number;
  errorCode: SignalR_DownloadedManualErrorCode;
  errorText: string;
  userDownloadedManuals?: SignalR_UserDownloadedManual[];
  lastDownloadedByUserDate?: Date;
  totalDownloadCount: number;
  documotoFetchMediaContentJobId?: number;
  documotoFetchMediaContentJob: SignalR_DocumotoFetchMediaContentJob;
  downloadManualJobId?: number;
  downloadManualJob: SignalR_DownloadManualJob;
}
/***
 * Generated from Entities\UserDownloadedManual.cs
 */
export interface SignalR_UserDownloadedManual extends SignalR_AuditableEntity {
  lastDownloadDate?: Date;
  downloadRequestedDate: Date;
  downloadCount: number;
  downloadedManualId?: number;
  downloadedManual: SignalR_DownloadedManual;
  downloadedManualPackageId?: number;
  downloadedManualPackage: SignalR_DownloadedManualPackage;
  equipmentId: number;
  equipment: SignalR_Equipment;
  userId: Guid;
  user: SignalR_User;
  shouldBeNotified: boolean;
  shouldBeEmailed: boolean;
  customName: string;
  isSoftDeleted: boolean;
}
/***
 * Generated from Entities\DownloadedManualPackage.cs
 */
export interface SignalR_DownloadedManualPackage extends SignalR_AuditableEntity {
  latestPackageUpdateDate?: Date;
  status: SignalR_DownloadedManualStatus;
  fileSize?: number;
  errorCode: SignalR_DownloadedManualErrorCode;
  errorText: string;
  userDownloadedManuals?: SignalR_UserDownloadedManual[];
  media?: SignalR_DownloadedManualPackageMedia[];
  isPartiallyComplete: boolean;
  packageHash: string;
  equipmentId: number;
  equipment: SignalR_Equipment;
  isAllManualsForUserType?: SignalR_UserType;
  lastDownloadedByUserDate?: Date;
  totalDownloadCount: number;
  isContentOutOfSync: boolean;
}
/***
 * Generated from Entities\DownloadedManualPackageMedia.cs
 */
export interface SignalR_DownloadedManualPackageMedia extends SignalR_AuditableEntity {
  mediaId: number;
  media: SignalR_Media;
  documotoDownloadedDate?: Date;
  downloadedManualPackageId: number;
  downloadedManualPackage: SignalR_DownloadedManualPackage;
}
/***
 * Generated from Entities\DocumotoFetchMediaContentJob.cs
 */
export interface SignalR_DocumotoFetchMediaContentJob {
  id: number;
  jobStatus: SignalR_JobStatus;
  jobAddedTime: Date;
  jobStartTime?: Date;
  jobEndTime?: Date;
  durableFunctionInstanceId?: Guid;
  statusQueryGetUri: string;
  terminatePostUri: string;
  blobLogName: string;
  priority: SignalR_JobPriority;
  mediaId: number;
  media: SignalR_Media;
  trigger: SignalR_MediaContentJobTrigger;
  documotoMediaSyncJobId?: number;
  documotoMediaSyncJob: SignalR_DocumotoMediaSyncJob;
  documotoFetchMediaContentJobFiles?: SignalR_DocumotoFetchMediaContentJobFile[];
  documotoProcessBillOfMaterialContentJobs?: SignalR_DocumotoProcessBillOfMaterialContentJob[];
  downloadManualJobs?: SignalR_DownloadManualJob[];
  triggeredBy: string;
}
/***
 * Generated from Entities\DocumotoMediaSyncJob.cs
 */
export interface SignalR_DocumotoMediaSyncJob {
  id: number;
  jobStatus: SignalR_JobStatus;
  jobAddedTime: Date;
  jobStartTime?: Date;
  jobEndTime?: Date;
  durableFunctionInstanceId?: Guid;
  statusQueryGetUri: string;
  terminatePostUri: string;
  mediaIdentifier: string;
  triggeredBy: string;
  blobLogName: string;
  beforeIsPartsManual?: boolean;
  afterIsPartsManual?: boolean;
  hasExistingOrPendingDownload?: boolean;
  hasDocumotoMediaId?: boolean;
  documotoFetchMediaContentJobs?: SignalR_DocumotoFetchMediaContentJob[];
  priority: SignalR_JobPriority;
}
/***
 * Generated from Entities\DocumotoFetchMediaContentJobFile.cs
 */
export interface SignalR_DocumotoFetchMediaContentJobFile {
  id: number;
  createdDate: Date;
  blobFilename: string;
  status: SignalR_MediaContentFileStatus;
  size?: number;
  downloadType: SignalR_MediaContentDownloadType;
  fileType: SignalR_FileType;
  downloadStartDate?: Date;
  blobDeletedDate?: Date;
  md5: string;
  downloadEndDate?: Date;
  documotoExportId: string;
  documotoExportStatusDate?: Date;
  blobSavedDate?: Date;
  errorMessage: string;
  documotoExportStatus: string;
  documotoExportStatusCheckCount: number;
  documotoFetchMediaContentJobId: number;
  documotoFetchMediaContentJob: SignalR_DocumotoFetchMediaContentJob;
}
/***
 * Generated from Entities\DocumotoExportFileJob\DocumotoProcessBillOfMaterialContentJob.cs
 */
export interface SignalR_DocumotoProcessBillOfMaterialContentJob {
  id: number;
  jobStatus: SignalR_JobStatus;
  jobAddedTime: Date;
  jobsStartTime?: Date;
  jobEndTime?: Date;
  durableFunctionInstanceId?: Guid;
  statusQueryGetUri: string;
  terminatePostUri: string;
  mediaidentifier: string;
  documotoFetchMediaContentJobId?: number;
  documotoFetchMediaContentJob: SignalR_DocumotoFetchMediaContentJob;
  metadataXmlMD5: string;
  blobLogName: string;
  priority: SignalR_JobPriority;
}
/***
 * Generated from Entities\DownloadManualJob.cs
 */
export interface SignalR_DownloadManualJob extends SignalR_OnModelCreating {
  id: number;
  jobStatus: SignalR_JobStatus;
  jobAddedTime: Date;
  jobStartTime?: Date;
  jobEndTime?: Date;
  durableFunctionInstanceId?: Guid;
  statusQueryGetUri: string;
  terminatePostUri: string;
  mediaId: number;
  media: SignalR_Media;
  triggeredBy: string;
  blobLogName: string;
  priority: SignalR_JobPriority;
  documotoFetchMediaContentJobId?: number;
  documotoFetchMediaContentJob: SignalR_DocumotoFetchMediaContentJob;
  pdfMergeIssues?: SignalR_DocumotoMdzPdfIssueDto[];
  customStatus: SignalR_DownloadManualJobCustomStatusDto;
}
/***
 * Generated from Entities\DocumotoImport\DocumotoMdzPdfIssueDto.cs
 */
export interface SignalR_DocumotoMdzPdfIssueDto {
  pageHash: string;
  title: string;
  exceptionType: string;
  exceptionMessage: string;
  exceptionStack: string;
  message: string;
  fileName: string;
  plzFileName: string;
  isWarning: boolean;
  errorPageAdded: boolean;
  errorCode: string;
}
/***
 * Generated from Entities\Dtos\DownloadManualJobStatusDto.cs
 */
export interface SignalR_DownloadManualJobCustomStatusDto {
  jobId: number;
  mediaId: number;
  instanceId: Guid;
  blobLogName: string;
  documotoZipFilename: string;
  documotoZipFileSize?: number;
  documotoExportDurationMs?: number;
  documotoDownloadDurationMs?: number;
  documotoSaveToBlobDurationMs?: number;
  createPdfDurationMs?: number;
  savePdfDurationMs?: number;
  createdPdfFileSize?: number;
  importedPdfCount?: number;
  importedPdfPageCount?: number;
  isUpdateOfDownloadedManual: boolean;
  fileType: SignalR_DownloadedManualFileType;
  errorMessage: string;
  curentStep: string;
  wasPartiallySuccessful: boolean;
}
/***
 * Generated from Entities\RelatedMedia.cs
 */
export interface SignalR_RelatedMediaIdentifier {
  id: number;
  media: SignalR_Media;
  mediaId: number;
  identifier: string;
}
/***
 * Generated from Entities\MediaAsJson.cs
 */
export interface SignalR_MediaAsJson extends SignalR_AuditableEntity, SignalR_OnModelCreating {
  jsonContent: string;
  mediaId: number;
  media: SignalR_Media;
}
/***
 * Generated from Entities\Promotions\PromotionProduct.cs
 */
export interface SignalR_PromotionProduct {
  id: number;
  productCompanyId?: number;
  productCompany: SignalR_Company;
  promotionId: number;
  promotion: SignalR_Promotion;
  sequenceOrder?: number;
  productCode: string;
  newProductId?: Guid;
  newProduct: SignalR_NewProduct;
}
/***
 * Generated from Entities\Promotions\Promotion.cs
 */
export interface SignalR_Promotion extends SignalR_AuditableEntity {
  type: SignalR_PromotionType;
  title: string;
  description: string;
  urlToExternalPromotion: string;
  placement: SignalR_PromotionPlacement;
  priority: number;
  publishFromDate: Date;
  publishToDate: Date;
  createdBy?: Guid;
  modifiedBy?: Guid;
  userFileId: Guid;
  userFile: SignalR_UserFile;
  promotionTargets?: SignalR_PromotionTarget[];
  promotionProducts?: SignalR_PromotionProduct[];
  promotionProductQuantities?: SignalR_PromotionProductQuantity[];
  contentId?: number;
  content: SignalR_Content;
  toBeIndexed: boolean;
  changeTrackingId?: number;
  changeTracking: SignalR_ChangeTracking;
}
/***
 * Generated from Entities\UserFile.cs
 */
export interface SignalR_UserFile {
  id: Guid;
  createdDate: Date;
  expirationDate?: Date;
  fileName: string;
  displayName: string;
  size: number;
  user: SignalR_User;
  userId?: Guid;
  mimeType: SignalR_MimeType;
  renditionOriginal: SignalR_UserFile;
  rendition?: SignalR_Rendition;
  renditions?: SignalR_UserFile[];
}
/***
 * Generated from Entities\MimeType.cs
 */
export interface SignalR_MimeType {
  id: number;
  value: string;
}
/***
 * Generated from Entities\Promotions\PromotionTarget.cs
 */
export interface SignalR_PromotionTarget {
  id: number;
  companyId?: number;
  company: SignalR_Company;
  businessRelationId?: number;
  businessRelation: SignalR_BusinessRelation;
  productModelId?: number;
  productModel: SignalR_ProductModel;
  equipmentId?: number;
  equipment: SignalR_Equipment;
  promotionId: number;
  promotion: SignalR_Promotion;
}
/***
 * Generated from Entities\PromotionProductQuantity.cs
 */
export interface SignalR_PromotionProductQuantity extends SignalR_OnModelCreating {
  id: number;
  promotionId: number;
  promotion: SignalR_Promotion;
  productCode: string;
  orderedQuantity: number;
  maxOrderableQuantity: number;
}
/***
 * Generated from Entities\ProductCode.cs
 */
export interface SignalR_ProductCode extends SignalR_OnModelCreating {
  id: number;
  code: string;
  createdDate: Date;
  newProducts?: SignalR_NewProduct[];
  catalogComponents?: SignalR_CatalogComponent[];
  productUNSPSCodes?: SignalR_ProductUNSPSCode[];
  supersessionProductCodes?: SignalR_ProductSupersession[];
  superseededByProductCodes?: SignalR_ProductSupersession[];
}
/***
 * Generated from Entities\CatalogComponent.cs
 */
export interface SignalR_CatalogComponent {
  id: number;
  type: SignalR_CatalogComponentType;
  offeringType: string;
  rockDrill: string;
  componentModelName: string;
  component: string;
  pistonRod: string;
  feedLength: string;
  recievedDate: Date;
  productCodeId: number;
  productModelId: number;
  productCode: SignalR_ProductCode;
  productModel: SignalR_ProductModel;
  toBeIndexed: boolean;
}
/***
 * Generated from Entities\ProductUNSPSCode.cs
 */
export interface SignalR_ProductUNSPSCode extends SignalR_AuditableEntity {
  code: string;
  businessRelationId: number;
  businessRelation: SignalR_BusinessRelation;
  productCodeId?: number;
  productCode: SignalR_ProductCode;
}
/***
 * Generated from Entities\NewProducts\ProductSupersession.cs
 */
export interface SignalR_ProductSupersession {
  id: number;
  productCodeId: number;
  productCode: SignalR_ProductCode;
  superSeededByProductCodeId: number;
  superSeededByProductCode: SignalR_ProductCode;
  type: SignalR_SuperSessionType;
  startDate?: Date;
  endDate?: Date;
  createdDate: Date;
}
/***
 * Generated from Entities\NewProducts\NewProductQunatityCharacteristic.cs
 */
export interface SignalR_NewProductQunatityCharacteristic {
  id: number;
  newProductId: Guid;
  quantity: number;
  type: SignalR_CharacteristicType;
  unitCode: string;
  newProduct: SignalR_NewProduct;
}
/***
 * Generated from Entities\Equipments\EquipmentSignal.cs
 */
export interface SignalR_EquipmentSignal {
  id: number;
  equipmentId: number;
  equipment: SignalR_Equipment;
  signalType: SignalR_EquipmentSignalType;
  value: number;
  captureDate: Date;
}
/***
 * Generated from Entities\Equipments\BulletinEquipments.cs
 */
export interface SignalR_BulletinEquipment {
  id: number;
  equipmentId: number;
  equipment: SignalR_Equipment;
  bulletinId: Guid;
  bulletin: SignalR_IntegrationBulletin;
}
/***
 * Generated from Entities\IntegrationBulletins.cs
 */
export interface SignalR_IntegrationBulletin extends SignalR_OnModelCreating {
  id: Guid;
  salesForceId: string;
  bulletinId: string;
  recordId: string;
  title: string;
  description: string;
  bulletinType: string;
  divisions: string;
  productionEntity?: number;
  severity?: number;
  isInternal: boolean;
  createdDate: Date;
  endDate?: Date;
  lastModifiedDate?: Date;
  content?: SignalR_Content;
  bulletinUserFiles?: SignalR_IntegrationBulletinUserFile[];
  status: string;
  warrantyApplicable?: boolean;
  createdByName: string;
  lastModifiedByName: string;
  bulletinCreatedDate?: Date;
  bulletinLastModifiedDate?: Date;
  toBeIndexed: boolean;
  isMySandvikBulletin: boolean;
  serialNumbers: string;
  productModels: string;
  userStatus: string;
  files?: string[];
  iB_UserStatus?: SignalR_IntegrationBulletinUserStatus[];
  iB_SerialNumbers?: SignalR_IntegrationBulletinSerialNumbers[];
  iB_ProductModels?: SignalR_IntegrationBulletinProductModels[];
  bulletinEquipments?: SignalR_BulletinEquipment[];
}
/***
 * Generated from Entities\IntegrationBulletinUserFile.cs
 */
export interface SignalR_IntegrationBulletinUserFile {
  id: number;
  integrationBulletinId?: Guid;
  integrationBulletin: SignalR_IntegrationBulletin;
  userFileId?: Guid;
  userFile: SignalR_UserFile;
}
/***
 * Generated from Entities\IntegrationBulletinUserStatus.cs
 */
export interface SignalR_IntegrationBulletinUserStatus {
  id: number;
  markRead?: boolean;
  otherRead?: boolean;
  readDate?: Date;
  user: SignalR_User;
  bulletin: SignalR_IntegrationBulletin;
}
/***
 * Generated from Entities\IntegrationBulletinSerialNumbers.cs
 */
export interface SignalR_IntegrationBulletinSerialNumbers {
  id: number;
  serialNumber: string;
  bulletin: SignalR_IntegrationBulletin;
}
/***
 * Generated from Entities\IntegrationBulletinProductModels.cs
 */
export interface SignalR_IntegrationBulletinProductModels {
  id: number;
  productModel: string;
  bulletin: SignalR_IntegrationBulletin;
}
/***
 * Generated from Entities\FleetDocument.cs
 */
export interface SignalR_FleetDocument {
  id: number;
  title: string;
  description: string;
  fleetDocumentDate: Date;
  documentType: SignalR_FleetDocumentType;
  deliverySequenceId?: number;
  deliverySequence: SignalR_DeliverySequence;
  assetType: SignalR_AssetType;
  componentTypeId?: number;
  componentType: SignalR_ComponentType;
  componentSerialNumber: string;
  equipmentId?: number;
  equipment: SignalR_Equipment;
  createdByUserId: Guid;
  createdByUser: SignalR_User;
  createdDate: Date;
  modifiedByUserId?: Guid;
  modifiedByUser: SignalR_User;
  modifiedDate?: Date;
  toBeIndexed: boolean;
  fleetDocumentsUserFiles?: SignalR_FleetDocumentsUserFile[];
  targetBusinessRelationId?: number;
  targetBusinessRelation: SignalR_BusinessRelation;
  operationSiteId?: number;
  operationSite: SignalR_OperationSite;
}
/***
 * Generated from Entities\FleetDocumentsUserFile.cs
 */
export interface SignalR_FleetDocumentsUserFile {
  id: number;
  fleetDocumentId: number;
  fleetDocument: SignalR_FleetDocument;
  userFileId: Guid;
  userFile: SignalR_UserFile;
}
/***
 * Generated from Entities\OperationSite.cs
 */
export interface SignalR_OperationSite {
  id: number;
  name: string;
  operationSiteMdgId: string;
  longitude: number;
  latitude: number;
  stateProvince: string;
  siteType: number;
  countryId?: number;
  country: SignalR_Country;
  operationSiteOperators?: SignalR_OperationSiteOperator[];
  operationSiteOwners?: SignalR_OperationSiteOwner[];
  equipments?: SignalR_Equipment[];
  deliverySequenceOperationSites?: SignalR_DeliverySequenceOperationSite[];
  createdDate?: Date;
  modifiedDate?: Date;
  toBeIndexed: boolean;
}
/***
 * Generated from Entities\OperationSiteOperators.cs
 */
export interface SignalR_OperationSiteOperator {
  id: Guid;
  operationSiteId: number;
  operationSite: SignalR_OperationSite;
  customerId: number;
  customer: SignalR_Customer;
  createdDate?: Date;
  modifiedDate?: Date;
}
/***
 * Generated from Entities\OperationSiteOwner.cs
 */
export interface SignalR_OperationSiteOwner {
  id: Guid;
  operationSiteId: number;
  operationSite: SignalR_OperationSite;
  customerId: number;
  customer: SignalR_Customer;
  createdDate?: Date;
  modifiedDate?: Date;
}
/***
 * Generated from Entities\DeliverySequenceOperationSite.cs
 */
export interface SignalR_DeliverySequenceOperationSite {
  id: number;
  deliverySequenceId: number;
  deliverySequence: SignalR_DeliverySequence;
  operationSiteId: number;
  operationSite: SignalR_OperationSite;
}
/***
 * Generated from Entities\EquipmentCalendar.cs
 */
export interface SignalR_EquipmentCalendar extends SignalR_AuditableEntity {
  createdByUser: SignalR_User;
  createdByUserId: Guid;
  equipment: SignalR_Equipment;
  equipmentId: number;
  startDate: Date;
  endDate: Date;
  startEngineHours: number;
  monthlyUtilizationRate: number;
  maintenanceStatuses?: SignalR_EquipmentMaintenanceStatus[];
}
/***
 * Generated from Entities\EquipmentMaintenanceStatus.cs
 */
export interface SignalR_EquipmentMaintenanceStatus extends SignalR_AuditableEntity, SignalR_OnModelCreating {
  equipmentCalendar: SignalR_EquipmentCalendar;
  equipmentCalendarId: number;
  createdByUser: SignalR_User;
  createdByUserId: Guid;
  isoWeek: number;
  isoYear: number;
  status: SignalR_MaintenanceCalendarWeekStatus;
  equipmentEngineHours: number;
  kitIntervalHours: number;
}
/***
 * Generated from Entities\Claim.cs
 */
export interface SignalR_Claim extends SignalR_AuditableEntity {
  claimId: string;
  mdgEquipmentId: string;
  causalPart: string;
  failureDate?: Date;
  condition: string;
  equipment: SignalR_Equipment;
  content: SignalR_Content;
  deliverySequence: SignalR_DeliverySequence;
  claimType: SignalR_ClaimType;
  claimStatus: SignalR_ClaimStatus;
  claimDecision: SignalR_ClaimDecision;
  referenceClaimNumber: string;
  toBeIndexed: boolean;
  isDeleted: boolean;
  externalCreatorUserId?: Guid;
  externalCreatorUser: SignalR_User;
  isMySandvikClaim: boolean;
}
/***
 * Generated from Entities\ClaimType.cs
 */
export interface SignalR_ClaimType {
  id: number;
  name: string;
}
/***
 * Generated from Entities\ClaimStatus.cs
 */
export interface SignalR_ClaimStatus {
  id: number;
  name: string;
}
/***
 * Generated from Entities\ClaimDecision.cs
 */
export interface SignalR_ClaimDecision {
  id: number;
  name: string;
}
/***
 * Generated from Entities\ComponentWarrantyRegistration.cs
 */
export interface SignalR_ComponentWarrantyRegistration {
  id: number;
  deliverySequenceId: number;
  deliverySequence: SignalR_DeliverySequence;
  equipmentId: number;
  equipment: SignalR_Equipment;
  engineHours: number;
  componentType: string;
  fittedComponentSerialNumber: string;
  replacedComponentSerialNumber: string;
  invoiceNumber: string;
  dateOfFitment: Date;
  dateOfPurchase: Date;
  isSubmitted: boolean;
  createdDate: Date;
  updatedDate?: Date;
  toBeIndexed: boolean;
  createdByUserId: Guid;
  createdByUser: SignalR_User;
  contentId: number;
  content: SignalR_Content;
  userFileId: Guid;
  userFile: SignalR_UserFile;
}
/***
 * Generated from Entities\WarrantyApplication.cs
 */
export interface SignalR_WarrantyApplication {
  id: Guid;
  referenceApplicationNumber: string;
  applicantUserId: Guid;
  applicantUser: SignalR_User;
  deliverySequenceId: number;
  deliverySequence: SignalR_DeliverySequence;
  recipientEmails: string;
  toBeIndexed: boolean;
  machineDown: boolean;
  photosSupplied: boolean;
  contactName: string;
  contactPhoneNumber: string;
  contentId: number;
  content: SignalR_Content;
  contactEmail: string;
  orderNumber: string;
  failureDate: Date;
  operatingHours: number;
  otherHours: number;
  commissioningDate: Date;
  createdDate: Date;
  equipmentId: number;
  equipment: SignalR_Equipment;
  isSubmitted: boolean;
  conditionDescription: string;
  causeDescription: string;
  correctionDescription: string;
  warrantyApplicationAttachments?: SignalR_WarrantyApplicationAttachment[];
  warrantyApplicationProducts?: SignalR_WarrantyApplicationProduct[];
}
/***
 * Generated from Entities\WarrantyApplicationAttachment.cs
 */
export interface SignalR_WarrantyApplicationAttachment {
  id: number;
  warrantyApplicationId: Guid;
  warrantyApplication: SignalR_WarrantyApplication;
  userFileId: Guid;
  userFile: SignalR_UserFile;
  type: SignalR_WarrantyApplicationFileType;
  warrantyApplicationProductId?: number;
  warrantyApplicationProduct: SignalR_WarrantyApplicationProduct;
}
/***
 * Generated from Entities\WarrantyApplicationProducts.cs
 */
export interface SignalR_WarrantyApplicationProduct {
  id: number;
  warrantyApplicationId: Guid;
  warrantyApplication: SignalR_WarrantyApplication;
  newProductId: Guid;
  newProduct: SignalR_NewProduct;
  isProofOfPurchaseSupplied: boolean;
  componentSerialNumber: string;
  warrantyApplicationAttachments?: SignalR_WarrantyApplicationAttachment[];
}
/***
 * Generated from Entities\CustomerClassification.cs
 */
export interface SignalR_CustomerClassification {
  id: number;
  code: string;
}
/***
 * Generated from Entities\SalesArea.cs
 */
export interface SignalR_SalesArea {
  id: number;
  name: string;
}
/***
 * Generated from Entities\DeliverySequence.cs
 */
export interface SignalR_AddressLine {
  id: number;
  value: string;
  sequence: number;
}
/***
 * Generated from Entities\Role.cs
 */
export interface SignalR_Role {
  id: number;
  name: string;
  description: string;
  type: SignalR_RoleType;
  ecommercePermissions: SignalR_RoleEcommercePermission;
  adminPermissions: SignalR_RoleAdministrationPermission;
  users?: SignalR_User[];
  features?: SignalR_RoleFeature[];
  isDefaultUserRole?: boolean;
  updatedDate?: Date;
  createdDate?: Date;
  level: SignalR_RoleLevel;
  changeTrackingId?: number;
  changeTracking: SignalR_ChangeTracking;
  sendWelcomeEmail: boolean;
}
/***
 * Generated from Entities\RoleFeature.cs
 */
export interface SignalR_RoleFeature extends SignalR_AuditableEntity {
  featureId: number;
  feature: SignalR_Feature;
  roleId: number;
  role: SignalR_Role;
  setting: SignalR_FeatureSetting;
}
/***
 * Generated from Entities\Feature.cs
 */
export interface SignalR_Feature {
  id: number;
  identifier: string;
  parentFeatureId?: number;
  parentFeature: SignalR_Feature;
  allowedOnUserLevel: boolean;
  isDeliverySequenceFeature: boolean;
  enabled: boolean;
  changeTrackingId?: number;
  changeTracking: SignalR_ChangeTracking;
  roleFeatures?: SignalR_RoleFeature[];
  userFeatures?: SignalR_UserFeature[];
  globalFeatures?: SignalR_GlobalFeature[];
  featureResponsibilities?: SignalR_FeatureResponsibilities[];
}
/***
 * Generated from Entities\UserFeature.cs
 */
export interface SignalR_UserFeature extends SignalR_AuditableEntity {
  featureId: number;
  feature: SignalR_Feature;
  userId: Guid;
  user: SignalR_User;
  setting: SignalR_FeatureSetting;
}
/***
 * Generated from Entities\GlobalFeature.cs
 */
export interface SignalR_GlobalFeature extends SignalR_AuditableEntity {
  featureId: number;
  feature: SignalR_Feature;
  enabled: boolean;
}
/***
 * Generated from Entities\FeatureResponsibilities.cs
 */
export interface SignalR_FeatureResponsibilities {
  id: number;
  featureId: number;
  feature: SignalR_Feature;
  scopeId: number;
  scope: SignalR_Scope;
}
/***
 * Generated from Entities\Emails\EmailRecipient.cs
 */
export interface SignalR_EmailRecipient {
  id: number;
  emailId: number;
  email: SignalR_Email;
  userId?: Guid;
  user: SignalR_User;
  emailAddress: string;
  sendGridStatus: SignalR_SendGridStatus;
  sendGridStatusUpdatedDate?: Date;
}
/***
 * Generated from Entities\Emails\Email.cs
 */
export interface SignalR_Email {
  id: number;
  identifierId: string;
  category: SignalR_EmailCategory;
  subject: string;
  body: string;
  sendgridResponseCode: string;
  hasFailed: boolean;
  createdDate: Date;
  requestSentDate?: Date;
  hash: string;
  emailRecipients?: SignalR_EmailRecipient[];
  emailFiles?: SignalR_EmailFile[];
  languageId?: number;
  language: SignalR_Language;
}
/***
 * Generated from Entities\Emails\EmailFile.cs
 */
export interface SignalR_EmailFile {
  id: Guid;
  fileName: string;
  mimeType: string;
  createdDate: Date;
  emailId: number;
  email: SignalR_Email;
}
/***
 * Generated from Entities\DisabledUserNotificationSettings.cs
 */
export interface SignalR_DisabledUserNotificationSetting {
  id: number;
  userId: Guid;
  user: SignalR_User;
  userNotificationSettingsType: SignalR_UserNotificationSettingsType;
  userNotificationSettingsFeatureType: SignalR_UserNotificationSettingsFeatureType;
}
/***
 * Generated from Entities\SystemMessageResponsibilities.cs
 */
export interface SignalR_SystemMessageResponsibilities {
  id: number;
  systemMessageId: number;
  companyId?: number;
  company: SignalR_Company;
}
/***
 * Generated from Entities\SystemMessageImage.cs
 */
export interface SignalR_SystemMessageImage {
  id: number;
  userFile: SignalR_UserFile;
  userFileId: Guid;
  systemMessage: SignalR_SystemMessage;
  systemMessageId: number;
  language: SignalR_Language;
  languageId: number;
}
/***
 * Generated from Entities\Events\NotificationEvent.cs
 */
export interface SignalR_NotificationEvent extends SignalR_MibpApiEvent {
  notifications?: SignalR_Notification[];
  unreadNotifications: number;
}
/***
 * Generated from Entities\Notification.cs
 */
export interface SignalR_Notification {
  id: Guid;
  args: string;
  type: SignalR_NotificationType;
  resourceTitle: SignalR_Resource;
  resourceMessage: SignalR_Resource;
  url: string;
  user: SignalR_User;
  userId: Guid;
  createdDate: Date;
  readDate?: Date;
}
/***
 * Generated from Entities\Events\ProfileLoggedInEvent.cs
 */
export interface SignalR_ProfileLoggedInEvent extends SignalR_MibpApiEvent {
  loggedInProfiles?: Guid[];
}
/***
 * Generated from Entities\Events\PunchoutProfileUpdatedEvent.cs
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignalR_PunchoutProfileUpdatedEvent extends SignalR_MibpApiEvent {
}
/***
 * Generated from Entities\Events\RefreshSystemMessagesEvent.cs
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignalR_RefreshSystemMessagesEvent extends SignalR_MibpApiEvent {
}
/***
 * Generated from Entities\Events\SelectProfileEvent.cs
 */
export interface SignalR_SelectProfileEvent extends SignalR_MibpApiEvent {
  profiles?: SignalR_SelectProfileUserViewModel[];
}
/***
 * Generated from Entities\ViewModels\SelectProfileUserViewModel.cs
 */
export interface SignalR_SelectProfileUserViewModel {
  name: string;
  id: Guid;
  isPunchoutProfile: boolean;
  isLoggedIn: boolean;
}
/***
 * Generated from Entities\Events\SendOrderUpdateEvent.cs
 */
export interface SignalR_SendOrderUpdateEvent extends SignalR_MibpApiEvent {
  validateSendOrderInProgress: boolean;
  validateSendOrderDone: boolean;
  validateSendOrderError: boolean;
}
/***
 * Generated from Entities\Events\TDMServedFileEvent.cs
 */
export interface SignalR_TDMServedFileEvent extends SignalR_MibpApiEvent {
  file: string;
  fileSize: number;
}
/***
 * Generated from Entities\Events\TriggerMibpSessionUpdateEvent.cs
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignalR_TriggerMibpSessionUpdateEvent extends SignalR_MibpApiEvent {
}
/***
 * Generated from Entities\Events\UserNotificationEvent.cs
 */
export interface SignalR_UserMessageEvent extends SignalR_MibpApiEvent {
  resourceStringKey: string;
  macros?: { [key: string]: string  };
  type: SignalR_UserMessageEventType;
  textOverride: string;
}
/***
 * Generated from Enums\CartAddProductErrorReason.cs
 */
export enum SignalR_CartAddProductErrorReason {
  Unknown = 0,
  Missing = 1,
  CartSizeExceeded = 2,
  InvalidProductCode = 3,
  DecimalNotSupported = 4
}
/***
 * Generated from Enums\AddToCartSource.cs
 */
export enum SignalR_AddToCartSource {
  NotSpecified = 0,
  Documoto = 1
}
/***
 * Generated from Enums\RoleEcommercePermission.cs
 */
export enum SignalR_RoleEcommercePermission {
  None = 0,
  ActAs = 1,
  StockAvailabilityEnquiry = 2,
  ReviewAndApproveWishList = 4,
  CheckOutCartPlaceOrder = 8,
  NetPriceOnly = 16,
  PriceEnquiry = 32,
  WarrantyPortal = 64,
  CreateCart = 256,
  ExportCart = 512,
  CheckOutCartRequestQuote = 1024,
  ELearning = 2048,
  PartsCatalogue = 4096,
  Bulletins = 8192,
  ViewLubetrendReports = 16384,
  MyFleet = 32768,
  TrackOrder = 65536,
  MyProfile = 524288,
  PunchOut = 4194304,
  ViewBulletinReport = 8388608,
  BulletinEmailNotification = 16777216,
  ShareTemplate = 33554432,
  UnShareTemplate = 67108864,
  ViewSubscriptions = 134217728,
  ECatalogue = 268435456,
  SendWishList = 536870912,
  InstructionAndSafetyManuals = 1073741824,
  MyOrderTemplate = 2147483648,
  ViewInvoices = 4294967296,
  RequestReturns = 8589934592,
  FindParts = 17179869184,
  AllowUpdateMachines = 34359738368,
  UserRequestNotification = 68719476736,
  POCSearch = 137438953472,
  UndergroundFleetReports = 274877906944,
  SurfaceFleetReports = 1099511627776,
  InspectionReports = 2199023255552,
  MaintenanceRecords = 4398046511104,
  MultiplePunchOutProfiles = 549755813888,
  Promotions = 8796093022208,
  CreditLimit = 17592186044416,
  PartsManuals = 35184372088832,
  RemoteSupport = 70368744177664,
  SmartMate = 140737488355328
}
/***
 * Generated from Enums\DeploymentEventType.cs
 */
export enum SignalR_DeploymentEventType {
  Unspecified = 0,
  DeployWarning = 1,
  OneMinuteCountdown = 2,
  DeployStarted = 3
}
/***
 * Generated from Enums\DownloadedManualStatus.cs
 */
export enum SignalR_DownloadedManualStatus {
  Unknown = 0,
  Queued = 1,
  InProgress = 2,
  Downloaded = 3,
  Error = 4,
  Expired = 5
}
/***
 * Generated from Enums\DownloadedManualErrorCode.cs
 */
export enum SignalR_DownloadedManualErrorCode {
  None = 0,
  UnknownError = 1,
  DocumotoExportFailed = 10,
  DocumotoReturnedNoRequestId = 11,
  DocumotoReturnedErrorStatus = 12,
  DocumotoReturnedUnknownStatus = 13,
  CreatePdfFailed = 20,
  ManualContainNoPdfs = 21,
  WebJobTerminated = 40,
  WebJobFailed = 50,
  NoPackageManualsCouldBeDownloaded = 60,
  ErrorCreatingPackage = 70,
  MediaMissingInDocumoto = 100,
  ErrorFetchingDocumotoFiles = 200
}
/***
 * Generated from Enums\DownloadedManualFileType.cs
 */
export enum SignalR_DownloadedManualFileType {
  Unknown = 0,
  Pdf = 1,
  Zip = 2,
  MicrosoftExcel = 3,
  MicrosoftExcelOpenXml = 4,
  PlainText = 5
}
/***
 * Generated from Enums\ResourceType.cs
 */
export enum SignalR_ResourceType {
  Text = 0,
  Html = 1
}
/***
 * Generated from Enums\UserType.cs
 */
export enum SignalR_UserType {
  Internal = 0,
  External = 1,
  Both = 2
}
/***
 * Generated from Enums\UserStatus.cs
 */
export enum SignalR_UserStatus {
  New = 0,
  Active = 1,
  Inactivated = 2,
  Suspended = 3,
  Approved = 4,
  Rejected = 5,
  Deactivated = 6,
  Created = 7,
  None = 8
}
/***
 * Generated from Enums\CustomerStatus.cs
 */
export enum SignalR_CustomerStatus {
  None = 0,
  Active = 1
}
/***
 * Generated from Enums\ContentPermission.cs
 */
export enum SignalR_ContentPermission {
  NotInUse = 0,
  Read = 10,
  Write = 20
}
/***
 * Generated from Enums\KitType.cs
 */
export enum SignalR_KitType {
  NotSet = 0,
  Maintenance = 1,
  Repair = 2
}
/***
 * Generated from Enums\KitSectionType.cs
 */
export enum SignalR_KitSectionType {
  NotSet = 0,
  Percussion = 10,
  Compressor = 20,
  Engine = 30
}
/***
 * Generated from Enums\ProductType.cs
 */
export enum SignalR_ProductType {
  NotSet = 0,
  SparePart = 1,
  Kit = 2,
  Subscription = 3,
  SparePartOnly = 4,
  NotSparePart = 5
}
/***
 * Generated from Enums\TagHeader.cs
 */
export enum SignalR_TagHeader {
  Model = 0,
  Serial = 1,
  Unknown = 2,
  eCatCombo = 3,
  MDGEquipmentId = 4
}
/***
 * Generated from Enums\TagType.cs
 */
export enum SignalR_TagType {
  NotSet = 0,
  ECatCombo = 1,
  Model = 2,
  MDGEquipmentId = 3,
  SerialNumber = 4
}
/***
 * Generated from Enums\JobStatus.cs
 */
export enum SignalR_JobStatus {
  Unknown = 0,
  Waiting = 10,
  Running = 20,
  Completed = 30,
  PartiallyCompleted = 35,
  Terminated = 40,
  Failed = 60,
  Hold = 70
}
/***
 * Generated from Enums\JobPriority.cs
 */
export enum SignalR_JobPriority {
  Low = 0,
  Normal = 10,
  High = 20
}
/***
 * Generated from Entities\DocumotoFetchMediaContentJob.cs
 */
export enum SignalR_MediaContentJobTrigger {
  Undefined = 0,
  SyncRequest = 1,
  UserDownloadOfManual = 2
}
/***
 * Generated from Enums\DocumotoFetchMediaContent\MediaContentFileStatus.cs
 */
export enum SignalR_MediaContentFileStatus {
  Waiting = 10,
  InProgress = 20,
  Done = 30,
  Error = 60
}
/***
 * Generated from Enums\DocumotoFetchMediaContent\MediaContentDownloadType.cs
 */
export enum SignalR_MediaContentDownloadType {
  NotSet = 0,
  BookMDZExport = 1,
  DocumentFileDownload = 2,
  BookPdfExport = 3
}
/***
 * Generated from Enums\FileType.cs
 */
export enum SignalR_FileType {
  NotSet = 0,
  Pdf = 10,
  Mdz = 20,
  Excel = 30,
  OpenXmlExcel = 40,
  PlainText = 50,
  Jpeg = 60,
  Png = 70,
  WordOpenXml = 80,
  Word = 90,
  Postscript = 100,
  TIFF = 110,
  Zip = 120
}
/***
 * Generated from Enums\PromotionType.cs
 */
export enum SignalR_PromotionType {
  Unknown = 0,
  Internal = 1,
  External = 2
}
/***
 * Generated from Enums\PromotionPlacement.cs
 */
export enum SignalR_PromotionPlacement {
  Unknown = 0,
  HomePage = 10
}
/***
 * Generated from Enums\Rendition.cs
 */
export enum SignalR_Rendition {
  Thumbnail = 0,
  Medium = 1,
  Large = 2,
  Original = 3,
  MediumLarge = 4,
  BillOfMaterialDrawingWeb = 10,
  BillOfMaterialDrawingThumbnail = 11,
  ClaimAttachmentThumbnail = 12,
  MyFleetListImage = 20,
  MyFleetDetailsImage = 21,
  ThumbnailProduct = 22
}
/***
 * Generated from Enums\CatalogComponentType.cs
 */
export enum SignalR_CatalogComponentType {
  NotSet = 0,
  Booms = 1,
  Feeds = 2
}
/***
 * Generated from Enums\SuperSessionType.cs
 */
export enum SignalR_SuperSessionType {
  None = 0,
  OutPhased = 10,
  NotAvailable = 20,
  ReplacedBy = 30
}
/***
 * Generated from Enums\CharacteristicType.cs
 */
export enum SignalR_CharacteristicType {
  NET_WEIGHT = 0,
  LENGTH = 1,
  HEIGHT = 2,
  WIDTH = 3,
  VOLUME = 4,
  GROSS_WT = 5
}
/***
 * Generated from Enums\EquipmentSignalType.cs
 */
export enum SignalR_EquipmentSignalType {
  Undefined = 0,
  EngineHours = 10,
  TransmissionHours = 20,
  PercussionHours1 = 30,
  PercussionHours2 = 40
}
/***
 * Generated from Enums\FleetDocumentType.cs
 */
export enum SignalR_FleetDocumentType {
  InspectionReport = 0,
  MaintenanceRecord = 1,
  OperationalReadiness = 2
}
/***
 * Generated from Enums\AssetTypes.cs
 */
export enum SignalR_AssetType {
  Machine = 0,
  Component = 1
}
/***
 * Generated from Enums\MaintenanceCalendarWeekStatus.cs
 */
export enum SignalR_MaintenanceCalendarWeekStatus {
  Unspecified = 0,
  Completed = 1
}
/***
 * Generated from Enums\WarrantyApplicationFileType.cs
 */
export enum SignalR_WarrantyApplicationFileType {
  Proof = 0,
  Photo = 1
}
/***
 * Generated from Enums\AccessGroupType.cs
 */
export enum SignalR_AccessGroupType {
  Organization = 0,
  TargetedAudience = 1
}
/***
 * Generated from Enums\AccessGroupPermission.cs
 */
export enum SignalR_AccessGroupPermission {
  None = 0,
  DetectCrossCountrySignins = 1
}
/***
 * Generated from Enums\OrderReferenceType.cs
 */
export enum SignalR_OrderReferenceType {
  NotSet = 0,
  Mandatory = 10,
  Unique = 20,
  NotRequired = 30
}
/***
 * Generated from Enums\BusinessRelationPermission.cs
 */
export enum SignalR_BusinessRelationPermission {
  None = 0,
  ViewCrushingAndScreeningReports = 4,
  ViewProductivityReportsForTrucks = 16,
  ViewProductivityReportsForLoaders = 32,
  ViewProductivityReportForUgDrills = 64,
  ViewTrackReports = 128,
  WarrantyPortal = 256
}
/***
 * Generated from Enums\RoleType.cs
 */
export enum SignalR_RoleType {
  Internal = 0,
  External = 1
}
/***
 * Generated from Enums\RoleAdministrationPermission.cs
 */
export enum SignalR_RoleAdministrationPermission {
  None = 0,
  Administration = 1,
  ResourceStringEditor = 2,
  ManageBulletins = 4,
  ManageSubscriptions = 8,
  ManageUsersAndUserAccess = 16,
  ManageRoles = 32,
  ManageOrganizations = 64,
  ManageCustomers = 128,
  ManageInternalUsers = 256,
  ECatalogueAdminModule = 512,
  ViewEventLog = 1024,
  PartsCatalogueTemplateManagement = 2048,
  ManageScopes = 8192,
  ManageSearchIndex = 16384,
  ManageRestrictedItem = 32768,
  ManageUpSell = 65536,
  ManageCrossSell = 131072,
  ManageInspectionReports = 262144,
  ManageMaintenanceRecords = 524288,
  ManagePromotions = 1048576,
  ManageMessageCenter = 2097152,
  EditHelpPage = 4194304,
  ManageTermsAndConditions = 8388608,
  ViewInternalReporting = 16777216,
  ManageChangelog = 33554432,
  ManageProductEnrichments = 67108864
}
/***
 * Generated from Enums\FeatureSetting.cs
 */
export enum SignalR_FeatureSetting {
  Inherit = 0,
  Disabled = 1,
  Enabled = 2
}
/***
 * Generated from Enums\RoleLevel.cs
 */
export enum SignalR_RoleLevel {
  None = 0,
  Global = 1000,
  Regional = 500,
  Customer = 200
}
/***
 * Generated from Enums\UserPermission.cs
 */
export enum SignalR_UserPermission {
  None = 0,
  ApproveNewUsers = 2,
  PunchoutProfile = 4,
  SupportTeamMember = 256,
  PunchoutcXML = 512,
  WarrantyPortal = 2048
}
/***
 * Generated from Enums\UserDateFormat.cs
 */
export enum SignalR_UserDateFormat {
  BrowserDefault = 0,
  YYMMDDDash = 1,
  YYMMDDSlash = 2,
  MMDDYYSlash = 3,
  MMDDYYDash = 4,
  DDMMYYSlash = 5,
  DDMMYYDash = 6
}
/***
 * Generated from Enums\SendGridCategory.cs
 */
export enum SignalR_EmailCategory {
  Unknown = 0,
  Bulletin = 1,
  Order = 2,
  Quotation = 3,
  Despatch = 4,
  FleetDocument = 5,
  NewUser = 6,
  WelcomeEmail = 7,
  UserInactivated = 8,
  UserSuspended = 9,
  UserReApproved = 10,
  ApprovedUserActionPending = 11,
  NewUserActionPending = 12,
  UserPromotion = 13,
  RemoteSupport = 14,
  Claim = 15,
  PartsManual = 16,
  WarrantyApplication = 17,
  WarrantyRegistration = 18,
  DownloadedManuals = 19,
  CreateB2cUserIntroductionEmail = 20,
  SupportCase = 21,
  WelcomeEmailIncludingResetPasswordLink = 22,
  ManuallyTriggeredWelcomeEmailIncludingResetPasswordLink = 23
}
/***
 * Generated from Enums\SendgridStatus.cs
 */
export enum SignalR_SendGridStatus {
  NotProcessed = 0,
  SentToSendGridForProcessing = 1,
  Processed = 2,
  Clicks = 3,
  Delivered = 4,
  Open = 5,
  Unsubscribes = 6,
  GroupUnsubscribes = 7,
  GroupResubscribes = 8,
  Deferred = 9,
  Bounce = 10,
  Dropped = 11,
  Blocks = 12,
  SpamReports = 13,
  Parse = 14
}
/***
 * Generated from Enums\UserNotificationSettingsType.cs
 */
export enum SignalR_UserNotificationSettingsType {
  Email = 0
}
/***
 * Generated from Enums\UserNotificationSettingsFeatureType.cs
 */
export enum SignalR_UserNotificationSettingsFeatureType {
  Quotation = 0,
  Order = 1,
  Despatch = 2,
  Bulletin = 3,
  FleetDocument = 4,
  Claim = 5,
  PartsManual = 6,
  DownloadedManuals = 7,
  SupportCase = 8
}
/***
 * Generated from Enums\NavigationLinkIcon.cs
 */
export enum SignalR_NavigationLinkIcon {
  None = 0,
  ExternalLink = 1
}
/***
 * Generated from Enums\NotificationType.cs
 */
export enum SignalR_NotificationType {
  BulletinAdded = 0,
  BulletinDeleted = 1,
  BulletinUpdated = 2,
  OrderCreated = 5,
  OrderDespatched = 6,
  QuotationCreated = 7,
  QuotationCancelFailed = 8,
  QuoteConverted = 9,
  ProfileUpdated = 10,
  SubscriptionAdded = 11,
  SubscriptionNotification = 12,
  EquipmentUpdated = 13,
  DownloadedManualComplete = 14,
  DownloadedManualUpdated = 15,
  DownloadedManualPackageComplete = 16,
  DownloadedManualPackageUpdated = 17,
  OrderUpdated = 18,
  QuotationUpdated = 19,
  FleetDocumentCreated = 20,
  FleetDocumentUpdated = 21,
  ClaimCreated = 22,
  ClaimUpdated = 23,
  PartsManualCreated = 24,
  PartsManualUpdated = 25,
  SupportCaseCreated = 26,
  SupportCaseUpdated = 27,
  SupportCaseCompleted = 28
}
/***
 * Generated from Enums\UserMessageEventType.cs
 */
export enum SignalR_UserMessageEventType {
  Info = 0,
  Error = 1,
  Success = 2,
  Warning = 3
}
