/* tslint:disable */
/* eslint-disable */
export enum IndexType {
  Customers = 1,
  Users = 2,
  Orders = 3,
  Company = 4,
  Equipments = 5,
  DeliverySequence = 7,
  Kits = 9,
  Organizations = 11,
  Claim = 12,
  BusinessRelation = 13,
  IntegrationBulletins = 14,
  Promotions = 15,
  Quotation = 16,
  NewProducts = 17,
  OperationSites = 18,
  PartsManuals = 20,
  NewsArticles = 21,
  WarrantyApplication = 22,
  SupportCase = 23,
  WarrantyRegistration = 24,
  EquipmentMedias = 25,
  MachineUpgrades = 26,
  FleetDocuments = 27,
  CatalogComponent = 28
}
