
import { SafeUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from "@angular/core";
import { SignalR_Rendition} from "root/services/mibp-api-services";
import { HttpClient } from "@angular/common/http";
import { MibpLogger, LogService, MibpHttpApi, CanDeactivateService } from 'root/services';
import { Guid } from 'guid-typescript';
import { ImageForReturnVm } from './image-for-return-vm';


@Component({
  selector: "mibp-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.scss"]
})
export class ImageComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() userFileId: Guid;
  @Input() urlOfImage:string;
  @Input() isOrder = false;
  defaultImage = 'assets/images/NewDefaultImage.png';
  @Input() adjustToContainer = false;
  @Input() loaderAspectRatio: number[];
  @Input() enableLightbox = false;
  @Output() imageLoaded = new EventEmitter();
  @Input() maxHeight: string;
  base64String: SafeUrl;
  imageUrl: string;
  clientId: string;
  isLoading: boolean;
  imageNotFound: boolean;
  renditionSize: SignalR_Rendition;
  log: MibpLogger;
  base64LargeString: SafeUrl;
  base64LargeUrl: string;
  testUrl: string;


  constructor(
    private httpApi: MibpHttpApi,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private canDeactivateService: CanDeactivateService,
    logger: LogService
  ) {
    this.log = logger.withPrefix('image');
  }

  public Rendition = SignalR_Rendition;

  ngOnInit(): void {
  }

  @Input()
  set rendition(value: SignalR_Rendition) {
    this.renditionSize = value;
  }

  get getCalculatedClasses(): string {
    return [
      this.adjustToContainer && "full-width",
      this.imageNotFound && "image-not-found " + `rendition-${this.renditionSize}`,
    ]
      .filter(Boolean)
      .join(" ");
  }

  getImage(): void {
    this.imageNotFound = false;
    if (this.urlOfImage) {
      this.base64String = this.sanitizer.bypassSecurityTrustUrl(this.urlOfImage);

      if(this.urlOfImage.includes('?'))
      {
        this.base64LargeString = this.urlOfImage.substring(0,this.urlOfImage.lastIndexOf("?")).replace(/\s+/g, '');
      }
      else
      {
        this.base64LargeString = this.urlOfImage;
      }

      this.isLoading = false;
    }
    else {
      if (this.userFileId) {
        this.isLoading = true;

        this.httpClient.get<ImageForReturnVm>(this.httpApi.resolveUriWithJwtToken(`userFile/image/${this.userFileId}/${this.renditionSize}`))
          .subscribe((imageForReturnVm: ImageForReturnVm) => {
            this.imageNotFound = imageForReturnVm.notFound;
            this.base64String = imageForReturnVm.notFound ? null : this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + imageForReturnVm.base64Image);
            this.base64LargeString = this.enableLightbox ? this.base64String : null;
            this.imageUrl = null;
            this.isLoading = false;
            if (this.imageNotFound) {
              this.imageUrl = this.defaultImage;
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error: any) => {
            this.imageNotFound = true;
            this.isLoading = false;
            this.imageUrl = this.defaultImage;
            this.log.error("Error:", error);
          }
          );

      } else {
        this.isLoading = true;
        this.httpClient.get<ImageForReturnVm>(this.httpApi.resolveUriWithJwtToken(`image/get/${this.renditionSize}/${this.name}`))
          .subscribe((imageForReturnVm: ImageForReturnVm) => {
            this.imageNotFound = imageForReturnVm.notFound;
            this.imageUrl = null;
            this.base64String = imageForReturnVm.notFound ? null : this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + imageForReturnVm.base64Image);
            this.base64LargeUrl   = imageForReturnVm.notFound ? null : this.base64String.toString();
            this.base64LargeString = imageForReturnVm.notFound ? null : this.base64LargeUrl.substring(0,this.base64LargeUrl.lastIndexOf("?"));
            this.isLoading = false;
            if (this.imageNotFound) {
              this.imageUrl = this.defaultImage;
            }

          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error: any) => {
            this.imageNotFound = true;
            this.isLoading = false;
            this.imageUrl = this.defaultImage;
            this.log.error("Error:", error);
          }
          );

      }
    }

  }

  ngOnChanges(): void {
    if (!this.isLoading) {
      this.getImage();
    }
  }

  loadingComplete() {
    this.imageLoaded.emit(null);
  }

  dialogOpen() {
    this.canDeactivateService.block();
  }

  dialogClosed() {
    this.canDeactivateService.unblock();
  }
}
