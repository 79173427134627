/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DeployStatusViewModel } from '../models/deploy-status-view-model';
import { getDeployStatus } from '../fn/application/get-deploy-status';
import { GetDeployStatus$Params } from '../fn/application/get-deploy-status';
import { getDeployStatus$Plain } from '../fn/application/get-deploy-status-plain';
import { GetDeployStatus$Plain$Params } from '../fn/application/get-deploy-status-plain';
import { getGlobalConfig } from '../fn/application/get-global-config';
import { GetGlobalConfig$Params } from '../fn/application/get-global-config';
import { getGlobalConfig$Plain } from '../fn/application/get-global-config-plain';
import { GetGlobalConfig$Plain$Params } from '../fn/application/get-global-config-plain';
import { GlobalConfigResponseViewModelApiResponse } from '../models/global-config-response-view-model-api-response';
import { handleDocumotoExtractZipFileJobsAfterDeploy } from '../fn/application/handle-documoto-extract-zip-file-jobs-after-deploy';
import { HandleDocumotoExtractZipFileJobsAfterDeploy$Params } from '../fn/application/handle-documoto-extract-zip-file-jobs-after-deploy';
import { handleDocumotoExtractZipFileJobsBeforeDeploy } from '../fn/application/handle-documoto-extract-zip-file-jobs-before-deploy';
import { HandleDocumotoExtractZipFileJobsBeforeDeploy$Params } from '../fn/application/handle-documoto-extract-zip-file-jobs-before-deploy';
import { handleDocumotoFetchMediaContentJobsAfterDeploy } from '../fn/application/handle-documoto-fetch-media-content-jobs-after-deploy';
import { HandleDocumotoFetchMediaContentJobsAfterDeploy$Params } from '../fn/application/handle-documoto-fetch-media-content-jobs-after-deploy';
import { handleDocumotoFetchMediaContentJobsBeforeDeploy } from '../fn/application/handle-documoto-fetch-media-content-jobs-before-deploy';
import { HandleDocumotoFetchMediaContentJobsBeforeDeploy$Params } from '../fn/application/handle-documoto-fetch-media-content-jobs-before-deploy';
import { handleDocumotoManualsJobsAfterDeploy } from '../fn/application/handle-documoto-manuals-jobs-after-deploy';
import { HandleDocumotoManualsJobsAfterDeploy$Params } from '../fn/application/handle-documoto-manuals-jobs-after-deploy';
import { handleDocumotoManualsJobsBeforeDeploy } from '../fn/application/handle-documoto-manuals-jobs-before-deploy';
import { HandleDocumotoManualsJobsBeforeDeploy$Params } from '../fn/application/handle-documoto-manuals-jobs-before-deploy';
import { handleDocumotoMediaSyncJobsAfterDeploy } from '../fn/application/handle-documoto-media-sync-jobs-after-deploy';
import { HandleDocumotoMediaSyncJobsAfterDeploy$Params } from '../fn/application/handle-documoto-media-sync-jobs-after-deploy';
import { handleDocumotoMediaSyncJobsBeforeDeploy } from '../fn/application/handle-documoto-media-sync-jobs-before-deploy';
import { HandleDocumotoMediaSyncJobsBeforeDeploy$Params } from '../fn/application/handle-documoto-media-sync-jobs-before-deploy';
import { handleDocumotoSyncJobsAfterDeploy } from '../fn/application/handle-documoto-sync-jobs-after-deploy';
import { HandleDocumotoSyncJobsAfterDeploy$Params } from '../fn/application/handle-documoto-sync-jobs-after-deploy';
import { handleDocumotoSyncJobsBeforeDeploy } from '../fn/application/handle-documoto-sync-jobs-before-deploy';
import { HandleDocumotoSyncJobsBeforeDeploy$Params } from '../fn/application/handle-documoto-sync-jobs-before-deploy';
import { handleFullCrawlJobsAfterDeploy } from '../fn/application/handle-full-crawl-jobs-after-deploy';
import { HandleFullCrawlJobsAfterDeploy$Params } from '../fn/application/handle-full-crawl-jobs-after-deploy';
import { handleFullCrawlJobsBeforeDeploy } from '../fn/application/handle-full-crawl-jobs-before-deploy';
import { HandleFullCrawlJobsBeforeDeploy$Params } from '../fn/application/handle-full-crawl-jobs-before-deploy';
import { handleOrganizationScopingJobsAfterDeploy } from '../fn/application/handle-organization-scoping-jobs-after-deploy';
import { HandleOrganizationScopingJobsAfterDeploy$Params } from '../fn/application/handle-organization-scoping-jobs-after-deploy';
import { handleOrganizationScopingJobsBeforeDeploy } from '../fn/application/handle-organization-scoping-jobs-before-deploy';
import { HandleOrganizationScopingJobsBeforeDeploy$Params } from '../fn/application/handle-organization-scoping-jobs-before-deploy';
import { handlePollDocumotoForChangesJobsAfterDeploy } from '../fn/application/handle-poll-documoto-for-changes-jobs-after-deploy';
import { HandlePollDocumotoForChangesJobsAfterDeploy$Params } from '../fn/application/handle-poll-documoto-for-changes-jobs-after-deploy';
import { handlePollDocumotoForChangesJobsBeforeDeploy } from '../fn/application/handle-poll-documoto-for-changes-jobs-before-deploy';
import { HandlePollDocumotoForChangesJobsBeforeDeploy$Params } from '../fn/application/handle-poll-documoto-for-changes-jobs-before-deploy';
import { handleRestrictedItemJobsAfterDeploy } from '../fn/application/handle-restricted-item-jobs-after-deploy';
import { HandleRestrictedItemJobsAfterDeploy$Params } from '../fn/application/handle-restricted-item-jobs-after-deploy';
import { handleRestrictedItemJobsBeforeDeploy } from '../fn/application/handle-restricted-item-jobs-before-deploy';
import { HandleRestrictedItemJobsBeforeDeploy$Params } from '../fn/application/handle-restricted-item-jobs-before-deploy';
import { ping } from '../fn/application/ping';
import { Ping$Params } from '../fn/application/ping';
import { ping$Plain } from '../fn/application/ping-plain';
import { Ping$Plain$Params } from '../fn/application/ping-plain';
import { processnUpdateSuperSeededProductInfo } from '../fn/application/processn-update-super-seeded-product-info';
import { ProcessnUpdateSuperSeededProductInfo$Params } from '../fn/application/processn-update-super-seeded-product-info';
import { showDeployWarning } from '../fn/application/show-deploy-warning';
import { ShowDeployWarning$Params } from '../fn/application/show-deploy-warning';
import { startLogoutCountdown } from '../fn/application/start-logout-countdown';
import { StartLogoutCountdown$Params } from '../fn/application/start-logout-countdown';
import { startup } from '../fn/application/startup';
import { Startup$Params } from '../fn/application/startup';
import { StringApiResponse } from '../models/string-api-response';

@Injectable({ providedIn: 'root' })
export class ApplicationApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `applicationControllerPing()` */
  static readonly ApplicationControllerPingPath = '/application/ping';

  /**
   * Simple ping that can be used to wake the application up.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ping$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  ping$Plain$Response(params?: Ping$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StringApiResponse>> {
    return ping$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Simple ping that can be used to wake the application up.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ping$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ping$Plain(params?: Ping$Plain$Params, context?: HttpContext): Observable<StringApiResponse> {
    return this.ping$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StringApiResponse>): StringApiResponse => r.body)
    );
  }

  /**
   * Simple ping that can be used to wake the application up.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ping()` instead.
   *
   * This method doesn't expect any request body.
   */
  ping$Response(params?: Ping$Params, context?: HttpContext): Observable<StrictHttpResponse<StringApiResponse>> {
    return ping(this.http, this.rootUrl, params, context);
  }

  /**
   * Simple ping that can be used to wake the application up.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ping$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ping(params?: Ping$Params, context?: HttpContext): Observable<StringApiResponse> {
    return this.ping$Response(params, context).pipe(
      map((r: StrictHttpResponse<StringApiResponse>): StringApiResponse => r.body)
    );
  }

  /** Path part for operation `applicationControllerGetGlobalConfig()` */
  static readonly ApplicationControllerGetGlobalConfigPath = '/application/globalconfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalConfig$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConfig$Plain$Response(params?: GetGlobalConfig$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GlobalConfigResponseViewModelApiResponse>> {
    return getGlobalConfig$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGlobalConfig$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConfig$Plain(params?: GetGlobalConfig$Plain$Params, context?: HttpContext): Observable<GlobalConfigResponseViewModelApiResponse> {
    return this.getGlobalConfig$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GlobalConfigResponseViewModelApiResponse>): GlobalConfigResponseViewModelApiResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConfig$Response(params?: GetGlobalConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<GlobalConfigResponseViewModelApiResponse>> {
    return getGlobalConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGlobalConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalConfig(params?: GetGlobalConfig$Params, context?: HttpContext): Observable<GlobalConfigResponseViewModelApiResponse> {
    return this.getGlobalConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<GlobalConfigResponseViewModelApiResponse>): GlobalConfigResponseViewModelApiResponse => r.body)
    );
  }

  /** Path part for operation `applicationControllerStartupstartupKey()` */
  static readonly ApplicationControllerStartupstartupKeyPath = '/application/startup';

  /**
   * Endpoint that can be called after a deploy to clear resources/translations from redis cache.
   * In future this can be used to do more work if needed after a deploy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startup()` instead.
   *
   * This method doesn't expect any request body.
   */
  startup$Response(params?: Startup$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return startup(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that can be called after a deploy to clear resources/translations from redis cache.
   * In future this can be used to do more work if needed after a deploy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startup(params?: Startup$Params, context?: HttpContext): Observable<void> {
    return this.startup$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleOrganizationScopingJobsBeforeDeploystartupKey()` */
  static readonly ApplicationControllerHandleOrganizationScopingJobsBeforeDeploystartupKeyPath = '/application/handle-organization-scoping-jobs-before-deploy';

  /**
   * Endpoint that should be called before a deploy is started. This will set all jobs to correct hold state.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleOrganizationScopingJobsBeforeDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleOrganizationScopingJobsBeforeDeploy$Response(params?: HandleOrganizationScopingJobsBeforeDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleOrganizationScopingJobsBeforeDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called before a deploy is started. This will set all jobs to correct hold state.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleOrganizationScopingJobsBeforeDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleOrganizationScopingJobsBeforeDeploy(params?: HandleOrganizationScopingJobsBeforeDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleOrganizationScopingJobsBeforeDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleOrganizationScopingJobsAfterDeploystartupKey()` */
  static readonly ApplicationControllerHandleOrganizationScopingJobsAfterDeploystartupKeyPath = '/application/handle-organization-scoping-jobs-after-deploy';

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleOrganizationScopingJobsAfterDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleOrganizationScopingJobsAfterDeploy$Response(params?: HandleOrganizationScopingJobsAfterDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleOrganizationScopingJobsAfterDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleOrganizationScopingJobsAfterDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleOrganizationScopingJobsAfterDeploy(params?: HandleOrganizationScopingJobsAfterDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleOrganizationScopingJobsAfterDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleFullCrawlJobsBeforeDeploystartupKey()` */
  static readonly ApplicationControllerHandleFullCrawlJobsBeforeDeploystartupKeyPath = '/application/handle-full-crawl-jobs-before-deploy';

  /**
   * Endpoint that should be called before a deploy is started. This will set all jobs to correct hold state.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleFullCrawlJobsBeforeDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleFullCrawlJobsBeforeDeploy$Response(params?: HandleFullCrawlJobsBeforeDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleFullCrawlJobsBeforeDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called before a deploy is started. This will set all jobs to correct hold state.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleFullCrawlJobsBeforeDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleFullCrawlJobsBeforeDeploy(params?: HandleFullCrawlJobsBeforeDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleFullCrawlJobsBeforeDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleFullCrawlJobsAfterDeploystartupKey()` */
  static readonly ApplicationControllerHandleFullCrawlJobsAfterDeploystartupKeyPath = '/application/handle-full-crawl-jobs-after-deploy';

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleFullCrawlJobsAfterDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleFullCrawlJobsAfterDeploy$Response(params?: HandleFullCrawlJobsAfterDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleFullCrawlJobsAfterDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleFullCrawlJobsAfterDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleFullCrawlJobsAfterDeploy(params?: HandleFullCrawlJobsAfterDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleFullCrawlJobsAfterDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleDocumotoExtractZipFileJobsBeforeDeploystartupKey()` */
  static readonly ApplicationControllerHandleDocumotoExtractZipFileJobsBeforeDeploystartupKeyPath = '/application/handle-documoto-extract-zip-file-jobs-before-deploy';

  /**
   * Endpoint that should be called before a deploy is started. This will set all jobs to correct hold state.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleDocumotoExtractZipFileJobsBeforeDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoExtractZipFileJobsBeforeDeploy$Response(params?: HandleDocumotoExtractZipFileJobsBeforeDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleDocumotoExtractZipFileJobsBeforeDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called before a deploy is started. This will set all jobs to correct hold state.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleDocumotoExtractZipFileJobsBeforeDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoExtractZipFileJobsBeforeDeploy(params?: HandleDocumotoExtractZipFileJobsBeforeDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleDocumotoExtractZipFileJobsBeforeDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleDocumotoManualsJobsBeforeDeploystartupKey()` */
  static readonly ApplicationControllerHandleDocumotoManualsJobsBeforeDeploystartupKeyPath = '/application/handle-documoto-manuals-jobs-before-deploy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleDocumotoManualsJobsBeforeDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoManualsJobsBeforeDeploy$Response(params?: HandleDocumotoManualsJobsBeforeDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleDocumotoManualsJobsBeforeDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleDocumotoManualsJobsBeforeDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoManualsJobsBeforeDeploy(params?: HandleDocumotoManualsJobsBeforeDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleDocumotoManualsJobsBeforeDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleDocumotoManualsJobsAfterDeploystartupKey()` */
  static readonly ApplicationControllerHandleDocumotoManualsJobsAfterDeploystartupKeyPath = '/application/handle-documoto-manuals-jobs-after-deploy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleDocumotoManualsJobsAfterDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoManualsJobsAfterDeploy$Response(params?: HandleDocumotoManualsJobsAfterDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleDocumotoManualsJobsAfterDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleDocumotoManualsJobsAfterDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoManualsJobsAfterDeploy(params?: HandleDocumotoManualsJobsAfterDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleDocumotoManualsJobsAfterDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleDocumotoFetchMediaContentJobsBeforeDeploystartupKey()` */
  static readonly ApplicationControllerHandleDocumotoFetchMediaContentJobsBeforeDeploystartupKeyPath = '/application/handle-documoto-fetch-media-content-before-deploy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleDocumotoFetchMediaContentJobsBeforeDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoFetchMediaContentJobsBeforeDeploy$Response(params?: HandleDocumotoFetchMediaContentJobsBeforeDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleDocumotoFetchMediaContentJobsBeforeDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleDocumotoFetchMediaContentJobsBeforeDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoFetchMediaContentJobsBeforeDeploy(params?: HandleDocumotoFetchMediaContentJobsBeforeDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleDocumotoFetchMediaContentJobsBeforeDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleDocumotoFetchMediaContentJobsAfterDeploystartupKey()` */
  static readonly ApplicationControllerHandleDocumotoFetchMediaContentJobsAfterDeploystartupKeyPath = '/application/handle-documoto-fetch-media-content-after-deploy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleDocumotoFetchMediaContentJobsAfterDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoFetchMediaContentJobsAfterDeploy$Response(params?: HandleDocumotoFetchMediaContentJobsAfterDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleDocumotoFetchMediaContentJobsAfterDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleDocumotoFetchMediaContentJobsAfterDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoFetchMediaContentJobsAfterDeploy(params?: HandleDocumotoFetchMediaContentJobsAfterDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleDocumotoFetchMediaContentJobsAfterDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleDocumotoMediaSyncJobsBeforeDeploystartupKey()` */
  static readonly ApplicationControllerHandleDocumotoMediaSyncJobsBeforeDeploystartupKeyPath = '/application/handle-documoto-media-sync-before-deploy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleDocumotoMediaSyncJobsBeforeDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoMediaSyncJobsBeforeDeploy$Response(params?: HandleDocumotoMediaSyncJobsBeforeDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleDocumotoMediaSyncJobsBeforeDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleDocumotoMediaSyncJobsBeforeDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoMediaSyncJobsBeforeDeploy(params?: HandleDocumotoMediaSyncJobsBeforeDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleDocumotoMediaSyncJobsBeforeDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleDocumotoMediaSyncJobsAfterDeploystartupKey()` */
  static readonly ApplicationControllerHandleDocumotoMediaSyncJobsAfterDeploystartupKeyPath = '/application/handle-documoto-media-sync-after-deploy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleDocumotoMediaSyncJobsAfterDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoMediaSyncJobsAfterDeploy$Response(params?: HandleDocumotoMediaSyncJobsAfterDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleDocumotoMediaSyncJobsAfterDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleDocumotoMediaSyncJobsAfterDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoMediaSyncJobsAfterDeploy(params?: HandleDocumotoMediaSyncJobsAfterDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleDocumotoMediaSyncJobsAfterDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleDocumotoExtractZipFileJobsAfterDeploystartupKey()` */
  static readonly ApplicationControllerHandleDocumotoExtractZipFileJobsAfterDeploystartupKeyPath = '/application/handle-documoto-extract-zip-file-jobs-after-deploy';

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleDocumotoExtractZipFileJobsAfterDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoExtractZipFileJobsAfterDeploy$Response(params?: HandleDocumotoExtractZipFileJobsAfterDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleDocumotoExtractZipFileJobsAfterDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleDocumotoExtractZipFileJobsAfterDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoExtractZipFileJobsAfterDeploy(params?: HandleDocumotoExtractZipFileJobsAfterDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleDocumotoExtractZipFileJobsAfterDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleRestrictedItemJobsBeforeDeploystartupKey()` */
  static readonly ApplicationControllerHandleRestrictedItemJobsBeforeDeploystartupKeyPath = '/application/handle-restricted-item-jobs-before-deploy';

  /**
   * Endpoint that should be called before a deploy is started. This will set all jobs to correct hold state.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleRestrictedItemJobsBeforeDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleRestrictedItemJobsBeforeDeploy$Response(params?: HandleRestrictedItemJobsBeforeDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleRestrictedItemJobsBeforeDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called before a deploy is started. This will set all jobs to correct hold state.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleRestrictedItemJobsBeforeDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleRestrictedItemJobsBeforeDeploy(params?: HandleRestrictedItemJobsBeforeDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleRestrictedItemJobsBeforeDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleRestrictedItemJobsAfterDeploystartupKey()` */
  static readonly ApplicationControllerHandleRestrictedItemJobsAfterDeploystartupKeyPath = '/application/handle-restricted-item-jobs-after-deploy';

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleRestrictedItemJobsAfterDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleRestrictedItemJobsAfterDeploy$Response(params?: HandleRestrictedItemJobsAfterDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleRestrictedItemJobsAfterDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleRestrictedItemJobsAfterDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleRestrictedItemJobsAfterDeploy(params?: HandleRestrictedItemJobsAfterDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleRestrictedItemJobsAfterDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleDocumotoSyncJobsAfterDeploystartupKey()` */
  static readonly ApplicationControllerHandleDocumotoSyncJobsAfterDeploystartupKeyPath = '/application/handle-documoto-sync-jobs-after-deploy';

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleDocumotoSyncJobsAfterDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoSyncJobsAfterDeploy$Response(params?: HandleDocumotoSyncJobsAfterDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleDocumotoSyncJobsAfterDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleDocumotoSyncJobsAfterDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoSyncJobsAfterDeploy(params?: HandleDocumotoSyncJobsAfterDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleDocumotoSyncJobsAfterDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandleDocumotoSyncJobsBeforeDeploystartupKey()` */
  static readonly ApplicationControllerHandleDocumotoSyncJobsBeforeDeploystartupKeyPath = '/application/handle-documoto-sync-jobs-before-deploy';

  /**
   * Endpoint that should be called before a deploy is started. This will set all jobs to correct hold state.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handleDocumotoSyncJobsBeforeDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoSyncJobsBeforeDeploy$Response(params?: HandleDocumotoSyncJobsBeforeDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handleDocumotoSyncJobsBeforeDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called before a deploy is started. This will set all jobs to correct hold state.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handleDocumotoSyncJobsBeforeDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handleDocumotoSyncJobsBeforeDeploy(params?: HandleDocumotoSyncJobsBeforeDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handleDocumotoSyncJobsBeforeDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandlePollDocumotoForChangesJobsAfterDeploystartupKey()` */
  static readonly ApplicationControllerHandlePollDocumotoForChangesJobsAfterDeploystartupKeyPath = '/application/handle-poll-documoto-for-changes-job-after-deploy';

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handlePollDocumotoForChangesJobsAfterDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handlePollDocumotoForChangesJobsAfterDeploy$Response(params?: HandlePollDocumotoForChangesJobsAfterDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handlePollDocumotoForChangesJobsAfterDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handlePollDocumotoForChangesJobsAfterDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handlePollDocumotoForChangesJobsAfterDeploy(params?: HandlePollDocumotoForChangesJobsAfterDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handlePollDocumotoForChangesJobsAfterDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerHandlePollDocumotoForChangesJobsBeforeDeploystartupKey()` */
  static readonly ApplicationControllerHandlePollDocumotoForChangesJobsBeforeDeploystartupKeyPath = '/application/handle-poll-documoto-for-changes-job-before-deploy';

  /**
   * Endpoint that should be called before a deploy is started. This will set all jobs to correct hold state.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `handlePollDocumotoForChangesJobsBeforeDeploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  handlePollDocumotoForChangesJobsBeforeDeploy$Response(params?: HandlePollDocumotoForChangesJobsBeforeDeploy$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return handlePollDocumotoForChangesJobsBeforeDeploy(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called before a deploy is started. This will set all jobs to correct hold state.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `handlePollDocumotoForChangesJobsBeforeDeploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  handlePollDocumotoForChangesJobsBeforeDeploy(params?: HandlePollDocumotoForChangesJobsBeforeDeploy$Params, context?: HttpContext): Observable<void> {
    return this.handlePollDocumotoForChangesJobsBeforeDeploy$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerShowDeployWarningstartupKey()` */
  static readonly ApplicationControllerShowDeployWarningstartupKeyPath = '/application/deploy/show-warning';

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showDeployWarning()` instead.
   *
   * This method doesn't expect any request body.
   */
  showDeployWarning$Response(params?: ShowDeployWarning$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return showDeployWarning(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showDeployWarning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showDeployWarning(params?: ShowDeployWarning$Params, context?: HttpContext): Observable<void> {
    return this.showDeployWarning$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerGetDeployStatusstartupKey()` */
  static readonly ApplicationControllerGetDeployStatusstartupKeyPath = '/application/maintenance/status';

  /**
   * Check for deploy/maintenance status when user logs in.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeployStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeployStatus$Plain$Response(params?: GetDeployStatus$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeployStatusViewModel>> {
    return getDeployStatus$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check for deploy/maintenance status when user logs in.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeployStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeployStatus$Plain(params?: GetDeployStatus$Plain$Params, context?: HttpContext): Observable<DeployStatusViewModel> {
    return this.getDeployStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeployStatusViewModel>): DeployStatusViewModel => r.body)
    );
  }

  /**
   * Check for deploy/maintenance status when user logs in.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeployStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeployStatus$Response(params?: GetDeployStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<DeployStatusViewModel>> {
    return getDeployStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Check for deploy/maintenance status when user logs in.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeployStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeployStatus(params?: GetDeployStatus$Params, context?: HttpContext): Observable<DeployStatusViewModel> {
    return this.getDeployStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeployStatusViewModel>): DeployStatusViewModel => r.body)
    );
  }

  /** Path part for operation `applicationControllerStartLogoutCountdownstartupKey()` */
  static readonly ApplicationControllerStartLogoutCountdownstartupKeyPath = '/application/deploy/logout-users';

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startLogoutCountdown()` instead.
   *
   * This method doesn't expect any request body.
   */
  startLogoutCountdown$Response(params?: StartLogoutCountdown$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return startLogoutCountdown(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint that should be called after deploy is started. This will set all jobs to correct state. hold --> waiting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startLogoutCountdown$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startLogoutCountdown(params?: StartLogoutCountdown$Params, context?: HttpContext): Observable<void> {
    return this.startLogoutCountdown$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `applicationControllerProcessnUpdateSuperSeededProductInfostartupKeySkipTake()` */
  static readonly ApplicationControllerProcessnUpdateSuperSeededProductInfostartupKeySkipTakePath = '/application/superseededproducts/process';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processnUpdateSuperSeededProductInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  processnUpdateSuperSeededProductInfo$Response(params?: ProcessnUpdateSuperSeededProductInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return processnUpdateSuperSeededProductInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processnUpdateSuperSeededProductInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  processnUpdateSuperSeededProductInfo(params?: ProcessnUpdateSuperSeededProductInfo$Params, context?: HttpContext): Observable<void> {
    return this.processnUpdateSuperSeededProductInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
