@import '../../../scss/mixins/all';
@import '../../../scss/variables';


.messageColumn {
  float: left;
  width: 50%;
  padding: 10px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}
.columnFullWidth {
  text-align: center;
  width: 100%;
  padding: 10px;
}
hr.solid {
  border-top: 1px solid #bbb;
}
h1.bold{
  font-weight: bolder !important;
}
.disruptionMsgText{
  font-weight: 100;
}
.message-content {
  background: #fff;
  color: #333;
  padding: 18px 25px;

  overflow-y: auto;
  flex-grow: 1;

  h1, h2, h3, h4 {
    margin: 0 0 .4em;
    strong {
      font-weight: inherit;
    }
    font-weight: 100;
    text-transform: none !important;
  }
  h1{
    font-size: 26px;
    line-height: 34px;
    font-weight: 400;
    color: $my-color-cb;
  }
  h2{
    font-size: 20px;
    font-weight: 400;
    color: $my-color-cb;
  }
  h3{
    font-size: 16px;
    font-weight: 700; // Looks exactly like body text when we dont use header font. Using bold for now
    color: $my-color-cb;
  }
  p {
    // font-size: 15px;
    margin: 0 0 0.5em;
  }
  strong {
    font-weight: 700;
  }
  table {
    max-width: 755px;
  }
}
