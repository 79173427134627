<div *ngIf="isLoading" class="skeleton-box" [mibpAspectRatio]="loaderAspectRatio"></div>
<div [ngClass]="{'image-cursor':urlOfImage && urlOfImage !== defaultImage  }">

  <img *ngIf="!isLoading && base64String && isOrder"
    [style.maxHeight]="maxHeight"
    [src]="base64String"
    [ngClass]="{'product-image':isOrder}"
    (click)="enableLightbox && urlOfImage && dlg.open()"
    (load)="this.loadingComplete()">

  <img *ngIf="!isLoading && base64String && !isOrder"
    [style.maxHeight]="maxHeight"
    [src]="base64String"
    [ngClass]="{'full-width': adjustToContainer, 'image-not-found': imageNotFound}"
    (click)="enableLightbox && base64LargeString && urlOfImage !== defaultImage && dlg.open(imageNotFound)"
    (load)="this.loadingComplete()">

  <img *ngIf="!isLoading && imageUrl"
    [style.maxHeight]="maxHeight"
    [src]="imageUrl" class="userfile"
    [ngClass]="getCalculatedClasses"
    (click)="enableLightbox && dlg.open(imageNotFound)"
    (load)="this.loadingComplete()">
</div>

<mibp-lightbox (opened)="dialogOpen()" (closed)="dialogClosed()" #dlg>
  <img *ngIf="enableLightbox && !isLoading && base64LargeString" class="lightboximg" [src]="base64LargeString" (load)="this.loadingComplete()">
</mibp-lightbox>
