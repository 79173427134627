@import '../../../scss/variables.scss';

.cdk-overlay-container {
  z-index: 5005;
}

.mat-mdc-form-field-flex {
  width: auto;
}

.datepicker {
  padding-top: 0px;
  padding-bottom: 0px;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 200;
  font-stretch: normal;
  font-size: 1rem;
  line-height: 26px;
  font-family: "Aktiv Grotesk", Helvetica, Arial, sans-serif !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  width: 100%;
}

.mibp-datetimepicker {
  padding-left: 0px;
  padding-right: 0px;

  &.block {
    display: block;

    mat-form-field {
      width: 100%;
    }
  }

  .icon {
    display: inline;
    position: absolute;
    right: 0;
    top: 9px;
  }

  &--clear {
    .clear-icon {
      position: absolute;
      right: 48px;
      top: 10px;
      color: $my-color-eb;
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }
  }

  &--no-clear {
    .clear-icon {
      display: none;
    }
  }

  .mat-mdc-text-field-wrapper {
    padding: 0 !important;
  
    &.mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: $my-color-white;
    }
  
    &.mat-mdc-form-field-focus-overlay {
      background-color: $my-color-white;
    }
  
    &.mdc-text-field--filled:hover {
      background-color: $my-color-white;
    }
  
    .mat-mdc-form-field-infix {
      padding: 0 !important;
      border: none !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      border-bottom: 0px !important;
      width: auto !important;
      min-height: 42px !important;
  
      input[type="datetime-local"]::-webkit-calendar-picker-indicator {
        display: initial;
        -webkit-appearance: initial;
      }

      input {
        border: 1px solid $my-color-border;
        border-radius: 3px;
        background-color: $my-color-background;
        display: inline-block;
        padding: 5px 10px;
        min-height: 42px !important;
        outline: none;
  
        &.ng-invalid {
          border: 1px solid #F54B00;
          background-color: #fffbf9;
        }
  
        &:hover {
          +.clear-icon {
            opacity: 1;
          }
        }
      }
    }
  
    &.mdc-text-field {
      background-color: $my-color-white;
  
      &--disabled {
        .mat-mdc-form-field-infix {
          input {
            border-color: #cdcdcd;
            background: #f8f8f8;
            color: #767676;
            cursor: not-allowed;
  
            &:hover {
              +.clear-icon {
                display: none;
              }
            }
          }
  
          img {
            filter: grayscale(1);
            opacity: 0.6;
            cursor: not-allowed;
          }
        }
      }
  
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  
  .mdc-line-ripple {
    display: none;
  }
}

.mat-form-field-underline {
  display: none;
}

.mat-calendar-table-header-divider {
  display: none;
}