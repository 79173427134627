/*
 * This SCSS is not encapsulated to one component and will affect all styles
 * It is included in the product-list component
 *
 * Make sure to use css classes to encapsulate your styles
*/
@import '../../../../../../../scss/variables.scss';
@import '../../../../../../../scss/mixins/all';

$imageCellWidth: 100px;
$cellPadding: 30px;

#{ $mysandvik }productlist {
    &__cell {
      &--product {
      h5 {
        font-weight: 200;
        color: $my-color-vo;
        font-size: 1rem;
        display:inline-block;
      }
    }
  }
}

.contact-us {
  display: block;
  border: none;
  text-decoration: underline;
}
.last-child {
  margin-bottom: 30px;
}

@mixin duplicateItemsIcon {
  // /* DUPLICATE ITEMS ICON START */
  .exclamationIcon{
    color: red;
  }
  .tooltip {
    margin-left: 3px;
    position: relative;
    display: inline-block;
    cursor: default;
  }

  .tooltip .tooltiptext {
    min-width: 255px;
    visibility: hidden;
    background-color: #fff;
    color: $my-color-text;
    text-align: center;
    border: solid 1px $my-color-border-dark;
    padding: 5px;
    margin-left: 15px;
    margin-bottom: 15px;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 105%;
  }

  .tooltip .material-icon {
    vertical-align: middle;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .tooltiptext {
    position: relative;
    background: #fff;
    border: 1px solid $my-color-border-dark;
  }
  .tooltiptext:after, .tooltiptext:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .tooltiptext:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 11px;
    margin-top: -11px;
  }
  .tooltiptext:before {
    border-color: rgba($my-color-border-dark, 0);
    border-right-color: $my-color-border-dark;
    border-width: 12px;
    margin-top: -12px;
  }
  /* DUPLICATE ITEMS ICON END */
}



@mixin mobileduplicateItemsIcon {
  // /* DUPLICATE ITEMS ICON START */
  .exclamationIcon{
    color: red;
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    width: 143px;
    visibility: hidden;
    background-color: #fff;
    color: #000;
    text-align: center;
    border: solid 1px $my-color-border;
    padding: 5px;
    margin-left: 15px;
    margin-bottom: 15px;
    position: absolute;
    z-index: 1;
    top: -69px;
    left: -83px;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .tooltiptext {
    position: relative;
    background: #fff;
    border: 1px solid $my-color-border;
  }
  .tooltiptext:after, .tooltiptext:before {
    top: 100%;
    left: 54%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .tooltiptext:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 10px;
    margin-top: 0px;
  }
  .tooltiptext:before {
    border-color: rgba($my-color-border, 0);
    border-top-color: $my-color-border;
    border-width: 10px;
    margin-top: 1px;
  }
  /* DUPLICATE ITEMS ICON END */
}
#{ $mysandvik }productlist {
  $block: &;

  &__row {
    position: relative;
  }

  &__remove-from-cart {
    position: absolute;
    right: 0;
    a {
        border: none;
        padding: 10px;
        text-decoration: none;
      }
  }

}
.promotion-quantity-restriction {
  margin-top: 12px;
  color: red;
  font-weight: 600;
  display: flex;
  position: absolute;
}
/*Upsell CrossSell buttons*/

.upsell-crossSell-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  order:8;

  .my-button {
    font-size: 12px;
    padding: 0 5px;
    min-height: 25px;
  }

  .crossSell-btn {
    margin-left: 15px;
  }
}
/*End Upsell CrossSell buttons*/

@include media-xs-and-larger {

  #{ $mysandvik }productlist {
    $block: &;

    @include duplicateItemsIcon();

    &__head {
      #{ $block }__cell {
        border-bottom: 1px solid $my-color-border;
        padding-bottom: 2px;
        font-weight: 700;
      }
    }

    &__cellaction {
      display: flex;
      height: 100%;
      flex-direction: column;
      margin-right: 6px;
    }

    &__cellaction-grow {
      flex: 1;
    }

    &__row {
      display: flex;
      width: 100%;

    }

    &__cell {

      &--product {
        flex-grow: 1;
        min-width: 26%;

        // DIV containing product name
        div:first-child a {
          display: inline-block;
        }
      }
      &--name{
        color: $my-color-text;
        font-size: 16px;
        word-break: break-all;
      }
      &--image {
        width: 160px;
        min-width: 160px;
        flex-grow: 0;
        flex-shrink: 0;
      }

      &--availability {
        min-width: 10%;
      }

      &--availableqty {
        min-width: 25%;
        display: inherit;
      }
      &--quantity {
        min-width: 25%;
        max-width: 25%;
      }

      &--unitprice {
        min-width: 15%;
        > div {
          padding-right: 30px;
        }
      }

      &--totalprice {
        min-width: 20%;
        > div {
          padding-right: 30px;
        }
      }

    }

    &__image {
      padding: 5px 50px 0 0;
    }

    &__rowgroup {
      font-size: .9rem;
      #{ $block }__row {
        margin: 30px 0;
      }

      &:not([data-product-index="0"]) {
        border-top: 1px solid #F2F2F2;
      }

  }

    &__action-buttons {
     position: absolute;
      padding-left: 0px;
      padding-right: 0px;
      top: 63px;
      right: 10px;

      mibp-button {
        margin-left: 10px;
      }
    }
  }
}

h5 {
  font-weight: 200;
  color: $my-color-vo;
  font-size: 1rem;
  display:inline-block;

}

.superseded-product {
  max-width: unset !important;
  background-color: $my-color-vo;
}

  @include media-s-only {
    #{ $mysandvik }productlist {
      $block: &;

      &__cell {
        &--product {
          min-width: 20%;
        }

        &--availability {
          display: none;
        }

        &--quantity {
          padding-right: 10px;
        }

         #{$block}__unitprice {
          padding:0;
          min-width: 25%;
        }

         &--mobileactions {
           width: 100%;
           display: flex;
           justify-content: flex-end;
         }
         &--actas {
           margin-left: 15px;
           max-width: 40%;
        }
      }

      &__action-buttons{
        margin-top: 30px;
      }
    }
  }

  @include media-m-only {
    #{ $mysandvik }productlist {
      $block: &;
      &__cell {
        &--product {
          min-width: 30%;
        }

        &--actas {
          margin-left: 15px;
          max-width: 50%;
        }
      }

      &__action-buttons{
        margin-right: 10px;
        bottom: 0px;
        top: unset;
      }
    }
  }

  @include media-m-and-smaller {
    #{ $mysandvik }productlist {
      $block: &;
      &__cell {
        &--image {
          width: 130px;
          min-width: 130px;
        }
      }

      &__image {
        padding: 5px 20px 0 0;
      }
    }
  }

  @include media-m-only {
    #{ $mysandvik }kitconfigure {
      $block: &;
      &__cell {
        &--product {
          min-width: 20%;
        }

        &--actas {
          margin-left: 15px;
          max-width: 50%;
        }
      }

      &__action-buttons{
        margin-top: 50px;
        right: 100px;
      }
    }
  }

  @include media-l-only {
    #{ $mysandvik }productlist {
      $block: &;
      &__cell {
        &--quantity {
          min-width: 20%;
          max-width: 20%;
        }
        &--totalprice {
          min-width: 20%;
        }

      }
    }
  }
  @include media-xl-only
  {
    #{ $mysandvik }productlist {
      $block: &;
      &__cell {
        &--quantity {
          min-width: 20%;
          max-width: 20%;
        }
        &--totalprice {
          min-width: 20%;
        }

      }
    }
  }


  @include media-xs-only {
    #{ $mysandvik }productlist {
      $block: &;
      &__cell {
        &--image {
          width: 64px;
          min-width: 64px;
        }

        &--actas {
          margin-left: 15px;
          max-width: 40%;
        }
        &--totalprice {
          min-width: 20%;
        }
      }
      &__image {
        padding: 0 15px 0 0;
      }
      .promotion-quantity-restriction{
        margin-top: 22px !important;
      }
      &__action-buttons{
        margin-top: 50px;
        margin-right: 50px;
      }
    }

  }


  @include media-xxs-only {
    .promotion-quantity-restriction {
      position: unset !important;
    }
    #{ $mysandvik }productlist {
    @include  mobileduplicateItemsIcon;
      $block: &;

      &__row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }

      &__image {
        display: none;
      }

      &__product {
        width: 100%;
      }

      &__row + &__row {
        margin-top: 0 ! important;
      }

      &__row {

        &--item-extras {
          margin-bottom: 30px;
          margin-top: 0 ! important;
        }


      }

      &__cell {
        &--image {
            display: none;

        }
        &--availableqty {
          display: none;
        }
        &--name{
          color: $my-color-text !important;
          font-size: $my-font-size;
        }
        &--availability,
        &--quantity,
        &--unitprice,
        &--totalprice,
        &--product {
          width: 90%;
        }
        margin-bottom: 0.5rem;

        &--quantity {
          order: 5
        }

        &--unitprice {
          order: 4
        }

        &--totalprice {
          order: 6
        }

        &--mobileactions {
          order: 7;
        }

        &--product {
          order: 2
        }
        &--availability {
          order: 3
        }

        &--quantity {
          min-width: 130px;
        }

        &--totalprice {
          &[data-has-price="false"] {
            display: none;
          }
        }

      }

      &__mobileheader {
        display: block;
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
        font-size: 0.9rem;
      }

    &__rowgroup {
      font-size: .9rem;
      #{ $block }__row {
          margin: 15px 0 0px 0;
          &--mobileactions {
            margin-bottom: 15px;
          }
          &--fullactions{
            display: none;
          }
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid #F2F2F2;
      }

    }

  }

  .upsell-crossSell-container {
    position: inherit;
    flex-direction: column;

    .crossSell-btn {
      margin: 0;
      margin-top: 5px;

    }
  }

}

.pointer{
  cursor: pointer;
}

@include media-s-only {
  #{ $mysandvik }productlist {

    &__action-buttons{
      top: unset;
      bottom: 0px;
    }
  }
  .promotion-quantity-restriction{
    margin-top: 22px !important;
  }
  .is-narrow-layout {

    .product {
      width: calc(50% - 100px);
    }

    .product-list-row {
      .aside {
        position: relative;
        width: 310px ! important;
        .total-price {
          padding-left: 10px;
        }
      }
    }

    .related-buttons {
      position: relative ! important;
      p {
        position: absolute! important;
      }
    }

    .action {
      position: absolute;
      right: 0;
    }

    .related-buttons-mobile {
      margin-top: 15px;
      display: flex ! important;
      justify-content: flex-end;
      width: 100%;
    }

    .properties {
      display: block;
      flex-wrap: initial;
      .prop,
      .prop:nth-child(odd),
      .prop:nth-child(even) {
        flex: unset;
        width: unset;
        margin-bottom: 3px;
        .label {
          font-weight: 600;
          width: unset;
          margin-right: 8px;
        }
        .value {
          font-weight: 100;
        }
      }

    }
  }
  .upsell-crossSell-container {
    flex-direction: column;

    .crossSell-btn {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}

@mixin collapse-table {
  tbody {
    tr, th, td {
      display: block;
    }
    th {
      font-weight: 500;
    }
    td {
      margin-bottom: 5px;
    }
  }
}


table.properties {
  border: none;
  width: auto;
  font-weight: 200;
  tbody, thead {
    th, td {
      border: none;
      padding:0;
    }
    th {
      padding-right: 22px;
    }

  }
  .availableqty{
    display: none;
  }
  @include media-xxs-only {
    .availableqty{
      display: table-row !important;
    }
  }
  @media only screen and (min-width: 1531px) and (max-width: 1770px) {
    &--collapsable {
      @include collapse-table();
    }
  }

  @media only screen and (min-width: 1025px) and (max-width: 1220px) {
    &--collapsable {
      @include collapse-table();
    }
  }
}

@include media-s-and-smaller {
  .related-buttons-mobile {
    display: block;
  }

}
.cardList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}
.cardList > * {
  box-sizing: border-box;
}
.cardList > *:not(:last-child) {
  margin-bottom: 32px;
  margin-right: 32px;
}
/* Item sizing */
.cardListItem {

  flex: 0 0.1 calc(50% - 32px);
}

@media only screen and (min-width: 781px) and (max-width: 870px) {
  .upsell-crossSell-container {
    right: 18px;
    flex-direction: column;

    .crossSell-btn {
      margin-left: 0;
      margin-top: 5px;
    }
  }

}


@include media-xs-and-larger {

  #{ $mysandvik }kitconfigure {
    $block: &;

    @include duplicateItemsIcon();

    &__head {
      #{ $block }__cell {
        border-bottom: 1px solid $my-color-g;
        padding-bottom: 2px;
        font-weight: 700;
      }
    }

    &__cellaction {
      display: flex;
      height: 100%;
      flex-direction: column;
      margin-right: 6px;
    }

    &__cellaction-grow {
      flex: 1;
    }

    &__row {
      display: flex;
      width: 100%;
    }

    &__cell {

      &--product {
        flex-grow: 1;
        width: 30%;;
      }

      &--availableqty {
        width: 30%;
        display: inherit;
      }
      &--name{
        color: $my-color-text;
        font-size: 16px;
      }
      &--image {
        width: 160px;
        min-width: 160px;
        flex-grow: 0;
        flex-shrink: 0;
      }

      &--availability {
        min-width: 10%;
      }

      &--quantity {
        min-width: 15%;
        max-width: 15%;
      }

      &--unitprice {
        min-width: 15%;
        > div {
          padding-right: 30px;
        }
      }

      &--totalprice {
        min-width: 20%;
        > div {
          padding-right: 30px;
        }
      }

    }

    &__image {
      padding: 5px 50px 0 0;
    }

    &__rowgroup {
      font-size: .9rem;
      #{ $block }__row {
        margin: 30px 0;
      }

      &:not([data-product-index="0"]) {
        border-top: 1px solid #F2F2F2;
      }

  }

    &__action-buttons {
      position:absolute;
      padding-left: 0px;
      padding-right: 0px;
      bottom:0px;
      right: 100px;

      mibp-button {
        margin-left: 10px;

      }
    }

  }
}
a {
  cursor: pointer;
}
