div.mibp-html-view {
  div, p, span {
    font-size: initial !important;
    font-family: inherit !important;
    color: inherit;
  }
  a {
    * {
      text-decoration: none !important;
    }
    strong {
      font-weight: inherit !important;
    }
  }
}
