import { Injectable } from '@angular/core';
//import { GlobalConfigService } from '../services/global-config.service';
import { GlobalConfigService} from 'root/services';

@Injectable({
  providedIn: 'root'
})
export class FileValidationService {
  allowedFileExtensions: string = '';

  constructor(private globalConfigService: GlobalConfigService) {
    this.setAllowedFileExtensions();
  }

  private setAllowedFileExtensions(): void {
    this.allowedFileExtensions = this.globalConfigService.supportedImageFileExtensions
      .split(',')
      .map(ext => ext.trim().toLowerCase())
      .map(ext => ext.startsWith('.') ? ext : `.${ext}`)
      .join(',');
  }

  getAllowedFileExtensions(): string {
    return this.allowedFileExtensions;
  }
}
