/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FdmReportAccessResponseViewModel } from '../models/fdm-report-access-response-view-model';
import { getFdmReportUrl } from '../fn/reports/get-fdm-report-url';
import { GetFdmReportUrl$Params } from '../fn/reports/get-fdm-report-url';
import { getFdmReportUrl$Plain } from '../fn/reports/get-fdm-report-url-plain';
import { GetFdmReportUrl$Plain$Params } from '../fn/reports/get-fdm-report-url-plain';
import { getPowerBiReport } from '../fn/reports/get-power-bi-report';
import { GetPowerBiReport$Params } from '../fn/reports/get-power-bi-report';
import { getPowerBiReport$Plain } from '../fn/reports/get-power-bi-report-plain';
import { GetPowerBiReport$Plain$Params } from '../fn/reports/get-power-bi-report-plain';
import { getRtmsReport } from '../fn/reports/get-rtms-report';
import { GetRtmsReport$Params } from '../fn/reports/get-rtms-report';
import { getRtmsReport$Plain } from '../fn/reports/get-rtms-report-plain';
import { GetRtmsReport$Plain$Params } from '../fn/reports/get-rtms-report-plain';
import { getRtmsReports } from '../fn/reports/get-rtms-reports';
import { GetRtmsReports$Params } from '../fn/reports/get-rtms-reports';
import { getRtmsReports$Plain } from '../fn/reports/get-rtms-reports-plain';
import { GetRtmsReports$Plain$Params } from '../fn/reports/get-rtms-reports-plain';
import { PowerBiReportViewModel } from '../models/power-bi-report-view-model';
import { RtmsReportViewModel } from '../models/rtms-report-view-model';

@Injectable({ providedIn: 'root' })
export class ReportsApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reportsControllerGetFdmReportUrlreportSource()` */
  static readonly ReportsControllerGetFdmReportUrlreportSourcePath = '/reports/fdm/url';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFdmReportUrl$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFdmReportUrl$Plain$Response(params?: GetFdmReportUrl$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FdmReportAccessResponseViewModel>> {
    return getFdmReportUrl$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFdmReportUrl$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFdmReportUrl$Plain(params?: GetFdmReportUrl$Plain$Params, context?: HttpContext): Observable<FdmReportAccessResponseViewModel> {
    return this.getFdmReportUrl$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FdmReportAccessResponseViewModel>): FdmReportAccessResponseViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFdmReportUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFdmReportUrl$Response(params?: GetFdmReportUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<FdmReportAccessResponseViewModel>> {
    return getFdmReportUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFdmReportUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFdmReportUrl(params?: GetFdmReportUrl$Params, context?: HttpContext): Observable<FdmReportAccessResponseViewModel> {
    return this.getFdmReportUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<FdmReportAccessResponseViewModel>): FdmReportAccessResponseViewModel => r.body)
    );
  }

  /** Path part for operation `reportsControllerGetRtmsReports()` */
  static readonly ReportsControllerGetRtmsReportsPath = '/reports/getrtmsreports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRtmsReports$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRtmsReports$Plain$Response(params?: GetRtmsReports$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RtmsReportViewModel>> {
    return getRtmsReports$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRtmsReports$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRtmsReports$Plain(params?: GetRtmsReports$Plain$Params, context?: HttpContext): Observable<RtmsReportViewModel> {
    return this.getRtmsReports$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RtmsReportViewModel>): RtmsReportViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRtmsReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRtmsReports$Response(params?: GetRtmsReports$Params, context?: HttpContext): Observable<StrictHttpResponse<RtmsReportViewModel>> {
    return getRtmsReports(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRtmsReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRtmsReports(params?: GetRtmsReports$Params, context?: HttpContext): Observable<RtmsReportViewModel> {
    return this.getRtmsReports$Response(params, context).pipe(
      map((r: StrictHttpResponse<RtmsReportViewModel>): RtmsReportViewModel => r.body)
    );
  }

  /** Path part for operation `reportsControllerGetPowerBiReportreportTypeSerial()` */
  static readonly ReportsControllerGetPowerBiReportreportTypeSerialPath = '/reports/getpowerbireportnew';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPowerBiReport$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPowerBiReport$Plain$Response(params?: GetPowerBiReport$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PowerBiReportViewModel>> {
    return getPowerBiReport$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPowerBiReport$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPowerBiReport$Plain(params?: GetPowerBiReport$Plain$Params, context?: HttpContext): Observable<PowerBiReportViewModel> {
    return this.getPowerBiReport$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PowerBiReportViewModel>): PowerBiReportViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPowerBiReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPowerBiReport$Response(params?: GetPowerBiReport$Params, context?: HttpContext): Observable<StrictHttpResponse<PowerBiReportViewModel>> {
    return getPowerBiReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPowerBiReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPowerBiReport(params?: GetPowerBiReport$Params, context?: HttpContext): Observable<PowerBiReportViewModel> {
    return this.getPowerBiReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<PowerBiReportViewModel>): PowerBiReportViewModel => r.body)
    );
  }

  /** Path part for operation `reportsControllerGetRtmsReportreportId()` */
  static readonly ReportsControllerGetRtmsReportreportIdPath = '/reports/getrtmsreport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRtmsReport$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRtmsReport$Plain$Response(params?: GetRtmsReport$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PowerBiReportViewModel>> {
    return getRtmsReport$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRtmsReport$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRtmsReport$Plain(params?: GetRtmsReport$Plain$Params, context?: HttpContext): Observable<PowerBiReportViewModel> {
    return this.getRtmsReport$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PowerBiReportViewModel>): PowerBiReportViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRtmsReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRtmsReport$Response(params?: GetRtmsReport$Params, context?: HttpContext): Observable<StrictHttpResponse<PowerBiReportViewModel>> {
    return getRtmsReport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRtmsReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRtmsReport(params?: GetRtmsReport$Params, context?: HttpContext): Observable<PowerBiReportViewModel> {
    return this.getRtmsReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<PowerBiReportViewModel>): PowerBiReportViewModel => r.body)
    );
  }

}
